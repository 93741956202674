import React from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    BoltIcon, CurrencyDollarIcon, UsersIcon
} from "@heroicons/react/24/outline";

import imgDark from "../../Photos/OrderFormDark.png";
import imgLight from "../../Photos/OrderFormLight.png";


const NewOrderGuides = () => {

    const { darkMode } = useDarkMode();

    const posts = [
        {
            id: 1,
            title: 'Selecting a customer',
            href: '#',
            description:
                'The way that you, a sales rep, selects a customer is you choose from the drop down labeled Customer. This will preload with all of your active customers and once you have selected them you will see the next three fields to the left “Industry”,  “Pricing Type”, and “Customer Code” preloaded with the data associated with that customer’s account.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 2,
            title: 'Why does my one customer not appear in the dropdown?',
            href: '#',
            description:
                'This is potentially due to a couple of reasons, firstly being you may only place an order with an active customer so this customer\'s status may be pending. Secondly it may be that you need to reload your customer list. This can be done by going to the customer management tab and hitting the refresh/ update button in the top left corner. Once you have reloaded you may go back to your order form and they should appear now.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 3,
            title: 'How to add a Product to an order?',
            href: '#',
            description:
                'Once a customer has been selected, start by typing the name of the product you would like in the search bar. Once you see the product popup you can click on that product and you will see a product input card appear below preloaded with the first U/M available for that product and the price.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 4,
            title: 'How to remove a Product from an order?',
            href: '#',
            description:
                'Just hit the X button all the way to the right of the Product Input Card and you will see that item disappear and the totals at the bottom update accordingly.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 5,
            title: 'Why can I not change the Industry and PricingType for my Customer?',
            href: '#',
            description:
                'This is a locked feature which is loaded in from the customer’s account and cannot be changed by the salesman during an order. You may edit this in the customer management tab.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 6,
            title: 'What is the Green Dot in the corner of the input fields in my product input card?',
            href: '#',
            description:
                'This is an indicator meaning that this is a product that is available to your customer and you may place an order with that product in it.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 7,
            title: 'What is the Red Pulsing Dot in the corner of the input fields in my product input card?',
            href: '#',
            description:
                'This is an indicator meaning that this product is either not available to you customer because of region and or pricing type. You may still add this item to an order but the plant will have to add in a price and you will not know pricing for the product at the time of order placing.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 8,
            title: 'What is the Blue Dot in the corner of the total in my product input card?',
            href: '#',
            description:
                'This is an indicator meaning that a discount has been applied to that line Item. ',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 9,
            title: 'What happened to the Blue Dot in the corner of the total in my product input card?',
            href: '#',
            description:
                'This means that a discount is not applied to that specific product and or U/M so for instance we do not offer discounts for Bulk orders. So if the U/M is bulk there will not be a discount applied.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 10,
            title: 'What is the “Browse Customer Orders” button?',
            href: '#',
            description:
                'This is a coming soon feature that will allow you to search through past orders for your customer and replicate them in the order form but with newly updated pricing.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 11,
            title: 'Why are there no Shipping Locations?',
            href: '#',
            description:
                'The Shipping locations are populated by the shipping locations attached to the customer account. This means that if there are no items in the dropdown there is likely no shipping locations attached to the customer account and you may go to customer management to handle that.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 12,
            title: 'What is the difference between the Planned and Submit buttons?',
            href: '#',
            description:
                'A planned order gets saved to your account and to the customer account but is not submitted to the plant for fulfillment. A submitted order is an order that has been sent off to the plant for fulfillment. Just to note a Planned order may be edited but a submitted order also known as a pending order may not be edited once it has been submitted to the plant.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 13,
            title: 'What is that Document icon next to the Planned button?',
            href: '#',
            description:
                'This is the quote downloading button. This allows you to mock up an order for a customer in front of them and then download that order as a quoted PDF to email to them. You may also download an order as a quote in the current order tab.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        {
            id: 14,
            title: 'What is the Yellow dot in the corner of the pricing field?',
            href: '#',
            description:
                'This is the indicator light to show that a contract price has been recognized and applied to the order form.',
            date: 'Jan 20, 2025',
            category: { title: 'New Order Form', href: '#' },
        }
        // More posts...
    ]

    const features = [
      {
        name: 'Integrated Customer List.',
        description: 'Effortlessly access and manage a comprehensive list of customers directly within the order form. Quickly search, filter, and select customers without navigating away, ensuring a seamless workflow.',
        icon: UsersIcon,
      },
      {
        name: 'Automatic Pricing.',
        description: 'Automatically calculate region-specific pricing based on customer profiles and selected products. This eliminates manual calculations and ensures consistent and accurate quotes for every order.',
        icon: CurrencyDollarIcon,
      },
      {
        name: 'Rapid Ordering.',
        description: 'Streamline the order creation process with an intuitive and efficient interface. Add products, adjust quantities, and confirm orders with just a few clicks, reducing time spent on repetitive tasks.',
        icon: BoltIcon,
      },
    ];

    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'} overflow-y-scroll animate-fadeIn`}>

            <div className="mx-auto px-6">
                <div className="mx-auto">
                    
                    
                    <h2 className="text-pretty text-green-600 text-4xl font-semibold tracking-widest sm:text-5xl">New Order Form</h2>
                    <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>The new order form in the internal sales tool offers an efficient solution for sales reps to manage and submit customer orders seamlessly.</p>
                    
                    
                    <div className="overflow-hidden py-12 sm:py-12">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                                <div className="lg:pr-8 lg:pt-4">
                                    <div className="lg:max-w-lg">
                                        <h2 className="text-base/7 font-semibold text-green-600">Order faster</h2>
                                        <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight sm:text-5xl">
                                            A better workflow
                                        </p>
                                        <p className={`mt-6 text-lg/8 ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            Streamline sales with an integrated customer list, automatic pricing, and rapid ordering for efficient and accurate order management.
                                        </p>
                                        <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-sm lg:max-w-none">
                                            {features.map((feature) => (
                                                <div key={feature.name} className="relative pl-9">
                                                    <dt className="inline font-semibold">
                                                        <feature.icon aria-hidden="true" className="absolute left-1 top-1 size-5 text-green-600"/>
                                                        {feature.name}
                                                    </dt>
                                                    {' '}
                                                    <dd className={`inline ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>{feature.description}</dd>
                                                </div>
                                            ))}
                                        </dl>
                                    </div>
                                </div>
                                <img
                                    alt="Product screenshot"
                                    src={darkMode ? imgDark : imgLight}
                                    width={2432}
                                    height={1442}
                                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                />
                            </div>
                        </div>
                    </div>
                    
                    
                    <div
                        className={`mt-8 border-t-4 border-dashed ${darkMode ? 'border-green-600' : 'border-green-600'} pt-8`}>
                        <div className="columns-1 sm:columns-2 gap-6 space-y-6">
                            {posts.map((post) => (
                                <article key={post.id}
                                         className="break-inside-avoid p-4 rounded-lg shadow-lg hover:scale-105">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className={`${darkMode ? 'text-neutral-500' : 'text-gray-500'}`}>
                                            {post.date}
                                        </time>
                                        <div
                                            className={`relative z-10 rounded-full ${darkMode ? 'bg-darkBgColor text-green-600 border border-neutral-700' : 'bg-bgColor border text-green-600'} px-3 py-1.5 font-medium`}>
                                            {post.category.title}
                                        </div>
                                    </div>
                                    <div className="group relative mt-4">
                                        <h3 className="text-lg font-semibold">
                                            <div>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </div>
                                        </h3>
                                        <p className={`mt-3 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            {post.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                
                
                </div>
            </div>
        
        </div>
    
    );
}

export default NewOrderGuides;