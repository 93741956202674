import logo from "../main-logo.png";
import {useDarkMode} from "../contexts/darkModeContext";
import React from "react";

const Loading = () => {
    
    const {darkMode} = useDarkMode();
    
    return (
        <div className="fixed inset-0 animate-fadeIn flex items-center justify-center z-50">
            <div className={`w-56 h-56 animate-spinSlow flex items-center justify-center border-4 border-dashed ${darkMode ? 'border-neutral-700' : ''} rounded-full backdrop-blur-sm overflow-hidden`}>
                
                <svg
                    aria-hidden="true"
                    className={`absolute inset-0 z-10 size-full ${darkMode ? 'stroke-white/10' : 'stroke-black/10'} [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]`}
                >
                    <defs>
                        <pattern
                            x="50%"
                            y={-1}
                            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                            width={200}
                            height={200}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none"/>
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-800/0">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%"
                          strokeWidth={0}/>
                </svg>
                
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                
            </div>
            
            <img
                alt=""
                src={logo}
                className="absolute animate-heartBeatSlow h-32 z-50 opacity-80"
            />
        </div>
    )
}

export default Loading;