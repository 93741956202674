import React from "react";
import {useDarkMode} from "../../contexts/darkModeContext";


const ArchiveOrders = () => {

    const { darkMode } = useDarkMode();
    
    
    const stats = [
      { name: 'Quarter 1', value: 'April 1st', change: '+4.75%', changeType: 'positive' },
      { name: 'Quarter 2', value: 'July 1st', change: '+54.02%', changeType: 'negative' },
      { name: 'Quarter 3', value: 'October 1st', change: '-1.39%', changeType: 'positive' },
      { name: 'Quarter 4', value: 'January 1st', change: '+10.18%', changeType: 'negative' },
    ]
    
    const posts = [
        {
            id: 1,
            title: 'Selecting a customer',
            href: '#',
            description:
                'The way that you, a sales rep, selects a customer is you choose from the drop down labeled Customer. This will preload with all of your active customers and once you have selected them you will see the next three fields to the left “Industry”,  “Pricing Type”, and “Customer Code” preloaded with the data associated with that customer’s account.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        // More posts...
    ]


    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'} overflow-y-scroll animate-fadeIn`}>

            <div className="mx-auto px-6">
                <div className="mx-auto">
                    
                    
                    <h2 className="text-pretty text-green-600 text-4xl font-semibold tracking-widest sm:text-5xl">Archived Orders</h2>
                    <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>View and manage historical customer orders effortlessly, ensuring a streamlined workflow for sales reps.</p>
                    
                    <dl className={`mx-auto mt-4 grid grid-cols-1 divide-x border gap-px ${darkMode ? 'bg-darkBgColor/15 border-neutral-700 divide-neutral-700' : 'bg-bgColor border-neutral-300 divide-neutral-300'} rounded-lg sm:grid-cols-2 lg:grid-cols-4`}>
                        {stats.map((stat) => (
                            <div
                                key={stat.name}
                                className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-3 sm:px-6 xl:px-8"
                            >
                                <dt className="text-sm/6 font-medium">{stat.name}</dt>
                                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight">{stat.value}</dd>
                            </div>
                        ))}
                    </dl>
                    
                    <div className="mt-4">
                        <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                            Above are the dates in which the system archives only your Active Orders. This occurs and 12:00am on these dates and when you login those days your active orders will be empty
                        </p>
                    </div>
                    
                    <div
                        className={`mt-4 border-t-4 border-dashed ${darkMode ? 'border-green-600' : 'border-green-600'} pt-8`}>
                        <div className="columns-1 sm:columns-2 gap-6 space-y-6">
                            {posts.map((post) => (
                                <article key={post.id}
                                         className="break-inside-avoid p-4 rounded-lg shadow-lg hover:scale-105">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className={`${darkMode ? 'text-neutral-500' : 'text-gray-500'}`}>
                                            {post.date}
                                        </time>
                                        <div
                                            className={`relative z-10 rounded-full ${darkMode ? 'bg-darkBgColor text-green-600 border border-neutral-700' : 'bg-bgColor border text-green-600'} px-3 py-1.5 font-medium`}>
                                            {post.category.title}
                                        </div>
                                    </div>
                                    <div className="group relative mt-4">
                                        <h3 className="text-lg font-semibold">
                                            <div>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </div>
                                        </h3>
                                        <p className={`mt-3 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            {post.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                
                
                </div>
            </div>
        
        </div>
    
    );
}

export default ArchiveOrders;