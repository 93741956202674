import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import logo from "../main-logo.png";
import {useDarkMode} from "../contexts/darkModeContext";
import {useAuth} from "../contexts/authContext";
import {db} from "../firebase/firebase";

const SplashScreen = () => {
    
    const navigate = useNavigate();
    const {darkMode} = useDarkMode();
  
    useEffect(() => {
        // Redirect to Home after 5 seconds
        const timer = setTimeout(() => {
         navigate("/Home");
        }, 3000);

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, [navigate]);
    
    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    
    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userName_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            // Save user data to localStorage
                            localStorage.setItem(`userName_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    return (
        <div className={`fixed ${darkMode ? 'bg-darkMainColor text-white' : 'bg-bgColor text-black'} inset-0 flex items-center justify-center z-50`}>
            
            <svg
                aria-hidden="true"
                className={`absolute inset-0 z-10 size-full ${darkMode ? 'stroke-white/10' : 'stroke-black/10'} [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]`}
            >
                <defs>
                    <pattern
                        x="50%"
                        y={-1}
                        id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                        width={200}
                        height={200}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M.5 200V.5H200" fill="none"/>
                    </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-800/0">
                    <path
                        d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%"
                      strokeWidth={0}/>
            </svg>
            
            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>
            
            <div className="absolute w-full text-center top-4 font-extralight text-4xl tracking-widest overflow-hidden p-2">
                <div className={``}>Plant Food Sales Tool</div>
                <div className="absolute inset-0 -skew-x-12 animate-glisten bg-gradient-to-r from-transparent via-bgDarkGreen/50 blur-2xl to-transparent"></div>
            </div>
            
            
            <div className={`absolute align-middle mb-72 font-extralight text-2xl tracking-widest`}>Welcome {fname}!</div>
            
            <div className={`inset-0 animate-fadeIn flex items-center justify-center`}>
                <div className={`absolute w-56 h-56 animate-spinSlow flex items-center justify-center border-4 border-dashed ${darkMode ? 'border-neutral-700' : ''} rounded-full backdrop-blur-sm overflow-hidden`}></div>
                <img
                    alt=""
                    src={logo}
                    className="absolute animate-heartBeatSlow h-32 z-50 opacity-80"
                />
            </div>
            
            <div className={`absolute align-middle mt-72 font-extralight text-sm tracking-widest`}>Just Give
                Us a Moment to get everything Ready!
            </div>
        
        
        </div>
    );
};

export default SplashScreen;