import React from "react";
import OrderCard from "./OrderCard";
import {EyeSlashIcon, PlusIcon} from "@heroicons/react/24/outline";

const OrderSection = ({ status, orders, emptyTitle, emptyDescription, onDelete, darkMode }) => {
    return (
        <div className="w-full h-full relative animate-fadeIn">
            {orders.length === 0 ? (
                <div className="flex w-full h-full justify-center items-center py-10">
                    <div
                        className={`flex items-center border-2 border-dashed ${
                            darkMode ? "text-white border-borderColor" : "text-black bg-neutral-50"
                        } w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}
                    >
                        <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                            <EyeSlashIcon className="w-10 stroke-2 mr-3"/>
                            <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">{emptyTitle}</p>
                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">{emptyDescription}</p>
                            <div className="mt-6">
                                <a
                                    href="/NewOrder"
                                    className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5"/>
                                    Create Order
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                orders.map((order, index) => (
                    <OrderCard
                        key={order.id}
                        index={order.id}
                        order={order}
                        onDelete={onDelete}
                    />
                ))
            )}
        </div>
    );
};

export default OrderSection;