import {
    Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle, Field, Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch, Textarea
} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import logo from "../../main-logo.png";
import pdfIcon from "../../pdfIcon.png";

import {doc, setDoc, deleteDoc, getDoc, addDoc, collection} from "firebase/firestore";

import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    ArrowUpOnSquareStackIcon, CheckBadgeIcon,
    CheckCircleIcon,
    ClipboardDocumentIcon,
    ClockIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon, EnvelopeIcon,
    PencilSquareIcon, PhoneIcon,
    TrashIcon,
    TruckIcon,
    UserCircleIcon,
    ExclamationTriangleIcon,
    CreditCardIcon,
} from "@heroicons/react/24/outline";
import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import {AgCharts} from "ag-charts-react";
import {db} from "../../firebase/firebase";
import createQuoteTemplate from "../OrderForm/QuoteTemplate";

import pdfMake from "pdfmake/build/pdfmake";
import {
    EllipsisVerticalIcon,
} from "@heroicons/react/16/solid";

const OrderCard = ({ order, onDelete }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const [isAdding, setIsAdding] = useState(false);
    const { currentUser } = useAuth();

    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state

    const [customerID, setCustomerID] = useState("");
    const [salesID, setSalesID] = useState("");

    const [orderERP, setOrderERP] = useState("");

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);

    const [viewID, setViewID] = useState(false);
    
    const [isEditing, setEditing] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    
    const toggleDeleting = () => {
        setDeleting(!isDeleting);
    }
    
    const toggleSubmitting = () => {
        setSubmitting(!isSubmitting);
    }

    const handleViewIDToggle = () => {
        setViewID(true);
        setTimeout(() => {
            setViewID(false);
        }, 5000);
    };
    

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST");
    }, [accountLevel]);

    const { darkMode } = useDarkMode();

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
        setIsVisible(true);
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setIsLoaded(true);

        // Format data for the chart: Extract 'customerCode' and 'grandTotal'
        const chartData = order.products.map((product, index) => ({
            index: "Order [" + (index+1) + "]",
            name: product.name,
            total: parseFloat(product.total),
        }));
        // Set the chart data
        setChartData(chartData);
        setLoading(false); // Mark loading as complete

        setCustomerID(order.customerUID);
        setSalesID(order.salesUID);

    }, [order.customerUID, order.products, order.salesUID]);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed
    

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };



    // Function to move document from "pending" to "active" collection and update status
    const moveToPending = async (orderID) => {
        try {
            console.log("Status Updated");
            // Reference to the order in the 'planned' collection
            const plannedDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/planned`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(plannedDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the planned collection");
            }

            const today = new Date();
            const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`;

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "pending", orderDate: formattedDate };
            console.log("Status Updated");

            // Reference to the destination document in the "pending" subcollection
            const pendingDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/pending`, orderID);

            // Copy the document to the "pending" subcollection with updated status
            await setDoc(pendingDocRef, updatedOrderData);

            // Delete the document from the "planned" subcollection
            await deleteDoc(plannedDocRef);

            console.log("Order moved to Pending collection and status updated to pending.");
            alert("Order moved to Pending collection and status updated to pending.");

            // Generate formatted date and time
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            };
            const formattedDateTime = now.toLocaleString('en-US', options);

            // Add a notification document to the Notifications collection
            const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            const notificationData = {
                type: "B", // Notification type
                identifier: order.customerUID,
                createdAt: formattedDateTime, // Formatted date and time
            };

            await addDoc(notificationsCollectionRef, notificationData);
            console.log("Notification document added successfully.");
        } catch (error) {
            console.error("Error moving order to pending collection:", error);
            alert("Error moving order to pending collection.");
        }
    };

    const moveToActive = async (orderID) => {
        if (!salesID || !customerID) {
            console.error("SalesID or CustomerID is missing.");
            alert("Order cannot be moved to Active: SalesID or CustomerID is not available.");
            return;
        }
        try {
            console.log("Moving order to Active");

            console.log("SalesID:", salesID);
            console.log("CustomerID:", customerID);
            console.log("Order ID:", orderID);

            // Reference to the order in the 'pending' collection
            const pendingDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/pending`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(pendingDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the pending collection");
            }

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData,
                status: "active",
                orderERP: orderERP,
                csrTime: Date.now(),
            };

            // Reference to the destination document in the "active" subcollection
            const activeDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/active`, orderID);

            // Copy the document to the "active" subcollection with updated status
            await setDoc(activeDocRef, updatedOrderData);

            // Delete the document from the "pending" subcollection
            await deleteDoc(pendingDocRef);

            console.log("Order moved to Active collection and status updated to active.");
            alert("Order moved to Active collection and status updated to active.");

            // Generate formatted date and time
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            };
            const formattedDateTime = now.toLocaleString('en-US', options);

            // Add a notification document to the Notifications collection
            const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            const notificationData = {
                type: "A", // Notification type
                identifier: orderERP,
                createdAt: formattedDateTime, // Formatted date and time
            };

            await addDoc(notificationsCollectionRef, notificationData);
            console.log("Notification document added successfully.");
            
            
            
            
            // Add a notification document to the Notifications collection
            const performanceCollectionRef = collection(db, `Users/${currentUser.uid}/Performance`);
            const performanceData = {
                orderNum: orderERP,
                submittedTime: order.submittedTime,
                csrTime: Date.now(),
                salesCode: order.salesCode,
            };

            await addDoc(performanceCollectionRef, performanceData);
            console.log("Performance document added successfully.");
            
            
            
            
        } catch (error) {
            console.error("Error moving order to active collection:", error);
            alert("Error moving order to active collection.");
        }
    };



    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [bgColor, setBgColor] = useState("#efefef");
    const [textColor, setTextColor] = useState("#21b372");
    const [labelColor, setLabelColor] = useState("#ffffff");

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setBgColor("#2e2e2e");   // Dark background color for dark mode
            setTextColor("#21b372"); // Light text color for dark mode
            setLabelColor("#ffffff");
        } else {
            setBgColor("#f7f7f7");   // Light background color for light mode
            setTextColor("#000000"); // Dark text color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const myTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };

    const [options, setOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Order Product Makeup",
        },
        subtitle: {
            text: "By Total Price",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "total",
                angleKey: "total",
                sectorLabelKey: "name",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `$${numberWithCommas(order.grandTotal)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: $${numberWithCommas(datum[calloutLabelKey])}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    // Update chart options whenever chartData is updated
    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: chartData,
            background: {
                fill: bgColor, // Update background color dynamically
            }
        }));
         // eslint-disable-next-line
    }, [chartData, darkMode, bgColor]);

    const downloadJSON = () => {
        const dataStr = JSON.stringify(order, null, 2); // Convert data to JSON string
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${order.id}.json`; // Use a unique name for each file
        link.click();

        URL.revokeObjectURL(url); // Clean up the URL object
    };

    const today = new Date();
    const deliveryDate = order.deliveryDate ? new Date(order.deliveryDate) : null;

    // Calculate the difference in days if deliveryDate is valid
    const daysRemaining = deliveryDate
        ? Math.ceil((deliveryDate - today) / (1000 * 60 * 60 * 24))
        : null;


    const downloadQuotePDF = async () => {
        const docDefinition = createQuoteTemplate(order);
        pdfMake.createPdf(docDefinition).download(`${order.customerCode}_quote_.pdf`);
    };
    
    const [products, setProducts] = useState(order.products);
    const [isModified, setIsModified] = useState(false);

    
    const handleInputChange = (index, field, value) => {
        const updatedPrices = products.map((price, i) =>
            i === index ? { ...price, [field]: value } : price
        );
        setProducts(updatedPrices);
        setIsModified(true);
    };

    const formatTime = (timestamp) => {
      if (!timestamp) return ""; // Return an empty string for null or undefined values
      // Convert the timestamp to a Date object
      const date = new Date(timestamp);
    
      // Get hours, minutes, and period (AM/PM)
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? "PM" : "AM";
    
      // Convert to 12-hour format
      hours = hours % 12 || 12; // Convert 0 to 12 for midnight
    
      // Format minutes to always be 2 digits
      const formattedMinutes = minutes.toString().padStart(2, "0");
    
      // Return the formatted time string
      return `${hours}:${formattedMinutes} ${period}`;
    };
    
    const formatAddressWithLineBreaks = (address) => {
        if (!address || address === "---") return "---";
        return address.split(",").map((part, index) => (
            <React.Fragment key={index}>
                {part.trim()}
                <br />
            </React.Fragment>
        ));
    };

    
    return (
        <div className={`w-full overflow-x-hidden overflow-y-hidden h-fit flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className={`relative z-40 w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center justify-center mr-3">

                        {order.status === "planned" && (
                            <div>

                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={toggleDeleting}
                                >
                                    <TrashIcon className="h-5 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "active" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            {/*<div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>*/}
                            {order.salesPhoto ? (
                                <img
                                    src={order.salesPhoto}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>
                            )}
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>


                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-lg hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1"
                                 onClick={() => {
                                     const textToCopy = order.customerCode || "- - - - -";
                                     navigator.clipboard.writeText(textToCopy)
                                         .then(() => {
                                             alert("Customer code copied to clipboard!");
                                         })
                                         .catch(err => {
                                             console.error("Failed to copy text: ", err);
                                         });
                                 }}
                            >{order.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.customerName || "- - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.customerEmail || "- - - - -"} | {formatPhoneNumber(order.customerPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>


                    {/*ERP*/}
                    <div className={`flex w-1/6 items-center justify-center h-full text-sm px-4 rounded-full line-clamp-1`}>
                        {order.status === "active" && (
                            <div>

                                <div className={`w-fit p-1 h-8 ${darkMode ? 'border-darkBgColor' : ''} rounded-full border flex flex-row hover:scale-105`}>
                                    <div className="flex items-center justify-center h-full text-xs line-clamp-1">CO-{order.orderERP || "- - - - -"}</div>
                                </div>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>

                                <div>

                                    <Button className={`w-fit px-2 py-1.5 ${darkMode ? 'hover:scale-105' : ''} bg-green-700 text-white line-clamp-1 rounded-xl shadow-md text-xs hover:bg-opacity-50 flex flex-row items-center justify-center`}
                                        onClick={toggleAdding}
                                            disabled={disabled}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>ERP

                                        {disabled && (
                                            <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 py-0.5`}>Unauthorized Access</div>
                                        )}


                                    </Button>

                                </div>

                            </div>
                        )}

                        {order.status === "planned" && (
                            <div>

                                <div>

                                    <Button className="w-fit px-2 py-1.5 bg-green-700/50 text-white line-clamp-1 rounded-xl shadow-md text-xs hover:bg-opacity-50 flex flex-row items-center justify-center"
                                            disabled={true}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Order Num

                                        <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 py-0.5`}>Order Must Be Pending</div>

                                    </Button>

                                </div>

                            </div>
                        )}
                    </div>


                    {/*Date / Grand Total*/}
                    <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        {/*Status*/}
                        <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                            {order.status === "active" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Ordered</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "pending" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ClockIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "planned" && (
                                <div className={`flex flex-row`}>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-plannedColor' : 'bg-plannedColor border text-blue-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}>
                                        <ClipboardDocumentIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Planned</div>
                                    </div>
                                    
                                </div>
                            )}

                            {order.status === "shipped" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-shippedColor text-black' : 'bg-shippedColor border'} rounded-full flex flex-row hover:scale-105`}>
                                        <TruckIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Shipped</div>
                                    </div>

                                </div>
                            )}

                        </div>

                        {/*Date / Submit Button*/}
                        <div className="w-1/3 p-1 h-full">
                            <div className="flex items-center justify-center h-full text-sm px-4 line-clamp-1">
                                {order.status === "planned" ? (
                                    <div>

                                        <Button className="w-fit px-2 py-1.5 hover:scale-105 bg-green-700 text-white line-clamp-1 rounded-xl shadow-lg text-xs hover:bg-opacity-50 flex flex-row items-center justify-center"
                                                onClick={toggleSubmitting}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Submit</Button>

                                    </div>
                                ) : (

                                    <div className="justify-center flex flex-col h-full ml-2.5">
                                        <div className={`flex items-center text-xs`}>
                                            <div className={`mr-2 font-bold`}>[O]:</div>
                                            <div className="text-xs">{order.orderDate || "--/--/----"}</div>
                                        </div>

                                        <div className={`text-xs items-center flex`}>
                                            {daysRemaining !== null && (
                                                <div className={`flex ${order.status === "active" ? '' : (daysRemaining < 5 ? 'text-red-500 animate-heartbeat hover:animate-none' : '')}`}><div className={`mr-2 font-bold`}>[D]:</div>
                                                    <span className="line-clamp-1">
                                                        {order.deliveryDate || "--/--/----"} {order.status !== "active" && `[${daysRemaining}]`}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/*Order Total*/}
                        <div className="w-1/3 h-8 items-center justify-end flex">
                            <div className={`flex bg-green-600/15 border-green-600 border w-fit items-center justify-end h-full text-sm px-2 hover:scale-105 rounded-full line-clamp-1 tracking-wider`}>$ {numberWithCommas(order.grandTotal.toFixed(2)) || "- - - - -"} <span className="hidden xl:inline ml-1">USD</span></div>
                        </div>
                    
                    </div>


                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center ml-3">
                        <Button
                            className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            )}

                        </Button>
                    </div>


                </div>

            </div>


            {isVisible && (

                <>
                    <main className={`relative h-fit animate-fadeIn`}>
                        
                        <svg
                            aria-hidden="true"
                            className={`absolute inset-0 z-10 size-full ${darkMode ? 'stroke-white/10' : 'stroke-black/10'} [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]`}
                        >
                            <defs>
                                <pattern
                                    x="50%"
                                    y={-1}
                                    id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                    width={200}
                                    height={200}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M.5 200V.5H200" fill="none"/>
                                </pattern>
                            </defs>
                            <svg x="50%" y={-1} className="overflow-visible fill-gray-800/0">
                                <path
                                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                    strokeWidth={0}
                                />
                            </svg>
                            <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%"
                                  strokeWidth={0}/>
                        </svg>
                    
                        <div
                            aria-hidden="true"
                            className="absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                        >
                            <div
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            />
                        </div>
                        
                        <div
                            aria-hidden="true"
                            className="absolute -top-80 left-[max(6rem,33%)] transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
                        >
                            <div
                                style={{
                                    clipPath:
                                        'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
                                }}
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-30"
                            />
                        </div>
                        
                        
                        {/*Header*/}
                        <header className="relative isolate z-50">
                            
                            <div className="mx-auto w-full px-4 py-3 sm:px-6 lg:px-8">
                                <div
                                    className="mx-auto flex w-full relative items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                                    <div className="flex items-center gap-x-6">
                                        <div
                                            className={`${darkMode ? 'bg-darkBgColor border border-neutral-600' : 'bg-neutral-100 border'} rounded-full p-3 size-16 flex items-center justify-center`}>
                                            <img
                                                alt=""
                                                src={logo}
                                                className="flex-none"
                                            />
                                        </div>
                                        <h1>
                                            <div className="text-sm/6">Order: <span
                                                className="text-gray-500"># CO-{order.orderERP || "- - - - - -"}</span>
                                            </div>
                                            <div className="mt-1 text-base font-semibold">{order.customerName}</div>
                                        </h1>
                                    </div>
                                    
                                    {viewID && (
                                        <div className={`absolute left-1/2 transform -translate-x-1/2 text-center`}>
                                            <div className={`flex items-center bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-4 py-1`}>
                                                {/* Circular Progress Bar */}
                                                <div className="relative w-4 h-4 mr-2"> {/* Smaller size */}
                                                    <svg className="absolute top-0 left-0 transform rotate-90"
                                                         width="100%" height="100%" viewBox="0 0 36 36">
                                                        <path
                                                            className="circle-background"
                                                            fill="none"
                                                            stroke="#e6e6e6"
                                                            strokeWidth="5"
                                                            strokeDasharray="100"
                                                            strokeDashoffset="0"
                                                            d="M18 2 A16 16 0 1 1 18 34 A16 16 0 1 1 18 2"
                                                        />
                                                        <path
                                                            className="circle-progress"
                                                            fill="none"
                                                            stroke="#4caf50"
                                                            strokeWidth="5"
                                                            strokeDasharray="100"
                                                            strokeDashoffset="100"
                                                            d="M18 2 A16 16 0 1 1 18 34 A16 16 0 1 1 18 2"
                                                            style={{animation: "progress 5s linear forwards"}}
                                                        />
                                                    </svg>
                                                </div>
                                                
                                                {/* Order ID */}
                                                <div className={`text-xs`}>{order.id}</div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                                        <Button
                                            type="button"
                                            className="hidden text-sm/6 hover:scale-105 font-semibold sm:block"
                                            onClick={handleViewIDToggle}
                                        >
                                            View ID
                                        </Button>
                                        <div className={`flex flex-row items-center gap-4 relative px-4`}>
                                            {/*<Button href="#"*/}
                                            {/*    disabled={order.status !== "planned"}*/}
                                            {/*    className="hidden text-sm/6 hover:scale-105 font-semibold sm:block"*/}
                                            {/*>*/}
                                            {/*    Edit*/}
                                            {/*</Button>*/}
                                            
                                            <Field className="flex items-center">
                                                <Switch
                                                    checked={isEditing}
                                                    onChange={setEditing}
                                                    className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                >
                                                    <span className="sr-only">Use setting</span>
                                                    <span aria-hidden="true"
                                                          className="pointer-events-none absolute size-full rounded-md"/>
                                                    <span
                                                        aria-hidden="true"
                                                        className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                    />
                                                    <span
                                                        aria-hidden="true"
                                                        className="pointer-events-none absolute left-0 size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5 flex items-center justify-center"
                                                    >
                                                        <PencilSquareIcon
                                                            className={`w-3.5 stroke-2 text-neutral-500`}/>
                                                    </span>
                                                </Switch>
                                                {/*<Label as="span" className="ml-3 text-sm">*/}
                                                {/*    <span className="font-medium">Prepay Order</span>{' '}*/}
                                                {/*    /!*<span className="text-gray-500">(Save 10%)</span>*!/*/}
                                                {/*</Label>*/}
                                            </Field>
                                            
                                            <Button
                                                disabled={order.status !== "planned"}
                                                className={`rounded-md px-3 py-1 text-sm font-semibold text-white shadow-sm ${order.status !== "planned" ? '' : 'hover:bg-opacity-50 transform transition-transform duration-200'} ${isModified ? 'animate-rubberBandInfinite bg-blue-500' : 'bg-green-600'}`}
                                            >
                                                Update
                                            </Button>
                                            
                                            {order.status !== "planned" && (
                                                <div
                                                    className={`w-full z-50 left-0 top-0 text-center text-sm absolute bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 ${darkMode ? 'text-white' : 'text-black'} flex items-center justify-center line-clamp-1`}
                                                    style={{
                                                        height: 'fit-content',
                                                        transform: 'translateY(-50%)',
                                                        top: '50%'
                                                    }}
                                                >
                                                    Incorrect Status
                                                </div>
                                            )}
                                        </div>
                                        
                                        <Menu as="div" className="relative sm:hidden">
                                            <MenuButton className="-m-3 block p-3">
                                                <span className="sr-only">More</span>
                                                <EllipsisVerticalIcon aria-hidden="true"
                                                                      className="size-5 text-gray-500"/>
                                            </MenuButton>
                                            
                                            <MenuItems
                                                transition
                                                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                            >
                                                <MenuItem>
                                                    <button
                                                        type="button"
                                                        className="block w-full px-3 py-1 text-left text-sm/6 text-gray-900 hover:text-opacity-50 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                    >
                                                        View ID
                                                    </button>
                                                </MenuItem>
                                                <MenuItem>
                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                    <a
                                                        href="#"
                                                        className="block px-3 py-1 text-sm/6 text-gray-900 hover:text-opacity-50 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                    >
                                                        Edit
                                                    </a>
                                                </MenuItem>
                                            </MenuItems>
                                        </Menu>
                                    
                                    
                                    </div>
                                
                                
                                </div>
                            </div>
                            
                        </header>
                        
                        {/*Body*/}
                        <div className="z-50 mx-auto px-4 py-3 sm:px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                
                                {/* Invoice summary */}
                                <div className="z-50 lg:col-start-3 lg:row-end-1">
                                    <h2 className="sr-only">Summary</h2>
                                    <div
                                        className={`z-50 rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-bgColor/50'} shadow-lg ring-1 ring-gray-900/5`}>
                                        <dl className="flex flex-wrap">
                                            <div className="flex-auto pl-6 pt-6">
                                                <dt className="text-sm/6 font-semibold">Total:</dt>
                                                <dd className="mt-1 text-base font-semibold">${numberWithCommas(order.grandTotal.toFixed(2))}</dd>
                                            </div>
                                            <div className="flex-none self-end px-6 pt-4">
                                                <dt className="sr-only">Status</dt>
                                                
                                                <dd className={``}>
                                                    {order.status === "active" && (
                                                        <div>
                                                            
                                                            <div
                                                                className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                                                
                                                                <div
                                                                    className={`flex-none rounded-full h-4 p-1 bg-orderColor/30 mr-3`}>
                                                                    <div
                                                                        className={`size-2 rounded-full bg-orderColor`}/>
                                                                </div>
                                                                
                                                                <div
                                                                    className="flex items-center justify-center h-full text-xs mr-3">Ordered
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    )}
                                                    
                                                    {order.status === "pending" && (
                                                        <div>
                                                            
                                                            <div
                                                                className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                                                
                                                                <div
                                                                    className={`flex-none rounded-full h-4 p-1 bg-pendingColor/30 mr-3`}>
                                                                    <div
                                                                        className={`size-2 rounded-full bg-pendingColor`}/>
                                                                </div>
                                                                
                                                                <div
                                                                    className="flex items-center justify-center h-full text-xs mr-3">Pending
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    )}
                                                    
                                                    {order.status === "planned" && (
                                                        <div>
                                                            
                                                            <div
                                                                className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-plannedColor' : 'bg-plannedColor border text-blue-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}>
                                                                
                                                                <div
                                                                    className={`flex-none rounded-full h-4 p-1 bg-plannedColor/30 mr-3`}>
                                                                    <div
                                                                        className={`size-2 rounded-full bg-plannedColor`}/>
                                                                </div>
                                                                
                                                                <div
                                                                    className="flex items-center justify-center h-full text-xs mr-3">Planned
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    )}
                                                    
                                                    {order.status === "shipped" && (
                                                        <div>
                                                            
                                                            <div
                                                                className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-shippedColor text-black' : 'bg-shippedColor border'} rounded-full flex flex-row hover:scale-105`}>
                                                                
                                                                <div
                                                                    className={`flex-none rounded-full h-4 p-1 bg-shippedColor/30 mr-3`}>
                                                                    <div
                                                                        className={`size-2 rounded-full bg-shippedColor`}/>
                                                                </div>
                                                                
                                                                <div
                                                                    className="flex items-center justify-center h-full text-xs mr-3">Shipped
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    )}
                                                </dd>
                                            </div>
                                            <div className={`mt-6 flex w-full flex-none gap-x-4 border-t ${darkMode ? 'border-neutral-700' : ''} px-6 pt-6`}>
                                                <dt className="flex-none">
                                                    <UserCircleIcon aria-hidden="true"
                                                                    className="h-6 w-5 text-gray-400"/>
                                                </dt>
                                                <dd className="text-sm/6 font-medium">{order.customerName || "- - - -"}</dd>
                                            </div>
                                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                <dt className="flex-none">
                                                    <EnvelopeIcon aria-hidden="true" className="h-6 w-5 text-gray-400"/>
                                                </dt>
                                                <dd className="text-sm/6">
                                                    <div
                                                        className="text-sm/6 font-medium">{order.customerEmail || "- - - -"}</div>
                                                </dd>
                                            </div>
                                            
                                            {order.earlyOrder && (
                                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                    <dt className="flex-none">
                                                        <CheckBadgeIcon aria-hidden="true"
                                                                        className="h-6 w-5 stroke-2 animate-pulse text-emerald-500"/>
                                                    </dt>
                                                    <dd className="text-sm/6">Early Order</dd>
                                                </div>
                                            )}
                                            
                                            {order.prePay && (
                                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                    <dt className="flex-none">
                                                        <CreditCardIcon aria-hidden="true"
                                                                        className="h-6 w-5 stroke-2 animate-pulse text-emerald-500"/>
                                                    </dt>
                                                    <dd className="text-sm/6">
                                                        <div className="text-sm/6">Prepay Order</div>
                                                    </dd>
                                                </div>
                                            )}
                                        </dl>
                                        <div
                                            className={`mt-6 border-t ${darkMode ? 'border-neutral-700' : ''} px-6 py-6`}>
                                            <Button
                                                className="flex flex-row text-sm/6 font-semibold hover:text-green-600"
                                                onClick={downloadQuotePDF}
                                            >
                                                <img
                                                    alt=""
                                                    src={pdfIcon}
                                                    className="flex-none w-5 mr-3"
                                                />
                                                Download Quote PDF <span aria-hidden="true">&rarr;</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Invoice */}
                                <div className={`z-20 -mx-4 px-4 py-4 shadow-lg border ${darkMode ? 'border-neutral-700 bg-darkMainColor' : 'bg-white'} sm:mx-0 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2`}>
                                    
                                    
                                    <h2 className="text-base font-semibold">Order</h2>
                                    
                                    {/*Date*/}
                                    <dl className="mt-6 grid grid-cols-1 text-sm/6 sm:grid-cols-2">
                                        <div className="sm:pr-4">
                                            <dt className="inline mr-3 font-semibold">Placed on:</dt>
                                            {' '}
                                            <dd className="inline text-gray-400">
                                                <time dateTime="2023-23-01">{order.orderDate} {order.submittedTime ? ` | ${formatTime(order.submittedTime)}` : "--:--"}</time>
                                            </dd>
                                        </div>
                                        <div className="mt-2 sm:mt-0 sm:pl-4 flex">
                                            <dt className="inline mr-3 font-semibold">Intended By:</dt>
                                            {' '}
                                            <dd className="inline text-gray-400">
                                                {daysRemaining !== null && (
                                                    <div className={`flex ${order.status === "active" ? '' : (daysRemaining < 5 ? 'text-red-500 animate-heartbeat hover:animate-none' : '')}`}>
                                                        <div className={`mr-2 font-bold`}></div>
                                                        <span className="line-clamp-1">{order.deliveryDate || "--/--/----"} {order.status !== "active" && `[${daysRemaining}]`}</span>
                                                    </div>
                                                )}
                                            </dd>
                                        </div>
                                        <div
                                            className={`mt-6 border-t-2 ${darkMode ? 'border-neutral-700' : ''} pt-6 sm:pr-4`}>
                                            <dt className="font-semibold">From:</dt>
                                            <dd className="mt-2 text-gray-400">
                                                <span className="font-medium">Plant Food Company Inc.</span>
                                                <br/>
                                                38 Hightstown-Cranbury Station Rd,
                                                <br/>
                                                Cranbury, NJ 08512
                                            </dd>
                                        </div>
                                        <div
                                            className={`mt-8 sm:mt-6 sm:border-t-2 ${darkMode ? 'sm:border-neutral-700' : ''} sm:pl-4 sm:pt-6 relative`}>
                                            <dt className="font-semibold flex items-center">To:
                                                {order.callBefore && (
                                                    <PhoneIcon aria-hidden="true" className=" stroke-2 w-5 h-5 ml-2 text-emerald-500 animate-bounce"/>
                                                )}
                                            </dt>
                                            
                                            <dd className="mt-2 text-gray-400">
                                                <span className="font-medium">{order.customerName || "- - - -"}</span>
                                                <br/>
                                                {formatAddressWithLineBreaks(order.address) || "---"}
                                                <br/>
                                            </dd>
                                        </div>
                                    </dl>
                                    
                                    
                                    {/*Product Table*/}
                                    <table className="mt-12 w-full whitespace-nowrap text-left text-sm/6">
                                        <colgroup>
                                            <col className="w-full"/>
                                            <col/>
                                            <col/>
                                            <col/>
                                        </colgroup>
                                        <thead className={`border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                        <tr className={`text-gray-500`}>
                                            <th scope="col" className="px-0 py-3 font-semibold">
                                                LineItems [{order.products.length}]
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Price
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Quantity
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                U/M
                                            </th>
                                            <th scope="col"
                                                className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                WH
                                            </th>
                                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {products.map((item, index) => (
                                            <tr key={item.id}
                                                className={`border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                <td className="max-w-0 px-0 align-middle">
                                                    <div
                                                        className="truncate font-medium indent-3">{index + 1}] {item.name}</div>
                                                    <div
                                                        className="truncate text-gray-500 text-xxs indent-6">{item.productCode}</div>
                                                </td>
                                                <td className="items-center py-5 pl-8 pr-0 text-right align-top tabular-nums sm:table-cell flex">
                                                    $ {numberWithCommas(parseFloat(item.price).toFixed(2))} <span
                                                    className={`ml-2 text-gray-500 font-bold text-xs`}>{item.discountAmount}%</span>
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums sm:table-cell">
                                                    {isEditing ? (
                                                        <div className={`relative pt-1.5 pr-1.5`}>
                                                            <div
                                                                className={`top-0 right-0 absolute flex-none rounded-full p-1 bg-blue-500/30 animate-heartbeat`}>
                                                                <div className={`size-2 rounded-full bg-blue-500`}/>
                                                            </div>
                                                            
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className={`rounded-lg text-right block w-full px-2 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                                                placeholder="Quant"
                                                                value={item.quantity}
                                                                onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    ) : (
                                                        <span>{numberWithCommas(item.quantity)}</span>
                                                    )}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top sm:table-cell">
                                                    {item.selectedVolume}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top sm:table-cell">
                                                    {item.warehouse}
                                                </td>
                                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums">$ {numberWithCommas(parseFloat(item.total).toFixed(2))}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th scope="row" className="px-0 pb-0 pt-6 font-normal sm:hidden">
                                                Subtotal
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden px-0 pb-0 pt-6 text-right font-normal sm:table-cell text-gray-500"
                                            >
                                                Subtotal:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-500">${numberWithCommas(parseFloat(order.subTotal).toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-normal sm:hidden">
                                                Discount
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden pt-4 text-right font-normal sm:table-cell text-gray-500"
                                            >
                                                Discount [-{order.discount}%]:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-500">${numberWithCommas((order.subTotal - order.grandTotal).toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-semibold sm:hidden">
                                                Total
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden pt-4 text-right font-semibold sm:table-cell"
                                            >
                                                Total:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums">
                                                ${numberWithCommas(parseFloat(order.grandTotal).toFixed(2))}
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                
                                    {/*Notes*/}
                                    <div className={`z-50 mt-8 flex flex-col h-fit rounded-lg shadow-lg ring-1 ring-gray-900/5`}>
                                        <Textarea
                                            id=""
                                            name=""
                                            disabled={true}
                                            value={order.notes}
                                            autoComplete=""
                                            placeholder="Notes..."
                                            // onChange={(e) => setNotes(e.target.value)}
                                            className={`z-50 block w-full focus:outline-none ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} p-2 h-32 rounded-md shadow-gray-900 py-1.5 placeholder:text-gray-400`}
                                        />
                                    
                                    </div>
                                
                                
                                </div>
                                
                                {/*Analytics*/}
                                <div className={`z-50 lg:col-start-3`}>
                                    {/* Activity feed */}
                                    <div className={`z-50 flex flex-col h-fit p-1 rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-bgColor/50'} shadow-lg ring-1 ring-gray-900/5`}>
                                        {loading ?
                                            <div className="text-white">Loading chart...</div>
                                            :
                                            <div className={`transition-transform duration-500 ease-in-out`}>
                                                <AgCharts options={options}/>
                                            </div>
                                        }
                                    </div>
                                
                                </div>
                            
                            
                            </div>
                        </div>
                    
                    </main>
                </>
            
            
            )}
            
            
            <Dialog open={isAdding} onClose={setIsAdding} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Connect Order to ERP
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Make sure that you have reviewed all of the order's information. Once you have enter
                                    in the Order Number generated by ERP
                                </p>
                            </div>
                            
                            
                            <div className={`flex mt-2 flex-row items-center`}>
                                <div className={`tracking-widest text-gray-500 mr-1`}>CO-</div>
                                
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className={`rounded-lg block w-full p-2.5 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                    placeholder="Type order number"
                                    value={orderERP} onChange={(e) => setOrderERP(e.target.value)}
                                    required
                                />
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => moveToActive(order.id)}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Connect
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setIsAdding(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isDeleting} onClose={setDeleting} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-600/15 sm:mx-0 sm:size-10">
                        <TrashIcon aria-hidden="true" className="size-6 text-red-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Delete | {order.customerCode}'s Order
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete this Order? All of its data will be permanently removed from
                            our servers forever. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={onDelete}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        Delete
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => setDeleting(false)}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isSubmitting} onClose={setSubmitting} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <DocumentArrowUpIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold">
                          Submit | {order.customerCode}'s Order to plant
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to submit this Order? All of its data will be permanently submitted to
                            the plant. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={() => moveToPending(order.id)}
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        data-autofocus
                        onClick={() => setSubmitting(false)}
                        className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                      >
                        Cancel
                      </Button>
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>

        
        </div>
    )
}

export default OrderCard;
