import React, {useEffect, useState} from 'react';
import logo from "../main-logo.png";
import {Link, Navigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext";
import {doCreateUserWithEmailAndPassword} from "../firebase/auth";
import {db} from "../firebase/firebase";
import {Select} from "@headlessui/react";
import users from '../users.json';


const SignUp = () => {

    const { userLoggedIn } = useAuth();

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");


    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [region, setRegion] = useState("");
    const [accountLevel, setAccountLevel] = useState("");

    const [supervisor, setSupervisor] = useState("");
    const [salesCode, setSalesCode] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [isRegistering, setIsRegistering] = useState(false);


    useEffect(() => {
        const fullName = `${fname} ${lname}`;

        // Access the users array inside the data object
        const person = users[0].users.find(item => item.name === fullName);

        // If found, set the code, otherwise display a not found message
        if (person) {
            setSalesCode(person.code);
            setSupervisor(person.super);
        } else {
            setSalesCode('Sales Code Not Found');
            setSupervisor('Supervisor Not Found');
        }
    }, [fname, lname]);




    const onSubmit = async (e) => {
        e.preventDefault();
        if (password === password2) {
            if (!isRegistering) {
                setIsRegistering(true);
                try {
                    // Create user with email and password
                    const userCredential = await doCreateUserWithEmailAndPassword(email, password);

                    // Access the user object from the userCredential
                    const user = userCredential.user;


                    // Add user data to Firestore collection
                    await db.collection('Users').doc(user.uid).set({
                        fname: fname,
                        lname: lname,
                        email: email,

                        phone: phone,
                        fax: fax,
                        address: address,
                        region: region,
                        accountLevel: accountLevel,
                        salesCode: salesCode
                    });

                    // Registration successful, user is now logged in
                    alert(`Account Successfully Created! Redirecting to Home Page!`); // Optional: alert for user feedback
                    setIsRegistering(false);
                } catch (error) {
                    console.error('Error registering user:', error.message);
                    alert(`Account Registration Failed! Check to make sure all fields are Correct!`); // Optional: alert for user feedback
                    setIsRegistering(false);
                }
            }
        }
    };

    return (
        <>

            {userLoggedIn && <Navigate to={'/SplashScreen'} replace={true} />}


            <div className="bg-bgColor flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">


                <header className="absolute inset-x-0 top-0 z-50">
                    <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">

                        <div className="flex lg:flex-1">

                            <img
                                alt=""
                                src={logo}
                                className="m-1.5 p-1.5 h-16 w-auto"
                            />

                        </div>

                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <Link className="text-sm font-semibold leading-6 text-gray-900"
                                  to={'/Login'}>
                                Return to Login <span aria-hidden="true">&rarr;</span>
                            </Link>
                        </div>
                    </nav>
                </header>


                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="Plant Food Company Inc."
                        src={logo}
                        className="mx-auto h-24 w-auto"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign up for your account
                    </h2>
                </div>

                <div className="mt-10 w-1/2 mr-auto ml-auto">
                    <form onSubmit={onSubmit} method="POST" className="space-y-2">


                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    First Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="fname"
                                        name="fname"
                                        required
                                        autoComplete="fname"
                                        value={fname} onChange={(e) => setFname(e.target.value)}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>


                            <div className="w-1/2 ml-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Last Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="lname"
                                        name="lname"
                                        required
                                        autoComplete="lname"
                                        value={lname} onChange={(e) => setLname(e.target.value)}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="phone"
                                        required
                                        autoComplete="fname"
                                        value={phone} onChange={(e) => setPhone(e.target.value)}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>


                            <div className="w-1/2 ml-2.5">
                                <label htmlFor="fax" className="block text-sm font-medium leading-6 text-gray-900">
                                    Fax Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="fax"
                                        name="fax"
                                        autoComplete="fax"
                                        value={fax} onChange={(e) => setFax(e.target.value)}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-3/4 mr-2.5">
                                <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                    Address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        required
                                        autoComplete="address"
                                        value={address} onChange={(e) => setAddress(e.target.value)}
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>


                            <div className="w-1/4 ml-2.5">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                    Sales Industry
                                </label>
                                <div className="mt-2">
                                    <Select
                                        className="block h-full w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={region} onChange={(e) => setRegion(e.target.value)}
                                    >
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST">Distributor</option>
                                        <option value="COM">Commercial</option>
                                    </Select>
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="accountLevel"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Account Type
                                </label>
                                <div className="mt-2">
                                    <Select
                                        className="block h-full w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={accountLevel} onChange={(e) => setAccountLevel(e.target.value)}
                                    >
                                        <option value="S">Salesperson</option>
                                        <option value="CSR">CSR (Customer Service Rep)</option>
                                        <option value="PFC">PFC Fulfillment</option>
                                        <option value="DIST">Distributor</option>
                                    </Select>
                                </div>
                            </div>

                            <div className="w-1/4 mr-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Sales Code
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <div>
                                            {salesCode && <p>{salesCode}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Supervisor
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <div>
                                            {supervisor && <p>{supervisor}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password Confirmation
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password2"
                                    name="password2"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    value={password2} onChange={(e) => setPassword2(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>


                        <div>
                            <button
                                type="submit"
                                disabled={isRegistering}
                                className="flex w-full justify-center rounded-md bg-bgDarkGreen px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
};


export default SignUp;
