import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Input, Select} from "@headlessui/react";
import {ArrowDownTrayIcon, ArrowPathIcon, BookOpenIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import ProductCardV2 from "./Catalog/ProductCardV2";
import PDFModal from "./Catalog/PDFModal";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import catalogPDF from "../Plant Food Company_2024_Catalog.pdf"
import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";



const Catalog = () => {

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();


    // const [products] = useState(productsData.products);
    // const [filteredProducts, setFilteredProducts] = useState(productsData.products);
    const [searchQuery, setSearchQuery] = useState("");

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        
        
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };


        fetchProducts();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const productsCollection = db.collection("Products");
            const snapshot = await productsCollection.get();

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productData);
                setFilteredProducts(productData);
                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
            } else {
                console.log('Product documents not found');
            }

        } catch (error) {
            console.error("Error updating products", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Products Updated in ${timeElapsed} secs`);
    };
    
    const filterProductsByCatagory = (catagory) => {
        const filtered = products.filter(product => catagory === '' || product.category === catagory);
        setFilteredProducts(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
    }

    const [showModal, setShowModal] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);

    const handleOpenModal = (file) => {
        setPdfFile(file);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPdfFile(null);
    };


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            {/*<div className={`animate-fadeIn flex w-1/6 p-2 mr-3 ml-3 rounded-lg mt-3 overflow-y-auto hide-scrollbar mb-3 ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow-lg'} flex-col`}>*/}



            {/*</div>*/}


            <div className="z-50 animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Catalog
                    
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button
                                className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    
                    </div>
                    
                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>
                        
                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{products.length}</div>
                        </div>
                        
                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <a
                                href={catalogPDF}
                                download="PFC_2024_Catalog.pdf" // Specify the name for the downloaded file
                                className="font-semibold flex text-white bg-green-600 text-sm px-3 py-1 rounded-lg hover:opacity-50 line-clamp-1"
                            >
                                <ArrowDownTrayIcon className={`w-4 mr-3`}/>
                                Catalog
                            </a>
                        </div>
                        
                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{filteredProducts.length}</div>
                        </div>
                    
                    </div>
                
                </div>
                
                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>
                    
                    {/*Search Bar*/}
                    <div className="w-3/4 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div
                            className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Products By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    // onFocus={() => setShowProducts(true)}
                                    // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProductsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                                <option value="Equipment">Equipment</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Catalog Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full relative ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`relative overflow-y-auto hide-scrollbar h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100vh-12rem)]`}>

                                <div>

                                    {filteredProducts.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="absolute flex w-full h-full justify-center items-center">
                                            <div className={`flex items-center border-2 border-dashed ${
                                                    darkMode ? "text-white border-borderColor" : "text-black"
                                                } w-fit px-24 py-3 h-fit mr-auto ml-auto backdrop-blur-md justify-center rounded-lg hover:scale-105`}
                                            >
                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <BookOpenIcon className="w-10 stroke-2 mr-3"/>
                                                    <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">No Products Available</p>
                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex">Go to your profile and settings page to refresh the products list</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 auto-rows-auto">
                                            {filteredProducts
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((product, index) => (
                                                    <ProductCardV2
                                                        key={index}
                                                        product={product}
                                                        onOpenModal={handleOpenModal}/>
                                                ))}
                                        </div>

                                    )}

                                </div>

                            </div>


                        </div>


                    </div>

                </div>

            </div>


            {showModal && (
                <PDFModal
                    pdfFile={pdfFile}
                    onClose={handleCloseModal}
                />
            )}


        </div>
    )
};


export default Catalog;
