import React, {useState} from 'react';
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import logo from "../main-logo.png";
import course from "../course.jpg"
import {CheckIcon} from "@heroicons/react/24/outline";

const LandingPage = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleButtonClick = () => {
        setIsVisible(true);
    };
    return (
        
        <div className="bg-white overflow-y-hidden">
            
            <header className="absolute animate-fadeIn inset-x-0 top-0 z-50">
                <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src={logo}
                                className="h-16 w-auto"
                            />
                        </a>
                    </div>
                    
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="/login" className="text-sm/6 font-semibold text-gray-900">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
            </header>
            
            
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-green-100/20 pt-14">
                <div
                    aria-hidden="true"
                    className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-green-600/10 ring-1 ring-green-50 sm:-mr-80 lg:-mr-96"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                        <h1 className="animate-slideInLeft max-w-2xl text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl lg:col-span-2 xl:col-auto">
                            Welcome to the Plant Food Sales Portal
                        </h1>
                        <div className="animate-slideInLeft mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                            <p className="text-pretty text-md font-medium text-gray-500">
                                Welcome to the Plant Food Sales Portal, your all-in-one solution for managing orders,
                                exploring products, and streamlining sales processes. Designed with efficiency and ease
                                of use in mind, our platform empowers you to stay organized and focus on what matters
                                most—growing your business. Dive in and discover how we make sales smarter, faster, and simpler!
                            </p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a
                                    href="/login"
                                    className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Login
                                </a>
                                <Button onClick={handleButtonClick} className="text-sm/6 font-semibold text-gray-900">
                                    Learn more <span aria-hidden="true">→</span>
                                </Button>
                            </div>
                        </div>
                        <img
                            alt=""
                            src={course}
                            className="animate-slideInRight mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                        />
                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"/>
            </div>
        
        
            <Dialog open={isVisible} onClose={setIsVisible} className="relative z-10">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                  >
                    <div>
                      <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                          To Gain Access
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            This System is only available to Plant Food Co Employees! You must contact Spencer to access!
                          </p>
                        </div>
                      </div>
                    </div>
                      <div className="mt-5 sm:mt-6">
                          {/*<button*/}
                          {/*  type="button"*/}
                          {/*  onClick={() => setIsVisible(false)}*/}
                          {/*  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
                          {/*>*/}
                          {/*  Go back to dashboard*/}
                          {/*</button>*/}
                          <a
                              href="mailto:swondolowski@plantfoodco.com"
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50"
                          >
                              swondolowski@plantfoodco.com
                          </a>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
        </div>
    
    )
};


export default LandingPage;
