import React from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    CheckIcon,
} from "@heroicons/react/24/outline";

import imgDark from "../../Photos/PlantOrdersDark.png";
import imgLight from "../../Photos/PlantOrdersLight.png";


const PlantOrdersGuides = () => {

    const { darkMode } = useDarkMode();

    const posts = [
        {
            id: 1,
            title: 'Why is the Order Glistening?',
            description:
                "The order is glistening because that order was marked by the sales rep as an 'ASAP Order'. This shows the order's importance and is designed to catch your eye.",
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        {
            id: 2,
            title: 'How do I turn on Automatic Updates',
            description:
                'Click on the drop down called Reps. To the left there is a toggle switch called Automatic Updates you can switch that on. Once that toggle switch is on it saves to your settings and will update every 20 minutes.',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        {
            id: 3,
            title: 'Where did all my orders go?',
            description:
                'At Midnight on the first day of the quarter all active orders will be archived. You may reference these dates in the Archived Guides page.',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        {
            id: 4,
            title: 'How do I set an order to Active?',
            description:
                'In order to set the order as active you have to add in all of the necessary information into the ERP. Once in the ERP you have to enter in the order number generated and then that will set the order to active and move the location.',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        {
            id: 5,
            title: 'Why is one Line Item a different background color?',
            description:
                'If you see a line item in an order that is a pale green background color this means that the product is a No Charge. This will set the price to $0 and the reason code will appear in the Total column.',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        {
            id: 6,
            title: 'What is the View Id button?',
            description:
                'This button showing the internal database ID number. This number would be used to send to Spencer so he may locate the order in the database for issues.',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },{
            id: 7,
            title: 'Why is the delivery date pulsating red?',
            description:
                'A red pulsating delivery date means that the delivery date is within 5 days of the current day. This is designed to show urgency!',
            date: 'Jan 26, 2025',
            category: { title: 'Incoming Orders', href: '#' },
        },
        // {
        //     id: 8,
        //     title: 'How do I set an order to Active?',
        //     description:
        //         '',
        //     date: 'Jan 26, 2025',
        //     category: { title: 'Incoming Orders', href: '#' },
        // },
        // More posts...
    ]

    const features = [
      {
        name: 'Automatic Rep Syncing',
        description: 'Ensures that your order stream is filled with the orders from the reps that you are servicing',
      },
      {
        name: 'Automatic Refreshing',
        description: 'Keeps your dashboard up-to-date by automatically reloading data at regular intervals without manual intervention.',
      },
      {
        name: 'Order Alerts',
        description: 'Notifies you instantly about critical order updates, such as ASAP orders, late delivery dates.',
      },
      {
        name: 'Order Sorting',
        description: 'Allows users to easily organize and view orders based on criteria such as date, status, or order total.',
      },
      {
        name: 'Order Searching',
        description: 'Enables quick and efficient retrieval of specific orders using the name of the customer',
      },
      {
        name: 'Easy ERP Connection',
        description: 'Efficiently provides you all of the necessary order information in order to seamlessly add the order into our existing ERP system.',
      },
    ];

    
    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'} overflow-y-scroll animate-fadeIn`}>

            <div className="mx-auto px-6">
                <div className="mx-auto">
                    
                    <h2 className="text-pretty text-green-600 text-4xl font-semibold tracking-widest sm:text-5xl">Plant Incoming Orders</h2>
                    <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>The new order form in the internal sales tool offers an efficient solution for sales reps to manage and submit customer orders seamlessly.</p>
                    
                    {/*Image*/}
                    <div className="py-24 sm:py-12">
                        <div className="relative overflow-hidden pt-8">
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <img
                                    alt="App screenshot"
                                    src={darkMode ? imgDark : imgLight}
                                    width={2432}
                                    height={1442}
                                    className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                                />
                                <div aria-hidden="true" className="relative">
                                    <div
                                        className={`absolute -inset-x-20 bottom-0 bg-gradient-to-t ${darkMode ? 'from-darkMainColor' : 'from-white'} pt-[7%]`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/*Features*/}
                    <div className="py-24 sm:py-12">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div
                                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                                <div className="col-span-2">
                                    <h2 className="text-base/7 font-semibold text-green-600">Everything you need</h2>
                                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight sm:text-5xl">
                                        All Your Orders In One Location
                                    </p>
                                    <p className={`mt-6 text-base/7 ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit
                                        perferendis suscipit eaque, iste
                                        dolor cupiditate blanditiis ratione.
                                    </p>
                                </div>
                                <dl className="col-span-3 grid grid-cols-1 gap-x-8 gap-y-10 text-base/7 sm:grid-cols-2 lg:gap-y-16">
                                    {features.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="font-semibold">
                                                <CheckIcon aria-hidden="true" className="absolute left-0 top-1 size-5 text-green-600"/>
                                                {feature.name}
                                            </dt>
                                            <dd className={`mt-2 ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                    
                    {/*Guides*/}
                    <div className={`mt-8 border-t-4 border-dashed ${darkMode ? 'border-green-600' : 'border-green-600'} pt-8`}>
                        <div className="columns-1 sm:columns-2 gap-6 space-y-6">
                            {posts.map((post) => (
                                <article key={post.id}
                                         className="break-inside-avoid p-4 rounded-lg shadow-lg hover:scale-105">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className={`${darkMode ? 'text-neutral-500' : 'text-gray-500'}`}>
                                            {post.date}
                                        </time>
                                        <div
                                            className={`relative z-10 rounded-full ${darkMode ? 'bg-darkBgColor text-green-600 border border-neutral-700' : 'bg-bgColor border text-green-600'} px-3 py-1.5 font-medium`}>
                                            {post.category.title}
                                        </div>
                                    </div>
                                    <div className="group relative mt-4">
                                        <h3 className="text-lg font-semibold">
                                            <div>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </div>
                                        </h3>
                                        <p className={`mt-3 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            {post.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        
        </div>
    
    );
}

export default PlantOrdersGuides;