import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth} from "../contexts/authContext";
import logo from "../main-logo.png";
import {db, auth} from "../firebase/firebase";
import {
    Button, Combobox, ComboboxInput, ComboboxOption, ComboboxOptions,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    AdjustmentsHorizontalIcon,
    ArrowRightStartOnRectangleIcon, CheckIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    UserPlusIcon as OutlineUserPlusIcon, // Renaming the outline version
} from "@heroicons/react/24/outline";

import {
    Cog6ToothIcon,
    FolderIcon,
    XMarkIcon,
    ChartPieIcon,
    CheckBadgeIcon,
    BookOpenIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import {MoonIcon, SunIcon} from "@heroicons/react/24/outline";
import {doSignOut} from "../firebase/auth";
import {useDarkMode} from "../contexts/darkModeContext";
import Background from "./Background";
import course from "../course.jpg"
import lyme from "../lymeTrucks.jpg"
import CyberBackground from "./CyberBackground";
import {SnowOverlay} from "react-snow-overlay";
import {WindowIcon} from "@heroicons/react/16/solid";
import {onAuthStateChanged} from "firebase/auth";

const Header = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const [accountLevel, setAccountLevel] = useState("");

    const [profileImage, setProfileImage] = useState('');


    const {darkMode, toggleDarkMode} = useDarkMode();


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setProfileImage(userData.profileImageUrl);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setProfileImage(userData.profileImageUrl);
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time (hours and minutes only)
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every minute to avoid unnecessary updates every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);
        }, 60000); // Update every minute

        return () => clearInterval(interval);
    }, []);

    const [currentPath] = useState(window.location.pathname); // Initialize currentPath
    const [collapse, setCollapse] = useState(true);
    const [collapseSpin, setCollapseSpin] = useState(false);

    const [collapseCustomer, setCollapseCustomer] = useState(true);
    const [collapseSpinCustomer, setCollapseSpinCustomer] = useState(false);


    // Timeout reference to clear it when necessary
    let collapseTimeout;
    let collaspeTimeoutCustomer

    const handleMouseEnter = () => {
        clearTimeout(collapseTimeout); // Clear any existing timeout to prevent premature collapsing
        setCollapse(false); // Expand immediately on hover
    };

    const handleMouseLeave = () => {
        // Set a delay before collapsing again
        setCollapseSpin(true)
        collapseTimeout = setTimeout(() => {
            setCollapse(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpin(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };

    const handleMouseEnterCustomer = () => {
        clearTimeout(collaspeTimeoutCustomer); // Clear any existing timeout to prevent premature collapsing
        setCollapseCustomer(false); // Expand immediately on hover
    };

    const handleMouseLeaveCustomer = () => {
        // Set a delay before collapsing again
        setCollapseSpinCustomer(true);
        collaspeTimeoutCustomer = setTimeout(() => {
            setCollapseCustomer(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpinCustomer(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };


    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    };
    
    
    
    const projects = [
      { id: 1, name: 'Plant Food Company Inc.', url: 'https://www.plantfoodco.com/' },
      // More projects...
    ];
    
    const recent = [projects[0]];
    
    const quickActions = [
        // { name: 'Create a new order...', icon: DocumentPlusIcon, shortcut: '', url: '/NewOrder' },
        // { name: 'Add new customer...', icon: SolidUserPlusIcon, shortcut: '', url: '/NewCustomer' },
        { name: 'Go to settings...', icon: Cog6ToothIcon, shortcut: '', url: '/Profile' },
        { name: 'View Analytics...', icon: ChartPieIcon, shortcut: '', url: '/Dashboard' },
        { name: 'View Proven Programs...', icon: CheckBadgeIcon, shortcut: '', url: '/Proven' },
        { name: 'Go to Digital Catalog...', icon: BookOpenIcon, shortcut: '', url: '/Catalog' },
        { name: 'Go to Help & Resources', icon: QuestionMarkCircleIcon, shortcut: '', url: '/Guides' },
    ];
    
    const [query, setQuery] = useState('');
    const [expand, setExpand] = useState(false);
    
    const toggleExpand = () => {
        setExpand(!expand);
    };

    const filteredProjects = query === '' ? [] : quickActions.filter((project) => {
            return project.name.toLowerCase().includes(query.toLowerCase())
    });
    
    const backgroundElements = [
        { Lyman: course },
        { LymeTrucks: lyme },
        { CyberBackground: <CyberBackground /> }, // Custom component
    ];

    // State to track the current index and content
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentKey, setCurrentKey] = useState(Object.keys(backgroundElements[0])[0]);
    const [currentContent, setCurrentContent] = useState(Object.values(backgroundElements[0])[0]);

    // Load saved content from local storage on component mount
    useEffect(() => {
        const savedContent = localStorage.getItem("savedContent");
        const savedKey = localStorage.getItem("savedKey");

        if (savedContent && savedKey) {
            // setCurrentContent(JSON.parse(savedContent)); // Parse the saved component
            setCurrentKey(savedKey);
            
            const foundElement = backgroundElements.find((item) => item[savedKey]);
            if (foundElement) {
                setCurrentContent(foundElement[savedKey]);
            }

            const index = backgroundElements.findIndex((item) => Object.values(item)[0] === JSON.parse(savedContent));
            if (index !== -1) setCurrentIndex(index);
        }
            // eslint-disable-next-line
    }, []);

    // Function to handle cycling through images and components
    const handleContentClick = () => {
        const nextIndex = (currentIndex + 1) % backgroundElements.length;
        const nextKey = Object.keys(backgroundElements[nextIndex])[0];
        const nextContent = Object.values(backgroundElements[nextIndex])[0];

        setCurrentIndex(nextIndex);
        setCurrentKey(nextKey);
        setCurrentContent(nextContent);
    };

    // Function to save the current content (image or component) to local storage
    const handleSaveContent = () => {
        localStorage.setItem("savedKey", currentKey);
        alert(`Saved ${currentKey} to local storage.`);
    };
    
    // Initialize state with a default value of `false`, but use the value from local storage if it exists
    const [snowing, setSnowing] = useState(() => {
        const savedState = localStorage.getItem('snowing');
        return savedState === 'true'; // Convert string to boolean
    });

    // Function to toggle the snowing state
    const toggleSnow = () => {
        setSnowing((prev) => {
            const newState = !prev;
            localStorage.setItem('snowing', newState); // Save updated state to local storage
            return newState;
        });
    };

    // Ensure local storage value is synced when the component mounts
    useEffect(() => {
        const savedState = localStorage.getItem('snowing');
        if (savedState) {
            setSnowing(savedState === 'true'); // Convert string to boolean
        }
    }, []);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (!user) {
            navigate("/"); // Redirect to "/" if the user is not authenticated
          }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);



    return (
        <div className={`w-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} z-20 h-16 pr-3 pt-3 pl-3 absolute top-0 left-0 transition-all duration-500 ease-in-out`}>

            <Background/>
            
            <SnowOverlay
                speed={"FAST"}
                disabled={snowing}
            />
            
            <div className={`flex flex-row ${darkMode ? 'bg-darkMainColor' : 'bg-white'} justify-between items-center w-full h-full rounded-md drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}>

                {/*Links*/}
                <div className="w-1/3 flex flex-row">

                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>
                            <Link to="/NewCustomer"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 rounded-full ${currentPath === '/NewCustomer' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapseCustomer ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnterCustomer}  // Expands on hover
                                  onMouseLeave={handleMouseLeaveCustomer}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapseCustomer ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapseCustomer ? '' : 'mr-32'}`}>
                                    <OutlineUserPlusIcon className={`w-5 flex-none ${currentPath === '/NewCustomer' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapseCustomer ? 'text-black' : 'text-white'} ${collapseSpinCustomer ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white absolute left-10 text-sm transition-opacity duration-500 ease-in-out line-clamp-1 ${collapseCustomer ? 'hidden' : ''}`}>New Customer Form</div>
                            </Link>


                            <Link to="/NewOrder"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 mr-auto rounded-full ${currentPath === '/NewOrder' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapse ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnter}  // Expands on hover
                                  onMouseLeave={handleMouseLeave}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapse ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapse ? '' : 'mr-32'}`}>
                                    <PlusIcon className={`w-6 flex-none ${currentPath === '/NewOrder' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapse ? 'text-black' : 'text-white'} ${collapseSpin ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-sm absolute left-12 transition-opacity duration-500 ease-in-out line-clamp-1 ${collapse ? 'hidden' : ''}`}>New Order Form</div>
                            </Link>
                        </>
                    )}


                </div>

                {/*Middle*/}
                <div className="w-1/3 flex flex-row items-center justify-center">

                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{date}</div>

                    {/*<img*/}
                    {/*    alt=""*/}
                    {/*    src={logo}*/}
                    {/*    className="h-10 hover:rotate-2 hover:animate-heartbeat w-auto mr-16 ml-16"*/}
                    {/*/>*/}
                    
                    
                    <Button className={`p-2`}
                        onClick={toggleExpand}
                    >
                        <img
                            alt=""
                            src={logo}
                            className="h-8 hover:rotate-2 hover:animate-heartbeat w-auto mr-16 ml-16"
                        />
                        {/*<MagnifyingGlassIcon className={`w-4 stroke-2`}/>*/}
                    </Button>
                    
                    <Dialog
                      className="relative z-50"
                      open={expand}
                      onClose={() => {
                        setExpand(false)
                        setQuery('')
                      }}
                    >
                      <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-darkMainColor/25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                      />
                
                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                        <DialogPanel
                          transition
                          className={`mx-auto max-w-2xl transform divide-y divide-gray-500/20 overflow-hidden rounded-xl ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} shadow-2xl transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in`}
                        >
                          <Combobox
                            onChange={(item) => {
                              if (item) {
                                window.location = item.url
                              }
                            }}
                          >
                            <div className="grid grid-cols-1">
                              <ComboboxInput
                                autoFocus
                                className="col-start-1 row-start-1 h-12 w-full bg-transparent pl-11 pr-4 text-base outline-none placeholder:text-gray-500 sm:text-sm"
                                placeholder="Search..."
                                onChange={(event) => setQuery(event.target.value)}
                                onBlur={() => setQuery('')}
                              />
                              <MagnifyingGlassIcon
                                className="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-500"
                                aria-hidden="true"
                              />
                            </div>
                
                            {(query === '' || filteredProjects.length > 0) && (
                              <ComboboxOptions
                                static
                                as="ul"
                                className="max-h-80 scroll-py-2 divide-y divide-gray-500/20 overflow-y-auto"
                              >
                                <li className="p-2">
                                  {query === '' && (
                                    <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">Company Website</h2>
                                  )}
                                  <ul className="text-sm text-gray-400">
                                    {(query === '' ? recent : filteredProjects).map((project) => (
                                      <ComboboxOption
                                        key={project.id}
                                        value={project}
                                        className="group flex cursor-default select-none items-center rounded-md px-3 py-2 data-[focus]:bg-green-600 data-[focus]:text-white data-[focus]:outline-none"
                                      >
                                        <WindowIcon
                                          className="size-6 flex-none text-gray-500 group-data-[focus]:text-white forced-colors:group-data-[focus]:text-[Highlight]"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-3 flex-auto truncate">{project.name}</span>
                                        <span className="ml-3 hidden flex-none text-gray-400 group-data-[focus]:inline">
                                          Jump to...
                                        </span>
                                      </ComboboxOption>
                                    ))}
                                  </ul>
                                </li>
                                {query === '' && (
                                  <li className="p-2">
                                    <h2 className="sr-only">Quick actions</h2>
                                    <ul className="text-sm text-gray-400">
                                      {quickActions.map((action) => (
                                        <ComboboxOption
                                          key={action.shortcut}
                                          value={action}
                                          className="group flex cursor-default select-none items-center rounded-md px-3 py-2 data-[focus]:bg-green-600 data-[focus]:text-white data-[focus]:outline-none"
                                        >
                                          <action.icon
                                            className="size-6 flex-none text-gray-500 group-data-[focus]:text-white forced-colors:group-data-[focus]:text-[Highlight]"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-3 flex-auto truncate">{action.name}</span>
                                          <span className="ml-3 flex-none text-xs font-semibold text-gray-400">
                                            <kbd className="font-sans"></kbd>
                                            <kbd className="font-sans">{action.shortcut}</kbd>
                                          </span>
                                        </ComboboxOption>
                                      ))}
                                    </ul>
                                  </li>
                                )}
                              </ComboboxOptions>
                            )}
                
                            {query !== '' && filteredProjects.length === 0 && (
                              <div className="px-6 py-14 text-center sm:px-14">
                                <FolderIcon className="mx-auto size-6 text-gray-500" aria-hidden="true" />
                                <p className="mt-4 text-sm text-gray-200">
                                  We couldn't find any links with that term. Please try again.
                                </p>
                              </div>
                            )}
                          </Combobox>
                        </DialogPanel>
                      </div>
                    </Dialog>

                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{time}</div>

                </div>

                {/*Profile*/}
                <div className="w-1/3 flex flex-row align-middle items-center">


                    <div className={`ml-auto mr-2.5 drop-shadow ${darkMode ? '' : 'border-black border-1'} hover:scale-105`}>

                        {/* Display profile image if available, otherwise show placeholder */}
                        {profileImage ? (
                            <div className={`rounded-full bg-bgColor hover:animate-rubberBand justify-center items-center text-center w-10 h-10`}>
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            </div>
                        ) : (
                            <div className={`rounded-full flex ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} hover:animate-rubberBand justify-center items-center text-center w-10 h-10`}>
                                <img
                                    src={logo}
                                    alt="Profile"
                                    className="w-6 object-cover"
                                />
                                {/*<UserCircleIcon className="stroke-1"/>*/}
                            </div>
                        )}
                    
                    </div>
                    
                    
                    <div className={`text-xs mr-6 ${darkMode ? 'text-white' : 'text-black'}`}>Welcome {fname}!</div>
                    
                    <Button
                        className="flex text-center justify-center items-center mr-6 text-sm hover:text-opacity-50 hover:scale-105 text-green-600 rounded-md"
                        onClick={toggleOpen}
                    >
                        <AdjustmentsHorizontalIcon className={`w-6`}/>
                    </Button>
                    
                    
                    <Button
                        className="flex text-center justify-center items-center mr-6 text-sm hover:text-opacity-50 hover:scale-105 text-red-500 rounded-md"
                        onClick={() => {
                            doSignOut().then(() => {
                                navigate('/Login');
                            });
                        }}
                    >
                        <ArrowRightStartOnRectangleIcon className={`w-6`}/>
                    </Button>

                </div>

            </div>
            

            <Dialog open={open} onClose={setOpen} className={`relative z-50 ${darkMode ? 'text-white' : 'text-black'}`}>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10 pb-[5.5rem]">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex flex-col h-full overflow-y-scroll ${darkMode ? 'bg-darkMainColor' : 'bg-white'} py-6 shadow-2xl mt-[4.75rem] mr-3 rounded-lg`}>
                                    <div className={`px-4 sm:px-6`}>
                                        <div className="flex items-start justify-between">
                                            <DialogTitle className="text-base font-semibold">Quick Preferences</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className="relative z-50 rounded-md text-gray-500 hover:text-opacity-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Body*/}
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6 flex-col space-y-12">
                                        
                                        <div className="h-full overflow-y-auto">
                                            <div className="space-y-6 relative">
                                                <div>
                                                    {/*<img*/}
                                                    {/*    alt="Cycling Background"*/}
                                                    {/*    src={currentImage}*/}
                                                    {/*    onClick={handleImageClick}*/}
                                                    {/*    className="block aspect-[10/7] w-full rounded-lg object-cover cursor-pointer"*/}
                                                    {/*/>*/}
                                                    
                                                    <div className="relative flex items-center justify-center"
                                                        onClick={handleContentClick}
                                                    >
                                                        {/* Render current content (image or component) */}
                                                        {typeof currentContent === "string" ? (
                                                            <img src={currentContent} alt={currentKey} className="block aspect-[10/7] w-full rounded-lg object-cover"/>
                                                        ) : (
                                                            <div className={`block relative aspect-[10/7] w-full rounded-lg border border-gray-500 overflow-hidden`}>
                                                                <div className={`absolute inset-0`}>
                                                                    {currentContent}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mt-4 flex items-start justify-between">
                                                    <div>
                                                            <h2 className="text-base font-semibold">
                                                                <span className="sr-only">Background Image</span>Background Image: [{currentKey}]
                                                            </h2>
                                                            <p className="text-sm font-medium text-gray-500">Click on the image to view choices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {/*ToggleSwitch*/}
                                                <div className="w-fit flex items-center mr-10">
                                                    
                                                    <div className={`mr-10 font-medium`}>DarkMode:</div>
                                                    
                                                    <input
                                                        type="checkbox"
                                                        id="spray"
                                                        name="spray"
                                                        checked={darkMode}
                                                        onChange={toggleDarkMode} // Call the toggle function from context
                                                        className="sr-only w-fit"
                                                    />
                                                    <label htmlFor="spray" className="cursor-pointer">
                                                        <div
                                                            className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                                            <div className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-darkMainColor translate-x-full animate-rollOut' : 'bg-white animate-rollIn'}`}>
                                                                {darkMode ? (
                                                                    <MoonIcon
                                                                        className="w-4 h-full flex-none text-gray-300 mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : (
                                                                    <SunIcon
                                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                
                                                {/*ToggleSwitch*/}
                                                <div className="w-fit flex items-center mr-10">
                                                    
                                                    <div className={`mr-10 font-medium`}>Snowing:</div>
                                                    
                                                    <input
                                                        type="checkbox"
                                                        id="snow"
                                                        name="snow"
                                                        checked={snowing}
                                                        onChange={toggleSnow} // Call the toggle function from context
                                                        className="sr-only w-fit"
                                                    />
                                                    <label htmlFor="snow" className="cursor-pointer">
                                                        <div
                                                            className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${!snowing ? 'bg-blue-400' : 'bg-blue-200 drop-shadow shadow-gray-900'}`}>
                                                            <div className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${!snowing ? 'bg-white translate-x-full animate-rollOut' : 'bg-white animate-rollIn'}`}>
                                                                {snowing ? (
                                                                    <XMarkIcon
                                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : (
                                                                    <CheckIcon
                                                                        className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                
                                                <div>
                                                    <h3 className="font-medium">Software Info</h3>
                                                    <dl className="mt-2 divide-y divide-neutral-700 border-b border-t border-neutral-700">
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="">Version:</dt>
                                                            <dd className="text-gray-500">PFC.1.23.2025.1A</dd>
                                                        </div>
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="">Created By:</dt>
                                                            <dd className="text-gray-500">Plant Food Company Inc.</dd>
                                                        </div>
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="">Last Modified:</dt>
                                                            <dd className="text-gray-500">1/23/25, 3:45 PM</dd>
                                                        </div>
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="">Launched:</dt>
                                                            <dd className="text-gray-500">1/23/25</dd>
                                                        </div>
                                                        {/*<div className="flex justify-between py-3 text-sm font-medium">*/}
                                                        {/*    <dt className="">Resolution</dt>*/}
                                                        {/*    <dd className="text-gray-500">72 x 72</dd>*/}
                                                        {/*</div>*/}
                                                    </dl>
                                                </div>
                                                
                                                <div className="flex">
                                                    <Button
                                                        type="button"
                                                        className="flex-1 rounded-md z-50 bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        onClick={handleSaveContent}
                                                    >
                                                        Save Preferences
                                                    </Button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                
                                
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
            
            
        </div>
    );
};

export default Header;
