import {
    Button, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Field,
    Input,
    Label,
    Select,
    Switch
} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {
    doc,
    setDoc,
    deleteDoc,
    getDoc,
    updateDoc, arrayUnion,
    collection,
    getCountFromServer,
    getDocs, addDoc,
} from "firebase/firestore";
import {ChevronUpDownIcon, XMarkIcon} from "@heroicons/react/16/solid";
import {
    ArrowPathIcon, ArrowRightIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon,
    ArrowTopRightOnSquareIcon, ArrowUpTrayIcon,
    CheckCircleIcon, CheckIcon,
    ClipboardDocumentListIcon,
    DocumentArrowUpIcon, DocumentCurrencyDollarIcon, EyeSlashIcon, LinkIcon,
    MagnifyingGlassIcon,
    PlusIcon, SquaresPlusIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../../firebase/firebase";
import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";

const CustomerCard = ({ customer }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const { currentUser } = useAuth();
    
    // MAIN DATA POINTS
    const [CustName, setCustName] = useState(customer.name || "");
    const [managementName, setManagementName] = useState(customer.managementName || "");
    const [address1, setAddress1] = useState(customer.address1 || "");
    const [address2, setAddress2] = useState(customer.address2 || "");
    const [city, setCity] = useState(customer.city || "");
    const [selectedState, setSelectedState] = useState(customer.state || "");
    const [zip, setZip] = useState(customer.zip || "");
    const [selectedCounty, setSelectedCounty] = useState(customer.county || "");
    const [fips] = useState(customer.fips || "");
    const [country, setCountry] = useState(customer.country || "");
    const [industry, setIndustry] = useState(customer.industry || "");

    // CONTACTS DATA POINTS
    const [contact, setContact] = useState(customer.contact || "");
    const [contactPosition, setContactPosition] = useState(customer.contactPosition || "");
    const [contactPhone, setContactPhone] = useState(customer.contactPhone || "");
    const [contactEmail, setContactEmail] = useState(customer.contactEmail || "");
    const [billingContact, setBillingContact] = useState(customer.billingContact || "");
    const [billingPhone, setBillingPhone] = useState(customer.billingPhone || "");
    const [invoiceEmails, setInvoiceEmails] = useState(customer.invoiceEmails || "");
    const [statementEmails, setStatementEmails] = useState(customer.statementEmails || "");
    const [website, setWebsite] = useState(customer.website || "");
    
    // FINANCES DATA POINTS
    const [taxID, setTaxID] = useState(customer.taxID || "");
    const [salesTaxStatus, setSalesTaxStatus] = useState(customer.salesTaxStatus || "");
    const [taxExempCode, setTaxExempCode] = useState(customer.taxExempCode || "");
    const [paymentType, setPaymentType] = useState(customer.paymentType || "");
    
    // DELIVERY DATA POINTS
    const [TTA, setTTA] = useState(customer.TTA || false);
    const [LAS, setLAS] = useState(customer.LAS || false);
    const [LDA, setLDA] = useState(customer.LDA || false);
    const [PJR, setPJR] = useState(customer.PJR || false);
    const [DAR, setDAR] = useState(customer.DAR || false);
    const [HSA, setHSA] = useState(customer.HSA || false);
    const [FLC, setFLC] = useState(customer.FLC || false);
    
    
    
    const [customerCode, setCustomerCode] = useState("");
    const [type] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");

    const [orderLength, setOrderLength] = useState(0);
    const [pendingLength, setPendingLength] = useState(0);
    const [plannedLength, setPlannedLength] = useState(0);

    const [salesID, setSalesID] = useState("");


    const { darkMode } = useDarkMode();

    const [isAdding, setIsAdding] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);


    // eslint-disable-next-line
    const [Addresses, setAddresses] = useState([customer.ShippingAddresses]);


    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }
    
    const toggleMoving = () => {
        setIsMoving(!isMoving);
    }

    useEffect(() => {
        setIsLoaded(true);
        setSalesID(customer.userId);
        // eslint-disable-next-line
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    // Function to move document from "pending" to "active" collection and update status
    const moveToActive = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${salesID}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = { ...customerData, status: "active" };

            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${salesID}/clients`, customerId);

            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);

            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);

            console.log("Customer moved to clients collection and status updated to active.");
            alert("Customer moved to clients collection and status updated to active.");

            // Generate formatted date and time
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            };
            const formattedDateTime = now.toLocaleString('en-US', options);

            // Add a notification document to the Notifications collection
            const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            const notificationData = {
                type: "C", // Notification type
                identifier: customer.customerUID,
                createdAt: formattedDateTime, // Formatted date and time
            };

            await addDoc(notificationsCollectionRef, notificationData);
            console.log("Notification document added successfully.");
        } catch (error) {
            console.error("Error moving customer to clients collection:", error);
            alert("Error moving customer to clients collection.");
        }
    };

    const submitUpdate = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
            };

            // Update the document directly with customerDocRef
            await updateDoc(customerDocRef, updatedCustomerData);

            await moveToActive(customer.id)
            console.log("Customer successfully updated with internal setup.");
            alert("Customer successfully updated with internal setup and moved to Active Status.");
        } catch (error) {
            console.error("Customer update Failed.", error);
            alert("Customer update Failed.");
        }
    };

    const getSubcollectionCount = async (clientId) => {
        try {
            // Reference to the subcollection (e.g., 'planned')
            const pendingCollectionRed = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`);

            // Get the count from the server without fetching documents
            const pendingCountSnapshot = await getCountFromServer(pendingCollectionRed);

            // Get the count from the aggregate query result
            const pendingCount = pendingCountSnapshot.data().count;

            setPendingLength(pendingCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        try {
            // Reference to the subcollection (e.g., 'pending')
            const activeCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/active`);

            // Get the count from the server without fetching documents
            const activeCountSnapshot = await getCountFromServer(activeCollectionRef);

            // Get the count from the aggregate query result
            const count = activeCountSnapshot.data().count;

            setOrderLength(count);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        try {
            // Reference to the subcollection (e.g., 'planned')
            const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`);

            // Get the count from the server without fetching documents
            const plannedCountSnapshot = await getCountFromServer(plannedCollectionRef);

            // Get the count from the aggregate query result
            const plannedCount = plannedCountSnapshot.data().count;

            setPlannedLength(plannedCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }
    };

    useEffect(() => {
        if (currentUser) {
            // Call the function to get the document count
            getSubcollectionCount(customer.id).then(count => {
                console.log(`Fetched count: ${count}`);
            });
        }
        // eslint-disable-next-line
    }, [currentUser]);

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST" || accountLevel === "A");
    }, [accountLevel]);


    const [mainExpanded, setMainExpanded] = useState(false);
    const [contactExpanded, setContactExpanded] = useState(false);
    const [financesExpanded, setFinancesExpanded] = useState(false);
    const [shippingExpanded, setShippingExpanded] = useState(false);
    const [deliveryExpanded, setDeliveryExpanded] = useState(false);

    const toggleMain = () => {
        setMainExpanded(!mainExpanded);
    }
    const toggleContacts = () => {
        setContactExpanded(!contactExpanded);
    }
    const toggleFinances = () => {
        setFinancesExpanded(!financesExpanded);
    }
    const toggleShipping = () => {
        setShippingExpanded(!shippingExpanded);
    }
    const toggleDelivery = () => {
        setDeliveryExpanded(!deliveryExpanded);
    }

    const collapseAll = () => {
        setMainExpanded(false);
        setContactExpanded(false);
        setFinancesExpanded(false);
        setShippingExpanded(false);
        setDeliveryExpanded(false);
    }
    const expandAll = () => {
        setMainExpanded(true);
        setContactExpanded(true);
        setFinancesExpanded(true);
        setShippingExpanded(true);
        setDeliveryExpanded(true);
    }



    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    // eslint-disable-next-line
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility
    
    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor', shortened: 'BS' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor', shortened: 'LF' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700', shortened: 'DF' },
        { category: 'Secondary Nutrients', color: 'bg-snColor', shortened: 'SN' },
        { category: 'Micronutrients', color: 'bg-mnColor', shortened: 'MN' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor', shortened: 'PHA' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor', shortened: 'AP' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor', shortened: 'TP' },
        { category: 'Water Treatments', color: 'bg-wtColor', shortened: 'WT' },
        { category: 'Soil Amendments', color: 'bg-saColor', shortened: 'SA' },
        { category: 'Fungicides', color: 'bg-fgColor', shortened: 'FG' },
        { category: 'Equipment', color: 'bg-gray-600', shortened: 'EQ' },
    ];

// Example usage
    // eslint-disable-next-line
    const getCategoryInfo = (category) => {
        const categoryInfo = categoryColors.find(c => c.category === category);
        return categoryInfo ? { color: categoryInfo.color, shortened: categoryInfo.shortened } : { color: '', shortened: '' };
    };

    const [activeOrdersTotal, setActiveOrdersTotal] = useState(0);

    useEffect(() => {
        const fetchActiveOrdersTotal = async () => {
            try {
                const activeCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${customer.id}/active`);
                const querySnapshot = await getDocs(activeCollectionRef);

                let total = 0;
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.grandTotal) {
                        total += data.grandTotal;
                    }
                });

                setActiveOrdersTotal(total);
            } catch (error) {
                console.error("Error fetching active orders total:", error);
            }
        };

        fetchActiveOrdersTotal();
        
        // eslint-disable-next-line
    }, []);
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    const [contracts] = useState(customer.contractPrices || []);
    
    // Filter products by search term
    // eslint-disable-next-line
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products by search term
        if (value) {
            setFilteredProducts(
                products.filter((product) =>
                    product.name.toLowerCase().includes(value.toLowerCase())
                )
            );
            setShowProducts(true); // Show the product list while typing
        } else {
            setShowProducts(false); // Hide the product list if the search bar is empty
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);
    
    const [contract, setContract] = useState(false);
    
    const toggleContract = () => {
        setContract(!contract);
    }
    
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [name, setName] = useState("");
    const [prices, setPrices] = useState([]);
    const [selectedVolume, setSelectedVolume] = useState(""); // Set to the first `um` in prices or an empty string if prices is empty
    const [price, setPrice] = useState(0);
    const [newValue, setNewValue] = useState("");
    const [productCode, setProductCode] = useState("");
    const [unitLabel, setUnitLabel] = useState("Custom Price");
    const [percentLabel, setPercentLabel] = useState("$");

    const [percent, setPercent] = useState(false);
    
    const handleSelectChange = (product) => {
        if (product) {
            // Set selected customer and related fields
            setSelectedProduct(product);
            setName(product.name);
            setPrices(product.prices);
        } else {
            // Reset fields if no customer is selected
            setSelectedProduct(null);
            setName("");
            setPrices([]);
        }
        setSearchTerm('');
    };
    
    const clear = async () => {
        setSelectedProduct(null);
        setName("");
        setPrices([]);
        setSelectedVolume("");
        setNewValue(0);
        setProductCode("");
        setUnitLabel("Custom Price");
        setPercentLabel("$");
        setPercent(false);
        setPrice(0);
    }
    
    const getPriceBasedOnSelection = (prices, priceType, um) => {
        // Ensure prices is an array
        if (!Array.isArray(prices)) {
            console.error("Prices must be an array");
            return "Invalid data";
        }

        // Convert priceType to lowercase to handle case insensitivity
        const normalizedPriceType = priceType.toLowerCase();

        // Find the price object where 'um' matches the specified um
        const priceObject = prices.find((price) => price.um === um);

        if (priceObject && priceObject[normalizedPriceType]) {
            return priceObject[normalizedPriceType];
        }

        return "Price not available";
    };
    
    useEffect(() => {
        // setSelectedVolume(prices.length > 0 ? prices[0].um : "");

        if (selectedProduct && selectedVolume) {
            const newPrice = getPriceBasedOnSelection(prices, customer.priceType, selectedVolume);

            // Find the price object that matches the selected volume and priceType
            const priceObject = prices.find((price) => price.um === selectedVolume);

            if (priceObject) {
                setProductCode(priceObject.code);
                console.log("Selected Price Code:", priceObject.code); // Log the code of the selected price object
            }

            console.log("New Price:", newPrice); // Log the selected price

            setPrice(newPrice);

            // eslint-disable-next-line default-case
            switch (percent){
                case true:
                    setPercentLabel("%");
                    setUnitLabel("Percent Off");
                    break;
                case false:
                    setPercentLabel("$");
                    setUnitLabel("Custom Price");
                    break;
            }
            
        }
        // eslint-disable-next-line
    }, [selectedProduct, selectedVolume, percent]);

    const umMapping = {
        ACR: "Acre",
        B12: "BAG 12#",
        B25: "BAG 25#",
        B30: "BAG 30#",
        B40: "BAG 40#",
        B5: "BAG 5#",
        B50: "BAG 50#",
        B51: "BAG 51#",
        BAG: "BAG (Unspecified Weight)",
        "2.5": "CASE (2x2.5)",
        CS4: "CASE (4X1)",
        "30": "DRUM 30-GAL",
        "55": "DRUM 55-GAL",
        DRU: "DRUM (Unspecified Volume)",
        EAC: "EACH",
        GAL: "GALLON",
        TR: "TOTE REFILL",
        LB: "POUND",
        OZ: "Ounce - Av",
        PAI: "PAIL 5-GAL",
        SAC: "Super Sack (B2000/B1000)",
        TON: "TON (2,000#)",
        "275": "TOTE 275-GAL",
    };
    
    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };
    
    const addContractPrice = async () => {
        try {
            // Reference to the customer document
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customer.id);
    
            // Data to be added to the array
            const contractPriceEntry = {
                price: price,
                name: name,
                productCode: productCode,
                selectedVolume: selectedVolume,
                percent: percent,
                newValue: newValue,
            };
    
            // Update the document to add the new entry to the contractPrices array
            await updateDoc(customerDocRef, {
                "contractPrices": arrayUnion(contractPriceEntry),
            });
    
            console.log("New contract price added successfully");
            alert("Contract Price added successfully!");
        } catch (error) {
            console.error("Error adding contract price: ", error);
        }
    };
    
    const removeContractPrice = async (productCodeToRemove) => {
        try {
            // Reference to the customer document
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customer.id);
    
            // Fetch the current document to get the existing array
            const customerDocSnap = await getDoc(customerDocRef);
    
            if (customerDocSnap.exists()) {
                const customerData = customerDocSnap.data();
                const updatedContractPrices = customerData.contractPrices.filter(
                    (item) => item.productCode !== productCodeToRemove
                );
    
                // Update Firestore with the filtered array
                await updateDoc(customerDocRef, {
                    contractPrices: updatedContractPrices
                });
    
                console.log(`Contract price with product code ${productCodeToRemove} removed successfully`);
                alert("Contract Price Removed Successfully!");
            } else {
                console.log("Customer document does not exist");
            }
        } catch (error) {
            console.error("Error removing contract price: ", error);
        }
    };
    
    
    
    
    
    
    
    
    
    
    
    
    const tabs = [
        { name: 'Account Info', href: '#', current: true },
        { name: 'Contract Pricing', href: '#', count: `${contracts.length}`, current: false },
        { name: 'Orders', href: '#', count: `${orderLength}`, current: false },
    ];
    
    const [activeTab, setActiveTab] = useState('Account Info');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Account Info':
                return (
                    <div className={`flex flex-col space-y-12`}>
                        {/*Main Done*/}
                        <div className="w-full h-fit">
                            
                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                    <div
                                        className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`}/>
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Main</span>
                                <Button
                                  type="button"
                                  onClick={toggleMain}
                                  className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                >
                                    {mainExpanded ? (
                                        <>
                                            <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Collapse</span>
                                        </>
                                    ) : (
                                        <>
                                            <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Expand</span>
                                        </>
                                    )}
                                </Button>
                              </div>
                            </div>

                            {/*Content*/}
                            {mainExpanded && (
                                <div className={`w-full animate-slideInBottom flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    {/*Name Row*/}
                                    <div className={`w-full flex flex-row`}>

                                        {/*name*/}
                                        <div className="flex flex-col w-full mr-3">
                                            <div className="text-xs mb-0.5 font-semibold">Customer Name
                                            </div>
                                            <div className="mt-2">
                                                <Input
                                                    value={CustName}
                                                    placeholder={"Customer Name"}
                                                    onChange={(e) => setCustName(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*managment name*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Management Name
                                            </div>
                                            <div className="mt-2">
                                                <Input
                                                    value={managementName}
                                                    placeholder={"Management Company Name"}
                                                    onChange={(e) => setManagementName(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {/*Address 1*/}
                                    <div className="flex flex-col w-full">
                                        <div className="text-xs mb-0.5 font-semibold">Street Address [1]</div>
                                        <div className="mt-2">
                                            <Input
                                                value={address1}
                                                placeholder={"Street Address 1"}
                                                onChange={(e) => setAddress1(e.target.value)}
                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>

                                    {/*Address 2*/}
                                    <div className="flex flex-col w-full">
                                        <div className="text-xs mb-0.5 font-semibold">Street Address [2]</div>
                                        <div className="mt-2">
                                            <Input
                                                value={address2}
                                                placeholder={"Street Address [2]"}
                                                onChange={(e) => setAddress2(e.target.value)}
                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>

                                    {/*City Row*/}
                                    <div className={`flex flex-row w-full space-x-3`}>
                                        {/*City*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">City</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={city}
                                                    placeholder={"City"}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*State*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">State</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={selectedState}
                                                    placeholder={"State"}
                                                    onChange={(e) => setSelectedState(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Zip*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Zip</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={zip}
                                                    placeholder={"Zip Code"}
                                                    onChange={(e) => setZip(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*County Row*/}
                                    <div className={`flex flex-row w-full space-x-3`}>
                                        
                                        {/*County*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">County</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={selectedCounty}
                                                    placeholder={"County"}
                                                    onChange={(e) => setSelectedCounty(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Fips*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Fips Code</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={fips ? formatFipsCode(fips) : ""}
                                                    placeholder={fips ? "" : "No Fips Code"} // Display placeholder if no fips
                                                    disabled={true}
                                                    className={`rounded-lg block w-full p-2 ${
                                                        darkMode
                                                            ? "bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                                            : "bg-gray-50 border border-gray-300 text-gray-900 text-sm"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>

                                    {/*Country*/}
                                    <div className="flex flex-col w-full">
                                        <div className="text-xs mb-0.5 font-semibold">Country</div>
                                        <div className="mt-2">
                                            <Input
                                                value={country}
                                                placeholder={"Country"}
                                                onChange={(e) => setCountry(e.target.value)}
                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>

                        {/*Contacts Done*/}
                        <div className="w-full h-fit">

                            {/*Header*/}
                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Contacts</span>
                                    <Button
                                        type="button"
                                        onClick={toggleContacts}
                                        className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                    >
                                        {contactExpanded ? (
                                            <>
                                            <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Collapse</span>
                                        </>
                                    ) : (
                                        <>
                                            <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Expand</span>
                                        </>
                                    )}
                                </Button>
                              </div>
                            </div>


                            {contactExpanded && (
                                <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    <div className={`flex flex-row space-x-3`}>
                                        {/*Contact*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={contact}
                                                    placeholder={"Contact Name"}
                                                    onChange={(e) => setContact(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Position*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Contact Position</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={contactPosition}
                                                    placeholder={"Contact Position"}
                                                    onChange={(e) => setContactPosition(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`flex flex-row space-x-3`}>
                                        {/*Phone*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Contact Phone</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={formatPhoneNumber(contactPhone)}
                                                    placeholder={"Contact Phone"}
                                                    onChange={(e) => setContactPhone(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Email*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Contact Email</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={contactEmail}
                                                    placeholder={"Contact Email"}
                                                    onChange={(e) => setContactEmail(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>                                                </div>
                                    </div>

                                    {/*Website*/}
                                    <div className="flex flex-col w-full">
                                        <div className="text-xs mb-0.5 font-semibold">Website</div>
                                        <div className="mt-2">
                                            <Input
                                                value={website}
                                                placeholder={"Website"}
                                                onChange={(e) => setWebsite(e.target.value)}
                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>

                                    <div className={`flex flex-row space-x-3`}>
                                        {/*Billing Contact*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Billing Contact</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={billingContact}
                                                    placeholder={"Billing Contact"}
                                                    onChange={(e) => setBillingContact(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Billing Phone*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Billing Phone</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={formatPhoneNumber(billingPhone)}
                                                    placeholder={"Billing Phone"}
                                                    onChange={(e) => setBillingPhone(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`flex flex-row space-x-3`}>
                                        {/*Invoice Emails*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Invoice Email</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={invoiceEmails}
                                                    placeholder={"Invoice Email"}
                                                    onChange={(e) => setInvoiceEmails(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        {/*Statement Email*/}
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Statement Email</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={statementEmails}
                                                    placeholder={"Statement Email"}
                                                    onChange={(e) => setStatementEmails(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>

                        {/*Shipping*/}
                        <div className="w-full h-fit">

                            <div className="relative mb-3">
                              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                  <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`}/>
                              </div>
                                <div className="relative flex items-center justify-between">
                                <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Shipping</span>
                                <Button
                                  type="button"
                                  onClick={toggleShipping}
                                  className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                >
                                    {shippingExpanded ? (
                                        <>
                                            <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Collapse</span>
                                        </>
                                    ) : (
                                        <>
                                            <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                            <span>Expand</span>
                                        </>
                                    )}
                                </Button>
                              </div>
                            </div>


                            {shippingExpanded && (
                                <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                <div className="text-xs mb-0.5 font-semibold">Shipping Locations [{Addresses.length}]</div>

                                    {Addresses.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div
                                                className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div
                                                    className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                </div>
                                            </div>

                                        </div>

                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        customer.ShippingAddresses.map((address, index) => {
                                            return (
                                                <div key={index} className={`mb-1 w-full rounded-lg animate-zoomIn`}>
                                                    <div
                                                        className={`flex flex-col w-full items-center p-3 border-2 rounded-lg space-y-2 ${darkMode ? 'border-neutral-700' : ''}`}>


                                                        {/*Main*/}
                                                        <div className={`flex flex-row w-full space-x-3`}>
                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">Shipping
                                                                    Location Nickname
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.nickName}
                                                                        placeholder={"Nickname"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">Contact
                                                                    Name
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.contactName}
                                                                        placeholder={"Name"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">Contact
                                                                    Phone
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.contactPhone}
                                                                        placeholder={"Phone Number"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Shipping Info*/}
                                                        <div className={`flex flex-row w-full space-x-3`}>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Ship To Email
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.shipEmail}
                                                                        placeholder={"Optional"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Ship to Notes
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.shipNotes}
                                                                        placeholder={"Optional"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Country
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.contactPhone}
                                                                        placeholder={"Country"}
                                                                        disabled={true}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Address*/}
                                                        <div className={`flex flex-row w-full space-x-3`}>
                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Street Address [1]
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.address1}
                                                                        placeholder={"123 Main Street"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Street Address [2]
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.address2}
                                                                        placeholder={"Optional"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {/*City Row*/}
                                                        <div className={`flex flex-row w-full space-x-3`}>
                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    City
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.city}
                                                                        placeholder={"City"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    State
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.selectedState}
                                                                        placeholder={"State"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div
                                                                    className="text-xs mb-0.5 font-semibold">
                                                                    Zip Code
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.zip}
                                                                        placeholder={"00000"}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*County*/}
                                                        <div className={`flex flex-row w-full space-x-3`}>
                                                            <div className="flex flex-col w-full">
                                                                <div className="text-xs mb-0.5 font-semibold">
                                                                    County
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.selectedCounty}
                                                                        placeholder={"County"}
                                                                        disabled={true}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col w-full">
                                                                <div className="text-xs mb-0.5 font-semibold">
                                                                    Fips Code
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input
                                                                        value={address.fips}
                                                                        placeholder={"00-000"}
                                                                        disabled={true}
                                                                        // onChange={(e) => setFname(e.target.value)}
                                                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </div>


                                                        {/*<Button*/}
                                                        {/*    onClick={() => handleRemovePrice(index)}*/}
                                                        {/*    className="ml-2 stroke-2 text-red-500 hover:text-red-700 hover:animate-pulse"*/}
                                                        {/*>*/}
                                                        {/*    <XCircleIcon className="w-6 h-6"/>*/}
                                                        {/*</Button>*/}
                                                    </div>
                                                </div>
                                            )
                                        })

                                    )}


                                </div>
                            )}


                        </div>

                        {/*Finances Done*/}
                        <div className="w-full h-fit">


                            {/*Header*/}
                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Finances</span>
                                    <Button
                                        type="button"
                                        onClick={toggleFinances}
                                        className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                    >
                                        {financesExpanded ? (
                                            <>
                                                <XMarkIcon aria-hidden="true"
                                                           className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Collapse</span>
                                            </>
                                        ) : (
                                            <>
                                                <PlusIcon aria-hidden="true"
                                                          className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Expand</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>


                            {financesExpanded && (
                                <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    <div className={`flex flex-row space-x-3`}>
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Tax ID</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={taxID}
                                                    placeholder={"TaxID"}
                                                    onChange={(e) => setTaxID(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Sales Tax Status</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={salesTaxStatus}
                                                    placeholder={"Sales Tax Status"}
                                                    onChange={(e) => setSalesTaxStatus(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`flex flex-row space-x-3`}>
                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Tax Exempt Code
                                            </div>
                                            <div className="mt-2">
                                                <Input
                                                    value={taxExempCode}
                                                    placeholder={"Tax Exempt Code"}
                                                    onChange={(e) => setTaxExempCode(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full">
                                            <div className="text-xs mb-0.5 font-semibold">Payment Type</div>
                                            <div className="mt-2">
                                                <Input
                                                    value={paymentType}
                                                    placeholder={"Payment Type"}
                                                    onChange={(e) => setPaymentType(e.target.value)}
                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>

                        {/*Delivery Done*/}
                        <div className="w-full h-fit">


                            <div className="relative mb-3">
                                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                </div>
                                <div className="relative flex items-center justify-between">
                                    <span
                                        className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Delivery</span>
                                    <Button
                                        type="button"
                                        onClick={toggleDelivery}
                                        className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                    >
                                        {deliveryExpanded ? (
                                            <>
                                                <XMarkIcon aria-hidden="true"
                                                           className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Collapse</span>
                                            </>
                                        ) : (
                                            <>
                                                <PlusIcon aria-hidden="true"
                                                          className="-ml-1 -mr-0.5 size-5"/>
                                                <span>Expand</span>
                                            </>
                                        )}
                                </Button>
                              </div>
                            </div>


                            {deliveryExpanded && (
                                <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                    <div className={`flex flex-col w-full rounded-lg`}>

                                        <div className={`flex flex-row w-full ${darkMode ? 'border-neutral-700' : ''} border-b-2`}>
                                            <div className="w-3/4 p-3 ">Delivery Questions?</div>
                                            <div className="w-1/4 text-center p-3">[Yes] [No]</div>
                                        </div>

                                        <div className="flex w-full flex-row">
                                            <div className="space-y-7 w-3/4 p-3">
                                                <div className="p-1 border-b">Tractor Trailer Accessible?</div>
                                                <div className="p-1 border-b">Limited Access Site?</div>
                                                <div className="p-1 border-b">Loading Dock Available?</div>
                                                <div className="p-1 border-b">Lift Gate & Pallet Jack Required?</div>
                                                <div className="p-1 border-b">Delivery Appointment Required?</div>
                                                <div className="p-1 border-b">Heated Storage Available?</div>
                                                <div className="p-1 border-b">Forklift Available w/ 3,000 lbs Capacity?</div>
                                            </div>

                                            <div className={`ml-5 w-1/4 space-y-7 drop-shadow p-3 rounded-lg`}>
                                                
                                                {/*TTA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={TTA}
                                                      onChange={setTTA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*LAS*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={LAS}
                                                      onChange={setLAS}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*LDA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={LDA}
                                                      onChange={setLDA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*PJR*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={PJR}
                                                      onChange={setPJR}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*DAR*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={DAR}
                                                      onChange={setDAR}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*HSA*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={HSA}
                                                      onChange={setHSA}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                                
                                                {/*FLC*/}
                                                <div className="p-1 flex justify-center items-center">
                                                    <Switch
                                                      checked={FLC}
                                                      onChange={setFLC}
                                                      className={`group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent ${darkMode ? 'bg-darkBgColor' : 'bg-gray-200'} transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 data-[checked]:bg-green-600`}
                                                    >
                                                      <span className="sr-only">Use setting</span>
                                                      <span className={`pointer-events-none relative inline-block size-5 transform rounded-full ${darkMode ? 'bg-darkMainColor' : 'bg-white'} shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                                                        >
                                                          <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path
                                                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                              stroke="currentColor"
                                                              strokeWidth={2}
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </span>
                                                        <span
                                                          aria-hidden="true"
                                                          className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                                        >
                                                          <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-gray-500">
                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </Switch>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )}


                        </div>
                        
                    </div>
                );
                
            case 'Contract Pricing':
                return (
                    <div className={`flex h-full flex-col space-y-12`}>
                        {/*Contract Pricing*/}
                        <div className={`w-full h-full animate-slideInLeft space-y-2`}>
                            
                            <div className={`w-full h-full flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>
                                
                                {/*PRODUCT SEARCH BAR*/}
                                <div className="w-full flex flex-row relative">
                                    
                                    {/*Search Bar*/}
                                    <div className={`${darkMode ? 'bg-darkMainColor border-2 border-neutral-700' : 'bg-white border-2'} w-full rounded-md flex items-center justify-center hover:scale-y-110 transition-all duration-500 ease-in-out`}>
                                        <MagnifyingGlassIcon className="text-gray-500 stroke-2 h-5 mr-6 ml-6"/>
                                        <Input
                                            className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} text-sm transition-all duration-500 ease-in-out rounded-md p-1 w-full focus:outline-none`}
                                            placeholder={`Search Contract Prices`}
                                            disabled={true}
                                            // value={searchTerm}
                                            // onChange={handleSearch}
                                            // onFocus={() => setShowProducts(true)}
                                            // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                        />
                                    </div>
                                    
                                    <Button
                                        className={`bg-green-600 hover:bg-opacity-50 text-white px-4 ml-3 rounded-lg`}
                                        onClick={toggleContract}
                                        disabled={disabled}
                                        // onClick={handleSaveAllContractPrices}
                                    >
                                        Add
                                    </Button>
                                
                                </div>
                                
                                <div className={`w-full h-full overflow-y-scroll`}>
                                    
                                    {contracts.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center">
                                            
                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                <div className="p-1 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                    <SquaresPlusIcon className={`w-8 stroke-1 mr-3`}/>
                                                    <p className="text-lg mt-2 line-clamp-1 tracking-widest flex">No Contract Pricing</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    ) : (
                                        // Display SprayCard components if sprays array is not empty
                                        contracts.map((product, index) => (
                                            <div className={`h-12 flex flex-row px-3 border-2 ${darkMode ? 'border-neutral-700' : ''} mt-2 rounded-lg`}>
                                            
                                                {/*PRODCUT NAME*/}
                                                <div className="flex flex-row items-center w-1/3 mr-3">
                                                    {/*Name and Product Code*/}
                                                    <div className="flex flex-col mt-auto mb-auto w-2/3">
                                                        {/*Scrollable Name by Hover*/}
                                                        <div ref={(container) => {
                                                            if (container) {
                                                                const textElement = container.querySelector('.scrollable-text');
                                                                if (textElement) {
                                                                    container.classList.toggle(
                                                                        'hover-enabled',
                                                                        textElement.scrollWidth > container.offsetWidth
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                             className={`relative overflow-hidden ${
                                                                 darkMode ? 'text-white' : 'text-black'
                                                             } text-sm font-medium w-full`}
                                                        >
                                                            <div className="scrollable-text inline-block whitespace-nowrap transition-transform duration-1000 ease-linear"
                                                                 style={{
                                                                     transform: 'translateX(0)',
                                                                 }}
                                                                 onMouseEnter={(e) => {
                                                                     const container = e.currentTarget.parentElement;
                                                                     if (container.classList.contains('hover-enabled')) {
                                                                         e.currentTarget.style.transform = 'translateX(-50%)';
                                                                     }
                                                                 }}
                                                                 onMouseLeave={(e) => {
                                                                     e.currentTarget.style.transform = 'translateX(0)';
                                                                 }}
                                                            >
                                                                {product.name}
                                                            </div>
                                                        </div>
                                                        <div className={`ml-3 text-xxs ${darkMode ? 'text-borderColor' : 'text-borderColor'}`}>{product.productCode}</div>
                                                    </div>
                                    
                                                    {/*Percent Toggle*/}
                                                    <Field className="flex flex-col items-center w-1/3">
                                                        <Switch
                                                            checked={product.percent}
                                                            // onChange={setPercent}
                                                            className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                        >
                                                            <span className="sr-only">Use setting</span>
                                                            <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                                            <span
                                                                aria-hidden="true"
                                                                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                            />
                                                            <span
                                                                aria-hidden="true"
                                                                className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                            />
                                                        </Switch>
                                                        <Label as="span" className="mt-1 text-xxs">
                                    
                                                            {product.percent ? (
                                                                <span className="font-medium line-clamp-1">Percentage Off</span>
                                                            ) : (
                                                                <span className="font-medium line-clamp-1">Custom Price</span>
                                                            )}
                                    
                                                        </Label>
                                                    </Field>
                                                    
                                                </div>
                                    
                                                {/*PRODUCT PRICING/QUANT*/}
                                                <div className="flex flex-row w-2/3">
                                    
                                                    {/*PRICE*/}
                                                    <div className={`relative w-1/2 p-1.5`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} relative flex flex-row px-4 rounded-lg h-full items-center justify-center`}>
                                                            <div className="text-borderColor text-md mr-1">$</div>
                                                            <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-sm p-1.5 w-full tracking-wider line-clamp-1`}>
                                                                {parseFloat(product.price).toFixed(2)}
                                                            </div>
                                                            <div className="text-borderColor text-sm">USD</div>
                                                        </div>
                                                    </div>
                                    
                                                    <div className="flex flex-row px-1 h-full items-center justify-center">
                                                        <ArrowRightIcon className={`text-borderColor w-4 stroke-2`}/>
                                                    </div>
                                    
                                                    {/*New Price*/}
                                                    <div className={`relative w-1/2 p-1.5 mr-3`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} flex flex-row px-4 rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                                                            {/*// When newValue changes, trigger the onChange for recalculation*/}
                                                            <div className="text-borderColor text-sm">{}*</div>
                                    
                                                            <Input
                                                                className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                disabled={true}
                                                                // placeholder={unitLabel}
                                                                value={product.newValue}
                                                                // onChange={handleNewValueChange}
                                                            />
                                    
                                                            {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                                                        </div>
                                                    </div>
                                    
                                                    {/*U/M*/}
                                                    <div className={`relative w-1/4 p-1.5`}>
                                                        <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} line-clamp-1 overflow-x-hidden flex flex-row text-xs rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                                                            {umMapping[product.selectedVolume]}
                                                        </div>
                                                    </div>
                                    
                                                </div>
                                    
                                                {/*PRODUCT TOTAL*/}
                                                <div className="flex flex-row w-fit justify-end align-middle items-center">

                                                    <div className="ml-auto w-full flex flex-row justify-end">
                                    
                                                        <Button className="rounded-full ml-3 h-8 w-8 items-center justify-center align-middle"
                                                                onClick={() => removeContractPrice(product.productCode)}
                                                        >
                                                            <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:animate-pulse hover:scale-110 m-auto"/>
                                                        </Button>
                                                    </div>
                                    
                                    
                                                </div>
                                                
                                            </div>
                                        ))
                                    )}
                                
                                </div>
                            
                            </div>
                        
                        
                        </div>
                        
                        
                        
                        
                        
                        <Dialog open={contract} onClose={setContract} className="relative z-50">
                          <DialogBackdrop
                            transition
                            className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                          />
                    
                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <DialogPanel
                                transition
                                className={`relative h-96 transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                              >
                                <div className="sm:flex sm:items-start">
                                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <DocumentCurrencyDollarIcon aria-hidden="true" className="size-6 text-green-600" />
                                  </div>
                                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                      <DialogTitle as="h3" className="text-base font-semibold">
                                        Add Contract Price {name}
                                      </DialogTitle>
                                      <div className="mt-2">
                                          <p className="text-sm text-gray-500">
                                              This is a special pricing specific to that customer for a certain product only.
                                          </p>
                                      </div>
                                      
                                      {/*Body*/}
                                      <div className="mt-2 grid grid-cols-2 gap-3">
                                          {/*Product Select*/}
                                          <div className={`col-span-full z-30`}>
                                              <Combobox
                                                  as="div"
                                                  value={selectedProduct}
                                                  onChange={handleSelectChange} // Use the updated handler
                                              >
                                                  <div className="relative">
                                                      <label
                                                          htmlFor=""
                                                          className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                                      >
                                                          Product
                                                      </label>
                                                      
                                                      <ComboboxInput
                                                          className={`w-full rounded-md transition-all duration-500 ease-in-out outline ${darkMode ? 'bg-darkMainColor outline-gray-500' : 'bg-white outline-gray-300'} px-3 py-2 pl-3 pr-12 sm:text-sm/6`}
                                                          onChange={(event) => setSearchTerm(event.target.value)}
                                                          onBlur={() => setSearchTerm('')}
                                                          placeholder={`Select a product from ${filteredProducts.length} available products`}
                                                          displayValue={(customer) => customer?.name || ""}
                                                      />
                                                      <ComboboxButton
                                                          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                          <ChevronUpDownIcon className="size-5 text-gray-400"
                                                                             aria-hidden="true"/>
                                                      </ComboboxButton>
                                                      
                                                      <ComboboxOptions
                                                          className={`absolute z-10 mt-1 max-h-96 animate-fadeIn w-full overflow-auto rounded-lg ${darkMode ? 'bg-neutral-800' : 'bg-white'} py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}>
                                                          {Array.isArray(filteredProducts) && filteredProducts.length > 0 ? (
                                                              filteredProducts.map((product) => (
                                                                  <ComboboxOption
                                                                      key={product.id}
                                                                      value={product} // Pass the whole customer object as the value
                                                                      className={`group relative rounded-full cursor-default select-none py-2 pl-3 pr-9 data-[focus]:outline-none ${
                                                                          darkMode
                                                                              ? 'data-[focus]:bg-darkMainColor data-[focus]:bg-opacity-50'
                                                                              : 'data-[focus]:bg-neutral-200'
                                                                      }`}
                                                                  >
                                                                      <div className="flex items-center">
                                                                          <div
                                                                              className={`rounded-full py-0.5 px-2 ${darkMode ? 'bg-neutral-600' : 'bg-bgColor'}`}>
                                                                              xx
                                                                          </div>
                                                                          <span
                                                                              className="ml-3 truncate group-data-[selected]:font-semibold">{product.name}</span>
                                                                      </div>
                                                                      
                                                                      <span
                                                                          className="absolute inset-y-0 right-0 hidden items-center pr-4 text-green-600-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                                                        <CheckIcon className="size-5"
                                                                                   aria-hidden="true"/>
                                                                    </span>
                                                                  </ComboboxOption>
                                                              ))
                                                          ) : (
                                                              <div
                                                                  className={`flex items-center justify-center flex-col p-3`}>
                                                                  <EyeSlashIcon
                                                                      className={`w-6 stroke-2 text-gray-500`}/>
                                                                  <div className="px-4 py-2 text-sm text-gray-500">
                                                                      No customer found for <span
                                                                      className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{searchTerm}'</span>.
                                                                      Please try again.
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </ComboboxOptions>
                                                  </div>
                                              </Combobox>
                                          </div>
                                          
                                          
                                          {/*Name*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="name"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Name
                                              </label>
                                              <Input
                                                  id="name"
                                                  name="name"
                                                  type="text"
                                                  value={name}
                                                  placeholder={`Product Name`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          {/*Code*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="code"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Code
                                              </label>
                                              <Input
                                                  id="code"
                                                  name="code"
                                                  type="text"
                                                  value={productCode}
                                                  placeholder={`Product Code`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          
                                          {/*UM*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="um"
                                                  className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  U/M
                                              </label>
                                              <Select
                                                  id="um"
                                                  name="um"
                                                  value={selectedVolume}
                                                  onChange={handleVolumeChange}
                                                  placeholder={`Product Code`}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              >
                                                  {prices.map((price, index) => {
                                                      const label = umMapping[price.um]; // Get label from mapping object
                                                      return label ? (
                                                          <option key={index} value={price.um}>
                                                              {label}
                                                          </option>
                                                      ) : null; // Exclude options with no matching label
                                                  })}
                                              </Select>
                                          </div>
                                          
                                          {/*Percent*/}
                                          <div className={`flex items-centercol-span-1`}>
                                          
                                              <Field className="flex items-center">
                                                  <Switch
                                                        checked={percent}
                                                        onChange={setPercent}
                                                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                                                  >
                                                        <span className="sr-only">Use setting</span>
                                                        <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                                                        <span
                                                            aria-hidden="true"
                                                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                                                        />
                                                        <span
                                                            aria-hidden="true"
                                                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                                        />
                                                  </Switch>
                                                  <Label as="span" className="ml-3 text-sm">
                                                    <span className="font-medium">{unitLabel}</span>{' '}
                                                    <span className="text-gray-500">({percentLabel})</span>
                                                  </Label>
                                              </Field>
                                              
                                          </div>
                                          
                                          
                                          {/*Current Price*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="cp"
                                                  className={`absolute z-20 -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  Current Price
                                              </label>
                                              <Input
                                                  id="cp"
                                                  name="cp"
                                                  type="text"
                                                  value={price}
                                                  placeholder={`Current Price`}
                                                  disabled={true}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                          
                                          {/*New Price*/}
                                          <div className={`relative col-span-1`}>
                                              <label
                                                  htmlFor="np"
                                                  className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                              >
                                                  New Price
                                              </label>
                                              <Input
                                                  id="np"
                                                  name="np"
                                                  type="text"
                                                  value={newValue}
                                                  onChange={(e) => setNewValue(e.target.value)}
                                                  placeholder={unitLabel}
                                                  className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                              />
                                          </div>
                                      
                                      </div>
                                  
                                  </div>
                                </div>
                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                      <Button
                                          type="button"
                                          onClick={() => addContractPrice()}
                                          className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                      >
                                          Save
                                      </Button>
                                      <Button
                                          type="button"
                                          data-autofocus
                                          onClick={() => clear()}
                                          className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                      >
                                          Clear
                                      </Button>
                                  </div>
                              </DialogPanel>
                            </div>
                          </div>
                        </Dialog>
                    
                    
                    </div>
                );
            
            case 'Orders':
                return (
                    <div>
                        <h2>Current Orders</h2>
                        <p>Track all the orders currently in progress.</p>
                        {/* Add large content for Current Orders here */}
                    </div>
                );
            
            default:
                return null;
        }
    };
    
    
    const [users, setUsers] = useState([]);
    const [NewSalesID, setNewSalesID] = useState("");
    
    const moveToNewSalesRep = async (customerId, NewSalesID) => {
        console.log("Customer ID", customerId);
        console.log("Sales ID", salesID);
        console.log("New Sales ID", NewSalesID);
        
        
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${salesID}/clients`, customerId);
            
            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the active collection");
            }
            
            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {...customerData, status: "active"};
            
            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${NewSalesID}/clients`, customerId);
            
            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);
            
            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);
            
            console.log("Customer moved to new Sales Rep");
            alert("Customer moved to new Sales Rep");
            
            // // Generate formatted date and time
            // const now = new Date();
            // const options = {
            //     year: 'numeric',
            //     month: 'short',
            //     day: '2-digit',
            //     hour: 'numeric',
            //     minute: '2-digit',
            //     hour12: true
            // };
            // const formattedDateTime = now.toLocaleString('en-US', options);
            //
            // // Add a notification document to the Notifications collection
            // const notificationsCollectionRef = collection(db, `Users/${salesID}/Notifications`);
            // const notificationData = {
            //     type: "C", // Notification type
            //     identifier: customer.customerUID,
            //     createdAt: formattedDateTime, // Formatted date and time
            // };
            //
            // await addDoc(notificationsCollectionRef, notificationData);
            // console.log("Notification document added successfully.");
        } catch (error) {
            console.error("Error moving customer to new Sales Rep:", error);
            alert("Error moving customer to new Sales Rep:");
        }
    };

    // Fetch users from Firestore on component mount
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'Users');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id, // UID from Firestore document ID
                name: `${doc.data().fname} ${doc.data().lname}`, // Combining fname and lname into a single name field
            }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);
    
    // Handle selection change
    const handleChange = (event) => {
        const selectedID = event.target.value;
        setNewSalesID(selectedID);
    };
    
    const onUpdateMain = async (customerId) => {
    
        console.log("Customer ID", customerId);
        console.log("Sales ID", salesID);
        
        if (!customerId) {
            console.error("No customer ID provided for update");
            return;
        }
    
        const customerDocRef = db
            .collection("Customers")
            .doc(customer.userId)
            .collection("clients")
            .doc(customerId);
    
        const updatedFormData = {
            // Main
            name: CustName,
            managementName: managementName,
            address1: address1,
            address2: address2,
            city: city,
            state: selectedState,
            zip: zip,
            county: selectedCounty,
            fips: fips,
            country: country,
            industry: industry,
    
            // Contacts
            contact: contact,
            contactPosition: contactPosition,
            contactPhone: contactPhone,
            contactEmail: contactEmail,
            billingContact: billingContact,
            billingPhone: billingPhone,
            invoiceEmails: invoiceEmails,
            statementEmails: statementEmails,
            website: website,
    
            // Finances
            taxID: taxID,
            salesTaxStatus: salesTaxStatus,
            taxExempCode: taxExempCode,
            paymentType: paymentType,
    
            // Delivery
            TTA: TTA,
            LAS: LAS,
            LDA: LDA,
            PJR: PJR,
            DAR: DAR,
            HSA: HSA,
            FLC: FLC,
            // deliveryInstructions: deliveryInstructions,
        };
    
        try {
            await customerDocRef.update(updatedFormData);
            alert(`Customer data updated successfully`);
        } catch (error) {
            console.error("Error updating customer:", error.message);
        }
    };
    
    
    return (
        <div className={`w-full flex flex-col z-50 border-b-2 ${darkMode ? 'border-darkBgColor' : ''} p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>
            
            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>
                
                <div className="w-full flex flex-row">
                    
                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-5 hidden text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>
                    
                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">
                        
                        <div className={`w-8 p-1 h-8 bg-bgColor rounded-full hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-xs line-clamp-1">{customer.salesCode || "--"}</div>
                        </div>
                        
                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-xs xl:text-sm line-clamp-1">{customer.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xxs xl:text-xs line-clamp-1">{customer.salesEmail || "- - - - - - - - -"}</div>
                        </div>
                    
                    </div>
                    
                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">
                        
                        <div className={`w-fit p-1 px-2 h-8 rounded-lg hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-xs line-clamp-1">{customer.customerCode || "- - - - -"}</div>
                        </div>
                        
                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-xs xl:text-sm line-clamp-1">{customer.name || "- - - - - - - - -"}</div>
                            <div className={`text-xxs text-gray-500 line-clamp-1`}>{customer.contact || "- - - - -"} | {formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                        </div>
                    
                    
                    </div>
                    
                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">
                        
                        {/*Status*/}
                        <div className="mr-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>
                                    
                                    <div className={`w-fit p-1 h-6 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-3 xl:w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xxs xl:text-xs mr-3">
                                            {customer.existing ? 'Existing' : 'Active'}
                                        </div>
                                    </div>
                                
                                </div>
                            )}
                            
                            {customer.status === "pending" && (
                                <div>
                                    
                                    <div className={`w-fit p-1 h-6 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ArrowPathIcon className="w-3 xl:w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xxs xl:text-xs mr-3">Pending</div>
                                    </div>
                                
                                </div>
                            )}
                        </div>
                        
                        
                        {/*Button*/}
                        <div className="ml-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>
                                    
                                    <div className={`w-16 p-1 h-6 rounded-full flex flex-row hover:scale-105 ${darkMode ? '' : ''} ${orderLength ? `${darkMode ? 'border border-blue-700 bg-blue-600/15 text-blue-200' : 'border border-blue-700 bg-blue-500/15 text-blue-700'}` : `${darkMode ? 'border border-red-500 bg-red-500/15 text-red-200' : 'border border-red-500 bg-red-500/15 text-red-500'}`}`}>
                                        <ClipboardDocumentListIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center font-semibold h-full text-xs mr-3 ml-auto">
                                            {orderLength}
                                        </div>
                                    </div>
                                
                                </div>
                            )}
                            
                            {customer.status === "pending" && (
                                <div>

                                    <div>
                                        
                                        <Button
                                            className={`w-fit px-2 py-1.5 ${disabled ? '' : 'hover:scale-105 hover:bg-opacity-50'} bg-green-700 text-white line-clamp-1 rounded-xl shadow-lg text-xs flex flex-row items-center justify-center`}
                                            onClick={toggleAdding}
                                            disabled={disabled}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto hover:rotate-12 mb-auto mr-2 line-clamp-1"/>Status
                                        
                                            {!customer.userId && (
                                                <div className={`absolute bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-50 px-2 py-0.5`}>No UserID</div>
                                            )}
                                            
                                        </Button>
    
                                    </div>
    
                                </div>
                            
                            )}
                        </div>
                    
                    </div>
                    
                    {/*YTD*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">
                        
                        <div className={`w-1/2 p-1 h-8 bg-green-600/15 border border-green-600 rounded-full`}>
                            <div className="flex items-center justify-center h-full text-sm px-2">$ {numberWithCommas(activeOrdersTotal.toFixed(2))}<span className="hidden xl:inline ml-1 text-xxs">USD</span></div>
                        </div>
                    
                    </div>
                    
                    
                    {/* Buttons DropDown */}
                    <div className={`flex flex-row items-center ${customer.existing ? '' : ''}`}>
                        <Button
                            className={` rounded-full mt-auto mb-auto h-8 w-8 mr-2 items-center justify-center align-middle`}
                            onClick={toggleMoving}
                        >
                            <LinkIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                        
                        <Button
                            className={`rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle`}
                            onClick={toggleVisibility}
                        >
                            <ArrowTopRightOnSquareIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                    </div>
                
                </div>
            
            </div>
            
            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                
                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isVisible}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isVisible ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full flex items-center justify-center">
                        
                        {/* Modal content */}
                        <div className={`animate-zoomIn w-5/6 h-[40rem] relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg`}>
                            
                            {/* Modal header */}
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xs line-clamp-1 tracking-widest">
                                    <span
                                        className={`font-bold tracking-widest text-lg mr-6`}>{customer.name || "- - - -"}'s</span> Account
                                    Information
                                </h3>
                                
                                <Button
                                    className={`ms-auto flex text-xs items-center mr-6 px-2 bg-blue-500 hover:text-opacity-50 tracking-widest py-1 text-white rounded-lg`}
                                    onClick={() => onUpdateMain(customer.id)}
                                >
                                    <ArrowUpTrayIcon className={`w-4 mr-2`}/> Update
                                </Button>
                                
                                <Button
                                    className={`flex text-sm mr-6 hover:text-opacity-50 tracking-widest py-1 text-gray-500 rounded-full`}
                                    onClick={expandAll}
                                >
                                    <ArrowsPointingOutIcon className={`w-6`}/>
                                </Button>
                                
                                <Button
                                    className={` flex text-sm mr-6 hover:text-opacity-50 tracking-widest py-1 text-gray-500 rounded-full`}
                                    onClick={collapseAll}
                                >
                                    <ArrowsPointingInIcon className={`w-6`}/>
                                </Button>
                                
                                <button
                                    type="button"
                                    className="text-gray-500 bg-transparent hover:text-opacity-50 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            
                            {/* Modal body */}
                            <div className={`w-full flex flex-row p-3 h-full relative`}>
                                
                                {/*Account Info Short*/}
                                <div className={`relative w-1/4 h-[33.6rem] mr-3 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-neutral-50 drop-shadow'} border-2 rounded-lg`}>
                                    
                                    <div className={`top-1.5 right-1.5 absolute text-center text-xs tracking-widest line-clamp-1 bg-green-200/30 border-green-600 border font-bold rounded-full px-2`}>{customer.priceType}</div>
                                    
                                    
                                    {/*Name and Code*/}
                                    <div className={`border-b-2 p-3 border-dashed ${darkMode ? 'border-neutral-700' : ''}`}>
                                        <div className={`text-center mt-3 px-4 py-1.5 rounded-lg mr-auto ml-auto text-sm ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} w-fit`}>{customer.customerCode}</div>
                                        <div className={`text-center tracking-widest mt-3 line-clamp-1`}>{customer.name}</div>
                                    </div>
                                    
                                    <div className={`flex h-[25.5rem] overflow-y-scroll flex-col`}>
                                        <div className={`p-3 flex flex-row items-center mb-6`}>
                                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Active Orders</div>
                                            <div className={`px-4 text-center rounded-lg w-1/4 bg-orderColor bg-opacity-20 border-2 border-orderColor`}>{orderLength}</div>
                                        </div>
                                        
                                        <div className={`p-3 flex flex-row items-center mb-6`}>
                                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Pending Orders</div>
                                            <div className={`px-4 text-center rounded-lg w-1/4 bg-pendingColor bg-opacity-20 border-2 border-pendingColor`}>{pendingLength}</div>
                                        </div>
                                        
                                        <div className={`p-3 flex flex-row items-center`}>
                                            <div className={`px-4 py-1 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Planned Orders</div>
                                            <div className={`px-4 text-center rounded-lg w-1/4 bg-plannedColor bg-opacity-20 border-2 border-plannedColor`}>{plannedLength}</div>
                                        </div>
                                        
                                        <div className={`relative mx-4 mt-3`}>
                                            <label
                                              htmlFor="in"
                                              className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                            >
                                              Industry
                                            </label>
                                            <Select
                                                id="in"
                                                name="in"
                                                value={industry}
                                                onChange={(e) => setIndustry(e.target.value)}
                                                placeholder={`Product Code`}
                                                className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                            >
                                                <option value="">Select...</option>
                                                <option value="BOTANICAL">Botanical Garden</option>
                                                <option value="DISTRIB">Product Distributor</option>
                                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                                <option value="FARM">Agriculture - Farm</option>
                                                <option value="GOLF">Golf Course</option>
                                                <option value="GOVERNMENT">Government Entity</option>
                                                <option value="INDUSTRIAL">Industrial</option>
                                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                                <option value="MRSALE">One Time Purchase Expected</option>
                                                <option value="MUNICIPAL">Municipal/ School</option>
                                                <option value="Non-Profit">Non-Profit Account</option>
                                                <option value="OTHER">Other</option>
                                                <option value="SPORTFLD">Sportsfield</option>
                                            </Select>
                                        </div>
                                    
                                    </div>
                                    
                                    <div
                                        className={`text-center text-xxs tracking-widest line-clamp-1 bg-green-200/30 border-green-600 border font-bold rounded-full mx-6`}>{customer.id}</div>
                                
                                
                                </div>
                                
                                {/*Account Info Long*/}
                                <div className={`relative flex flex-col w-3/4 h-[33.6rem]`}>
                                    
                                    {/*Tabs*/}
                                    <div
                                        className={`w-full h-12 mb-2 flex px-3 justify-center p-1 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-neutral-50 drop-shadow'} border-2 space-y-12 rounded-lg hide-scrollbar flex flex-col overflow-y-scroll transition-all duration-500 ease-in-out`}>
                                        
                                        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                                            {tabs.map((tab) => (
                                                <button
                                                    key={tab.name}
                                                    onClick={() => setActiveTab(tab.name)}
                                                    className={`flex whitespace-nowrap border-b-2 px-1 py-1 text-sm font-medium ${
                                                        activeTab === tab.name
                                                            ? 'border-green-600 text-green-600'
                                                            : 'border-transparent hover:border-gray-200 hover:text-gray-700'
                                                    }`}
                                                >
                                                    {tab.name}
                                                    {tab.count ? (
                                                        <span
                                                            className={`ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block ${
                                                                activeTab === tab.name
                                                                    ? 'bg-emerald-700 bg-opacity-50 border border-green-600'
                                                                    : 'bg-green-600 bg-opacity-50 border border-green-600'
                                                            }`}
                                                        >
                                                            {tab.count}
                                                        </span>
                                                    ) : null}
                                                </button>
                                            ))}
                                        </nav>
                                    
                                    </div>
                                    
                                    {/*Account Info Long*/}
                                    <div className={`w-full h-[33.6rem] ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-neutral-50 drop-shadow'} border-2 space-y-12 rounded-lg p-4 hide-scrollbar flex flex-col overflow-y-scroll transition-all duration-500 ease-in-out`}>
                                        
                                        {renderTabContent()}
                                    
                                    </div>
                                </div>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
            
            )}
            
            
            <Dialog open={isAdding} onClose={setIsAdding} className="relative z-50">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                        <DocumentArrowUpIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle as="h3" className="text-base font-semibold">
                                Customer Internal SetUp
                            </DialogTitle>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Make sure that you have reviewed all of the customers information. Once you have
                                    done so please enter in the fields below.
                                </p>
                            </div>
                            
                            <div className="mt-2 grid grid-cols-2 gap-3">
                                
                                {/*Code*/}
                                <div className={`relative col-span-full`}>
                                    <label
                                        htmlFor="code"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Customer Code
                                    </label>
                                    <Input
                                        id="code"
                                        name="code"
                                        type="text"
                                        value={customerCode}
                                        onChange={(e) => setCustomerCode(e.target.value)}
                                        placeholder={`Customer Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                                
                                {/*Pricing Type*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="type"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Pricing Type
                                    </label>
                                    <Select
                                        id="type"
                                        name="type"
                                        onChange={(event) => {
                                            const selectedValue = event.target.value;
                                            setPriceCode(selectedValue); // Update priceCode state
                                            console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                        }}
                                        placeholder={`Product Code`}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    >
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST1">Distributor NE/INTL</option>
                                        <option value="DIST2">Distributor SE/CAN</option>
                                        <option value="NE">Northeast</option>
                                        <option value="SE">Southeast</option>
                                    </Select>
                                </div>
                                
                                {/*Credit Limit*/}
                                <div className={`relative col-span-1`}>
                                    <label
                                        htmlFor="cl"
                                        className={`absolute -top-2 left-2 inline-block rounded-lg ${darkMode ? 'bg-darkMainColor' : 'bg-white text-gray-900'} px-1 text-xs font-medium`}
                                    >
                                        Credit Limit
                                    </label>
                                    <Input
                                        id="cl"
                                        name="cl"
                                        type="number"
                                        value={creditLimit}
                                        onChange={(e) => setCreditLimit(e.target.value)}
                                        placeholder={'Credit Limit'}
                                        className={`block w-full rounded-md ${darkMode ? 'bg-darkMainColor text-white outline-gray-500' : 'bg-white text-gray-900 outline-gray-300'} px-3 py-2 text-base outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6`}
                                    />
                                </div>
                            
                            </div>
                        
                        
                        </div>
                    </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <Button
                              type="button"
                              onClick={() => submitUpdate(customer.id)}
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                          >
                              Connect
                          </Button>
                          <Button
                              type="button"
                              data-autofocus
                              onClick={() => setIsAdding(false)}
                              className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                          >
                              Cancel
                          </Button>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
            
            <Dialog open={isMoving} onClose={setIsMoving} className="relative z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />
                
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className={`relative transform overflow-hidden rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`}
                        >
                            <div className="sm:flex sm:items-start">
                                <div
                                    className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-600/15 sm:mx-0 sm:size-10">
                                    <LinkIcon aria-hidden="true" className="size-6 text-green-600"/>
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle as="h3" className="text-base font-semibold">
                                        Transfer Customer
                                    </DialogTitle>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to transfer this Customer? All of its data will be
                                            permanently transfered from
                                            your account. This action cannot be undone.
                                        </p>
                                    </div>
                                    
                                    <Select
                                        id="user-select"
                                        value={NewSalesID}
                                        onChange={handleChange}
                                        required={true}
                                        className={`rounded-lg block w-full p-1.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    >
                                        <option value="" disabled>
                                            Select a User
                                        </option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </Select>
                                    {NewSalesID && (
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected User ID: {NewSalesID}
                                        </p>
                                    )}
                                
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    type="button"
                                    onClick={() => moveToNewSalesRep(customer.id, NewSalesID)}
                                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50 sm:ml-3 sm:w-auto"
                                >
                                    Transfer
                                </Button>
                                <Button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setIsMoving(false)}
                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${darkMode ? 'bg-darkBgColor' : 'bg-white ring-1 ring-inset ring-gray-300'} px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-50 sm:mt-0 sm:w-auto`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        
        
        </div>
    )
}

export default CustomerCard;
