import React, {useState} from 'react';
import logo from "../main-logo.png";
import course from "../course.jpg";
import {Link, Navigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext";
import {signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase/firebase";
import {Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {CheckIcon} from "@heroicons/react/24/outline";


const Login = () => {

    const { userLoggedIn } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isSigningIn, setIsSigningIn] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn){
            setIsSigningIn(true);
            try {
                await signInWithEmailAndPassword(auth, email, password);  // Pass auth, email, password
            } catch (error) {
                console.error("Sign in failed:", error);
                alert("Sign In Failed! Check your Email and Password");
                setIsSigningIn(false);  // Reset sign-in state on failure
            }
        }
    }
    
    const [isVisible, setIsVisible] = useState(false);

    const handleButtonClick = () => {
        setIsVisible(true);
    };


    return (
        <>

            {userLoggedIn && <Navigate to={'/SplashScreen'} replace={true} />}




            <div className="flex min-h-full flex-1 bg-bgColor animate-zoomIn">

                <Link className={`absolute right-0 z-50 mr-8 mt-8 rounded-lg hover:text-green-600 px-6 py-1`}
                      to={'/LandingPage'}>
                    Return to Home <span aria-hidden="true">&rarr;</span>
                </Link>

                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                alt="Your Company"
                                src={logo}
                                className="h-10 w-auto"
                            />
                            <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">Sign in to your account</h2>
                            <p className="mt-2 text-sm/6 text-gray-500">
                                New Employee?{' '}
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Button
                                    className="font-semibold text-green-600 hover:text-indigo-500"
                                    onClick={handleButtonClick}
                                >
                                    Contact to Gain Access
                                </Button>
                            
                                {/*{isVisible && (*/}
                                {/*    <div className=" absolute border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-4">*/}
                                {/*        <div className="flex">*/}
                                {/*            <div className="shrink-0"><ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400"/>*/}
                                {/*        </div>*/}
                                {/*        <div className="ml-3">*/}
                                {/*            <p className="text-sm text-yellow-700">*/}
                                {/*                Contact Spencer.{" "}*/}
                                {/*                <a*/}
                                {/*                href="mailto:swondolowski@plantfoodco.com"*/}
                                {/*                className="font-medium text-yellow-700 underline hover:text-yellow-600"*/}
                                {/*                >*/}
                                {/*              swondolowski@plantfoodco.com*/}
                                {/*                </a>*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*      </div>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </p>
                        </div>
                        
                        <div className="mt-10">
                            <div>
                                <form onSubmit={onSubmit} method="POST" className="space-y-6">
                                    <div>
                                        <label htmlFor="email"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                autoComplete="email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                autoComplete="current-password"
                                                value={password} onChange={(e) => setPassword(e.target.value)}
                                                className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">

                                        <div className="ml-auto text-sm/6">
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            {/*<a href="#" className="font-semibold text-green-600 hover:text-indigo-500">*/}
                                            {/*    Forgot password?*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>

                                    <div>
                                        <Button
                                            type="submit"
                                            disabled={isSigningIn}
                                            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Sign in
                                        </Button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="animate-slideInRight relative hidden w-0 flex-1 lg:block border-l-4 border-neutral-400">
                    <img
                        alt=""
                        src={course}
                        className="absolute inset-0 size-full object-cover"
                    />
                </div>
            </div>
            
            
            
            
            
            
            
            <Dialog open={isVisible} onClose={setIsVisible} className="relative z-10">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
        
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                  >
                    <div>
                      <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon aria-hidden="true" className="size-6 text-green-600" />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                          To Gain Access
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            This System is only available to Plant Food Co Employees! You must contact Spencer to access!
                          </p>
                        </div>
                      </div>
                    </div>
                      <div className="mt-5 sm:mt-6">
                          {/*<button*/}
                          {/*  type="button"*/}
                          {/*  onClick={() => setIsVisible(false)}*/}
                          {/*  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
                          {/*>*/}
                          {/*  Go back to dashboard*/}
                          {/*</button>*/}
                          <a
                              href="mailto:swondolowski@plantfoodco.com"
                              className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50"
                          >
                              swondolowski@plantfoodco.com
                          </a>
                      </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
        
        
        </>
    )
};


export default Login;
