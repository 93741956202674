import React from "react";
import CustomerCard from "./CustomerCard";
import {EyeSlashIcon} from "@heroicons/react/24/outline";

// Reusable Section Component
const CustomerSection = ({ customers, emptyTitle, emptyDescription, darkMode }) => {
    return (
        <div className="w-full h-full relative animate-fadeIn">
            {customers.length === 0 ? (
                <div className="flex w-full h-full justify-center items-center py-10">
                    <div
                        className={`flex items-center border-2 border-dashed ${
                            darkMode ? "text-white border-borderColor" : "text-black bg-neutral-50"
                        } w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}
                    >
                        <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                            <EyeSlashIcon className="w-10 stroke-2 mr-3" />
                            <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">{emptyTitle}</p>
                            <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">
                                {emptyDescription}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                customers.map((customer, index) => (
                    <CustomerCard key={customer.id} index={customer.id} customer={customer} />
                ))
            )}
        </div>
    );
};

export default CustomerSection;