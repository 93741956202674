import React, {useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";

import HomeGuides from "./Guides/HomeGuides"
import OrdersGuides from "./Guides/OrdersGuides";
import NewOrderGuides from "./Guides/NewOrderGuides";
import ProfileGuides from "./Guides/ProfileGuides";
import Background from "./Background";
import ArchiveGuides from "./Guides/ArchiveGuides";

const Guides = () => {

    const { darkMode } = useDarkMode();

    const [activeTab, setActiveTab] = useState(16);

    // Define tab options and corresponding components for content
    const main = [
        { id: 0, title: 'Home Page', content: <OrdersGuides /> },
        { id: 1, title: 'Dashboard', content: <NewOrderGuides /> },
        { id: 2, title: 'Catalog', content: <HomeGuides /> },
        { id: 3, title: 'Proven Programs', content: <OrdersGuides /> },

        { id: 4, title: 'Current Orders', content: <OrdersGuides /> },
        { id: 5, title: 'Archived Orders', content: <ArchiveGuides /> },
        { id: 6, title: 'Customer Management', content: <HomeGuides /> },
        { id: 7, title: 'Sales Team', content: <OrdersGuides /> },

        { id: 8, title: 'Help & Resources', content: <OrdersGuides /> },
        { id: 9, title: 'Program Builder', content: <NewOrderGuides /> },
        { id: 10, title: 'Support Tickets', content: <HomeGuides /> },
        { id: 11, title: 'Product Pricing', content: <OrdersGuides /> },
        { id: 12, title: 'Messaging', content: <OrdersGuides /> },

        { id: 13, title: 'Incoming Orders', content: <OrdersGuides /> },
        { id: 14, title: 'Incoming Customers', content: <NewOrderGuides /> },

        { id: 15, title: 'New Customer Form', content: <HomeGuides /> },
        { id: 16, title: 'New Order Form', content: <NewOrderGuides /> },
        { id: 17, title: 'Profile View', content: <ProfileGuides /> },
    ];




    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            <Background/>

            <div className={`z-20 flex w-full`}>


                {/*Pages Nav*/}
                <div className={`animate-slideInLeft flex w-1/5 p-2 mr-3 rounded-lg mt-3 overflow-y-auto hide-scrollbar mb-3 ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow-lg'} flex-col`}>

                    <div className="w-64 h-screen px-3 py-6">

                        {/* Section: Global */}
                        <div className="mb-6">
                            <h2 className="text-md w-fit tracking-widest font-semibold mb-3 text-green-500">Global</h2>
                            <ul className={`ml-6`}>

                                {main.filter((tab) => tab.id >= 15 && tab.id <= 17) // Filter for items with id between 0 and 2
                                    .map((tab) => (
                                        <button
                                            key={tab.id}
                                            className={`flex items-center w-full flex-1 px-4 border-l-2 mb-1 py-1 hover:ml-4 transition-all duration-300 ease-in-out ${
                                                activeTab === tab.id
                                                    ? `text-green-600 bg-green-600/30 rounded-r-lg ${darkMode ? 'border-green-600 border-l-4' : 'border-green-600 border-l-4'}`
                                                    : `${darkMode ? 'text-gray-500' : 'text-gray-500'}`
                                            }`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            <div className={`line-clamp-1 mr-auto text-sm text-start`}>
                                                {tab.title}
                                            </div>
                                        </button>
                                    ))}
                            </ul>
                        </div>

                        {/* Section: Main */}
                        <div className="mb-6">
                            <h2 className="text-md tracking-widest font-semibold mb-3 text-green-500">Main</h2>
                            <ul className={`ml-6`}>

                                {main.filter((tab) => tab.id >= 0 && tab.id <= 3) // Filter for items with id between 0 and 2
                                    .map((tab) => (
                                        <button
                                            key={tab.id}
                                            className={`flex items-center w-full flex-1 px-4 border-l-2 mb-1 py-1 hover:ml-4 transition-all duration-300 ease-in-out ${
                                                activeTab === tab.id
                                                    ? `text-green-600 bg-green-600/30 rounded-r-lg ${darkMode ? 'border-green-600 border-l-4' : 'border-green-600 border-l-4'}`
                                                    : `${darkMode ? 'text-gray-500' : 'text-gray-500'}`
                                            }`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            <div className={`line-clamp-1 mr-auto text-sm text-start`}>
                                                {tab.title}
                                            </div>
                                        </button>
                                    ))}
                            </ul>
                        </div>

                        {/* Section: Data */}
                        <div className="mb-6">
                            <h2 className="text-md tracking-widest font-semibold mb-3 text-green-500">Data</h2>
                            <ul className={`ml-6`}>

                                {main.filter((tab) => tab.id >= 4 && tab.id <= 7) // Filter for items with id between 0 and 2
                                    .map((tab) => (
                                        <button
                                            key={tab.id}
                                            className={`flex items-center w-full flex-1 px-4 border-l-2 mb-1 py-1 hover:ml-4 transition-all duration-300 ease-in-out ${
                                                activeTab === tab.id
                                                    ? `text-green-600 bg-green-600/30 rounded-r-lg ${darkMode ? 'border-green-600 border-l-4' : 'border-green-600 border-l-4'}`
                                                    : `${darkMode ? 'text-gray-500' : 'text-gray-500'}`
                                            }`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            <div className={`line-clamp-1 mr-auto text-sm text-start`}>
                                                {tab.title}
                                            </div>
                                        </button>
                                    ))}
                            </ul>
                        </div>

                        {/* Section: Tools */}
                        <div className="mb-6">
                            <h2 className="text-md tracking-widest font-semibold mb-3 text-green-500">Tools</h2>
                            <ul className={`ml-6`}>

                                {main.filter((tab) => tab.id >= 8 && tab.id <= 12) // Filter for items with id between 0 and 2
                                    .map((tab) => (
                                        <button
                                            key={tab.id}
                                            className={`flex items-center w-full flex-1 px-4 border-l-2 mb-1 py-1 hover:ml-4 transition-all duration-300 ease-in-out ${
                                                activeTab === tab.id
                                                    ? `text-green-600 bg-green-600/30 rounded-r-lg ${darkMode ? 'border-green-600 border-l-4' : 'border-green-600 border-l-4'}`
                                                    : `${darkMode ? 'text-gray-500' : 'text-gray-500'}`
                                            }`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            <div className={`line-clamp-1 mr-auto text-sm text-start`}>
                                                {tab.title}
                                            </div>
                                        </button>
                                    ))}
                            </ul>
                        </div>

                        {/* Section: Plant */}
                        <div className="mb-6">
                            <h2 className="text-md tracking-widest font-semibold mb-3 text-green-500">Plant</h2>
                            <ul className={`ml-6`}>

                                {main.filter((tab) => tab.id >= 13 && tab.id <= 14) // Filter for items with id between 0 and 2
                                    .map((tab) => (
                                        <button
                                            key={tab.id}
                                            className={`flex items-center w-full flex-1 px-4 border-l-2 mb-1 py-1 hover:ml-4 transition-all duration-300 ease-in-out ${
                                                activeTab === tab.id
                                                    ? `text-green-600 bg-green-600/30 rounded-r-lg ${darkMode ? 'border-green-600 border-l-4' : 'border-green-600 border-l-4'}`
                                                    : `${darkMode ? 'text-gray-500' : 'text-gray-500'}`
                                            }`}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            <div className={`line-clamp-1 mr-auto text-sm text-start`}>
                                                {tab.title}
                                            </div>
                                        </button>
                                    ))}
                            </ul>
                        </div>


                    </div>

                    {/*{tabs.map((tab) => (*/}
                    {/*    <button*/}
                    {/*        key={tab.id}*/}
                    {/*        className={`flex items-center flex-1 px-4 ${*/}
                    {/*            activeTab === tab.id*/}
                    {/*                ? `rounded-lg border ${darkMode ? 'border-borderColor' : ''}`*/}
                    {/*                : `${darkMode ? 'text-white' : 'text-black'}`*/}
                    {/*        }`}*/}
                    {/*        onClick={() => setActiveTab(tab.id)}*/}
                    {/*    >*/}
                    {/*        <div className={`bg-bgLightGreen text-white w-8 h-8 justify-center items-center rounded-md flex mr-2 p-2`}>*/}
                    {/*            {tab.icon}*/}
                    {/*        </div>*/}

                    {/*        <div className={`line-clamp-1 mr-auto text-start`}>*/}
                    {/*            {tab.title}*/}
                    {/*        </div>*/}

                    {/*    </button>*/}
                    {/*))}*/}

                </div>

                {/*FAQ*/}
                <div className={`animate-slideInBottom flex w-full mr-3 rounded-lg mt-3 mb-3 ${darkMode ? 'bg-darkMainColor' : 'bg-white drop-shadow-lg'} flex-col`}>

                    {main[activeTab].content}

                </div>

            </div>

        </div>
    )
};


export default Guides;
