import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";

import { AgCharts } from "ag-charts-react";
import {useAuth} from "../contexts/authContext";
import {ArrowTrendingDownIcon, ArrowTrendingUpIcon} from "@heroicons/react/24/outline";

const Dashboard = () => {

    const { darkMode } = useDarkMode();
    const { currentUser } = useAuth();

    const [ordersPending, setOrdersPending] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [ordersPlanned, setOrdersPlanned] = useState([]);
    const [chartDataPlanned, setChartDataPlanned] = useState([]);
    
    const [ordersActive, setOrdersActive] = useState([]);
    const [chartDataActive, setChartDataActive] = useState([]);

    const [loading, setLoading] = useState(true);  // Add loading state

    const [textColor, setTextColor] = useState("#21b372");
    const [lineColor, setLineColor] = useState("#FDDE02");
    const [barColor, setBarColor] = useState("#3e3e42");
    const [bgColor, setBgColor] = useState("#1e1e1e");


    useEffect(() => {

        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    let activeData = [];

                    if (storedActiveData) {
                        // If data is available in localStorage
                        activeData = JSON.parse(storedActiveData);
                    } else {
                        console.log("No Data Stored")
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = activeData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: order.grandTotal || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartDataActive(chartData);

                    // Set pending orders data
                    setOrdersActive(activeData);

                    console.log(chartData);
                    setLoading(false);  // Mark loading as complete

                } catch (error) {
                    console.error('Error fetching active orders data:', error);
                }
            }
        };

        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                       console.log("No Data Stored");
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = pendingData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: order.grandTotal || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartData(chartData);

                    // Set pending orders data
                    setOrdersPending(pendingData);

                    console.log(chartData);
                    setLoading(false);  // Mark loading as complete

                } catch (error) {
                    console.error('Error fetching pending orders data:', error);
                }
            }
        };

        const fetchOrdersPlanned = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`plannedOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Format data for the chart: Extract 'customerCode' and 'grandTotal'
                    const chartData = pendingData.map((order, index) => ({
                        index: `Order [${index + 1}]`,
                        customerCode: order.customerCode || 'Unknown',  // Fallback if customerCode is missing
                        grandTotal: order.grandTotal || 0,  // Fallback if grandTotal is missing
                    }));

                    // Set the chart data
                    setChartDataPlanned(chartData);

                    // Set pending orders data
                    setOrdersPlanned(pendingData);

                    console.log(chartData);
                    setLoading(false);  // Mark loading as complete

                } catch (error) {
                    console.error('Error fetching planned orders data:', error);
                }
            }
        };

        fetchOrdersActive();
        fetchOrdersPending();
        fetchOrdersPlanned();

    }, [currentUser]);

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setTextColor("#21b372"); // Light text color for dark mode
            setBarColor("#3e3e42");
            setLineColor("#FDDE02");
            setBgColor("#1e1e1e");   // Dark background color for dark mode
        } else {
            setTextColor("#000000"); // Dark text color for light mode
            setBarColor("#efefef");
            setLineColor("#21b372");
            setBgColor("#ffffff");   // Light background color for light mode
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    const myTheme = {
        palette: {
            fills: [barColor, "#21B372", "#FDDE02", "#F76700", "#D30018"],
        },
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },
            bar: {
                series: {
                    // label: {
                    //     enabled: true,
                    //     color: textColor,
                    // },
                    cornerRadius: 10,
                },
            },
            line: {
                series: {
                    label: {
                        enabled: true,
                        color: lineColor,
                    },
                    stroke: lineColor,
                    circle: lineColor,
                    strokeWidth: 2,
                },
            },

        },
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Chart Options: Control & configure the chart

    const [orderOptions, setOrderOptions] = useState({
        // theme: myTheme,
        title: { text: 'Pending Orders' },
        subtitle: { text: 'Data from 2024' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
            {
                type: 'line',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
                label: {
                    formatter: (params) => {
                        return "$ " + numberWithCommas(params.value);
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    });

    const [orderOptionsCustomer, setOrderOptionsCustomer] = useState({
        // theme: myTheme,
        title: { text: 'Pending Orders by Customer' },
        subtitle: { text: 'Data from Current Orders' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'customerCode',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    })
    
    const [plannedOptions, setPlannedOptions] = useState({
        // theme: myTheme,
        title: { text: 'Planned Orders' },
        subtitle: { text: 'Data from 2024' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
            {
                type: 'line',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
                label: {
                    formatter: (params) => {
                        return "$ " + numberWithCommas(params.value);
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    });

    const [activeOptions, setActiveOptions] = useState({
        // theme: myTheme,
        title: { text: 'Active Orders' },
        subtitle: { text: 'Data from 2024' },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
            },
            {
                type: 'line',
                xKey: 'index',
                yKey: 'grandTotal',
                yName: 'Grand Total',
                label: {
                    formatter: (params) => {
                        return "$ " + numberWithCommas(params.value);
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    // rotation: 45,
                },
            },
            {
                type: 'number',
                position: 'left',
                label: {
                    formatter: (params) => {
                        return '$' + params.value.toLocaleString();
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    });


    // Update chart options whenever chartData is updated
    useEffect(() => {
        setActiveOptions((prevOptions) => ({
            ...prevOptions,
            data: chartDataActive,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));

        setOrderOptions((prevOptions) => ({
            ...prevOptions,
            data: chartData,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));
        
        setOrderOptionsCustomer((prevOptions) => ({
            ...prevOptions,
            data: chartData,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));

        setPlannedOptions((prevOptions) => ({
            ...prevOptions,
            data: chartDataPlanned,
            theme: myTheme,
            background: {
                fill: bgColor, // Update background color dynamically
            },
        }));
            // eslint-disable-next-line
    }, [chartData, chartDataPlanned, chartDataActive, darkMode, bgColor, textColor]);


    // Function to calculate the total grand total from pending orders
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return (total + (order.grandTotal || 0)); // Add 0 for undefined grandTotal
        }, 0);
    };

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            <div className="z-50 animate-fadeIn flex flex-col w-full mt-3 mr-3 overflow-y-auto rounded-lg max-h-[calc(100vh-4.7rem)]">


                {/*Header*/}
                <div className="w-full h-fit flex mr-3 text-sm">

                    <div className={`w-1/3 mr-3 p-3 rounded-lg drop-shadow flex flex-row items-center justify-center ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        <ArrowTrendingUpIcon className={`hover:animate-pulse h-12 ${darkMode ? 'bg-orderColor text-black' : 'bg-orderColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? 'text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Active</div>
                        <div className={`ml-auto line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersActive).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>

                    <div className={`w-1/3 mr-3 p-3 rounded-lg drop-shadow flex flex-row items-center ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        <ArrowTrendingDownIcon className={`h-12 ${darkMode ? 'bg-pendingColor text-black' : 'bg-pendingColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? 'text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Pending</div>
                        <div className={`ml-auto line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersPending).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>

                    <div className={`w-1/3 p-3 rounded-lg drop-shadow flex flex-row items-center ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        <ArrowTrendingUpIcon className={`h-12 ${darkMode ? 'bg-plannedColor text-black' : 'bg-plannedColor text-black'} p-3 rounded-lg`}/>
                        <div className={`ml-5 line-clamp-1 ${darkMode ? ' text-green-400' : 'text-black'} rounded-lg tracking-widest font-semibold`}>Planned</div>
                        <div className={`ml-auto line-clamp-1 text-xs ${darkMode ? 'bg-darkBgColor bg-opacity-80 text-white' : 'bg-bgColor text-black'} px-4 py-1 rounded-lg tracking-wider`}>$ {numberWithCommas(calculateTotalGrandTotal(ordersPlanned).toFixed(2)) || "- - - - - -"} USD</div>
                    </div>

                </div>

                {/*Charts Middle*/}
                <div className="w-full h-full flex">

                    <div className={`w-full mt-3 mb-3 mr-3 p-3 rounded-lg drop-shadow ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        {loading ?
                            <div className="text-white">Loading chart...</div>
                            :
                            <div style={{display: 'grid', width: '100%', height: '100%'}}>
                                <AgCharts options={orderOptions}/>
                            </div>
                        }
                    </div>
                    <div className={`w-full mt-3 mb-3 p-3 rounded-lg drop-shadow ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        {loading ?
                            <div className="text-white">Loading chart...</div>
                            :
                            <div style={{display: 'grid', width: '100%', height: '100%'}}>
                                <AgCharts options={activeOptions}/>
                            </div>
                        }
                    </div>


                </div>

                {/*Charts Bottom*/}
                <div className="w-full h-full flex">

                    <div className={`w-1/3 mb-3 mr-3 p-3 rounded-lg drop-shadow ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        {loading ?
                            <div className="text-white">Loading chart...</div>
                            :
                            <div style={{display: 'grid', width: '100%', height: '100%'}}>
                                <AgCharts options={activeOptions}/>
                            </div>
                        }
                    </div>

                    <div className={`w-1/3 mb-3 mr-3 p-3 rounded-lg drop-shadow ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        {loading ?
                            <div className="text-white">Loading chart...</div>
                            :
                            <div style={{display: 'grid', width: '100%', height: '100%'}}>
                                <AgCharts options={orderOptionsCustomer}/>
                            </div>
                        }
                    </div>

                    <div className={`w-1/3 mb-3 p-3 rounded-lg drop-shadow ${darkMode ? 'bg-darkMainColor' : 'bg-white border'}`}>
                        {loading ?
                            <div className="text-white">Loading chart...</div>
                            :
                            <div style={{display: 'grid', width: '100%', height: '100%'}}>
                                <AgCharts options={plannedOptions}/>
                            </div>
                        }
                    </div>

                </div>


            </div>

        </div>
    )
};


export default Dashboard;
