import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";

import mainLogo from "../../main-logo.png"
import {Button} from "@headlessui/react";

const ProductCardV2 = ({ product, onOpenModal }) => {

    const { darkMode } = useDarkMode();
    
    // Create a dynamic import context
    const requireSDS = require.context("../../SDS", false, /\.pdf$/);
    const requireSell = require.context("../../sellSheets", false, /\.pdf$/);
    const requireImg = require.context("../../images", false, /\.(png|jpg)$/);

    
    // Create a mapping from file names to file paths
    const SDSMapping = requireSDS.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(".pdf", "");
        // Add to the mapping
        acc[fileName] = requireSDS(filePath);
        return acc;
    }, {});
    
    // Create a mapping from file names to file paths
    const SellSheetMapping = requireSell.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(".pdf", "");
        // Add to the mapping
        acc[fileName] = requireSell(filePath);
        return acc;
    }, {});
    
    // Create a mapping from file names to file paths
    const ImageMapping = requireImg.keys().reduce((acc, filePath) => {
        // Extract the file name without extension
        const fileName = filePath.replace("./", "").replace(/\.(png|jpg)$/, "");
        // Add to the mapping
        acc[fileName] = requireImg(filePath);
        return acc;
    }, {});

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700' },
        { category: 'Secondary Nutrients', color: 'bg-snColor' },
        { category: 'Micronutrients', color: 'bg-mnColor' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor' },
        { category: 'Water Treatments', color: 'bg-wtColor' },
        { category: 'Soil Amendments', color: 'bg-saColor' },
        { category: 'Fungicides', color: 'bg-fgColor' },
        { category: 'Equipment', color: 'bg-gray-600' },

    ];

    const bgColor = categoryColors.find(c => c.category === product.category)?.color || '';


    return (
        <div className={`w-full break-inside-avoid hover:scale-95 flex flex-col transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'} relative  transition-all duration-500 ease-in-out`}>
            
            <div className="w-full">
                <div className={`overflow-hidden ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white drop-shadow'} rounded-lg p-6 relative z-10`}
                    style={{cursor: "auto"}}>
                    
                    <svg
                        aria-hidden="true"
                        className={`absolute inset-0 z-10 size-full ${darkMode ? 'stroke-white/20' : 'stroke-black/20'} [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]`}
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none"/>
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/0">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" width="100%" height="100%"
                              strokeWidth={0}/>
                    </svg>
                    
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        />
                    </div>
                    
                    <div className="z-50 flex flex-wrap items-center">
                        
                        {/*Product Images*/}
                        <div className="flex w-full h-48 md:h-64 lg:h-72 relative">
                            <div
                                className={`w-8/12 mr-4 relative flex justify-center items-center object-cover object-top rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                <img
                                    src={ImageMapping[product.sds] || mainLogo}
                                    alt="Main"
                                    className="rounded-lg h-full object-cover object-top overflow-hidden"
                                />
                            </div>
                            <div className="w-4/12 h-full">
                                <div className="flex flex-col w-full h-full">
                                    
                                    <div className="flex-1 pb-2">
                                        <div
                                            className={`w-full h-full flex justify-center items-center relative object-cover object-center rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                            <img
                                                src={ImageMapping[product.sds] || mainLogo}
                                                className={` top-0 w-2/3`}
                                                alt="Secondary"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-1 pt-2">
                                        <div
                                            className={`w-full h-full flex justify-center items-center relative object-cover object-center rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                            <img
                                                src={ImageMapping[product.sds] || mainLogo}
                                                className={` top-0 w-2/3`}
                                                alt="Third"
                                            />
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        
                        {/*Product Body*/}
                        <div className="w-full pt-8 flex flex-col justify-between">
                            <div>
                                <div className="font-bold text-xl line-clamp-1">{product.name}</div>
                                <div className="flex flex-wrap text-center pt-4 mb-2">
                                    <div
                                        className={`mr-2 mb-2 rounded-full px-3 py-1 text-xs ${bgColor} hover:scale-105 text-white`}>
                                        {product.category}
                                    </div>
                                    <div
                                        className="mr-2 mb-2 rounded-full px-3 py-1 text-xs bg-green-400 hover:scale-105 text-green-900">
                                        {product.weight} lbs
                                    </div>
                                    <div
                                        className="mr-2 mb-2 rounded-full px-3 py-1 text-xs bg-green-400 hover:scale-105 text-green-900">
                                        Ph: {product.ph}
                                    </div>
                                </div>
                                <p className="text-xs leading-relaxed h-28 overflow-y-auto">
                                    {product.description}
                                </p>
                                {/*<ul className="text-xs mt-4 list-disc list-inside leading-relaxed">*/}
                                {/*    <li>Responsive</li>*/}
                                {/*    <li>Mobile-friendly</li>*/}
                                {/*    <li>Media queries</li>*/}
                                {/*    <li>20MB of JavaScript</li>*/}
                                {/*</ul>*/}
                            </div>
                            
                            {/*SDS and Sell Sheet Buttons*/}
                            <div className="z-50 w-full sm:flex-1 grid gap-4 grid-cols-2 pt-6">
                                <div className={`relative px-3`}>
                                    <Button
                                        onClick={() => onOpenModal(SellSheetMapping[product.sds])}
                                        disabled={!SellSheetMapping[product.sds]} // Disable if product.sell is falsy
                                        className={`w-full ${!SellSheetMapping[product.sds] ? 'bg-opacity-50' : 'hover:shadow-none hover:opacity-75 hover:scale-105'} flex items-center justify-center text-center relative font-bold text-sm bg-gray-200 text-gray-800 px-8 py-3 rounded-lg shadow-lg`}
                                    >
                                        Sell
                                    </Button>
                                    
                                    
                                    {!SellSheetMapping[product.sds] && (
                                        <div
                                            className={`w-full z-50 left-0 top-0 text-center text-sm absolute bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 ${darkMode ? 'text-white' : 'text-black'} flex items-center justify-center line-clamp-1`}
                                            style={{
                                                height: 'fit-content',
                                                transform: 'translateY(-50%)',
                                                top: '50%'
                                            }}
                                        >
                                            Sell Not Available
                                        </div>
                                    )}
                                </div>
                                
                                <div className={`relative px-3`}>
                                    <Button
                                        onClick={() => onOpenModal(SDSMapping[product.sds])}
                                        disabled={!SDSMapping[product.sds]} // Disable if product.sell is falsy
                                        className={`${!SDSMapping[product.sds] ? 'bg-opacity-50' : 'hover:shadow-none hover:opacity-75 hover:scale-105'} w-full block text-center relative text-white font-bold text-sm bg-bgLightGreen px-4 py-3 rounded-lg shadow-lg`}
                                    >
                                        SDS
                                    </Button>
                                    
                                    {!SellSheetMapping[product.sds] && (
                                        <div
                                            className={`w-full z-50 left-0 top-0 text-center text-sm absolute bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 px-2 ${darkMode ? 'text-white' : 'text-black'} flex items-center justify-center line-clamp-1`}
                                            style={{
                                                height: 'fit-content',
                                                transform: 'translateY(-50%)',
                                                top: '50%'
                                            }}
                                        >
                                            SDS Not Available
                                        </div>
                                    )}
                                </div>
                                
                                
                            </div>
                        
                        </div>
                    </div>
                
                </div>
            </div>
        
        </div>
    )
}

export default ProductCardV2;