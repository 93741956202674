import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {Button, Input, Select, Textarea} from "@headlessui/react";
import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    PlusCircleIcon, SquaresPlusIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../firebase/firebase";
import Loading from "../Loading";

const PricingCard = ({ product }) => {

    const { darkMode } = useDarkMode();

    const [description, setDescription] = useState(product.description || ''); // State to manage description input
    const [name, setName] = useState(product.name || '');
    const [ph, setPh] = useState(parseFloat(product.ph).toFixed(2) || '');
    const [weight, setWeight] = useState(product.weight || '');
    const [category, setCategory] = useState(product.category || '');
    const [sds, setSDS] = useState(product.sds || '')

    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving] = useState(false); // Track whether the item is being removed

    // const numberWithCommas = (number) => {
    //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    const [prices, setPrices] = useState(product.prices);
    const [isModified, setIsModified] = useState(false);

    // Function to handle changes in inputs
    const handleInputChange = (index, field, value) => {
        const updatedPrices = prices.map((price, i) =>
            i === index ? { ...price, [field]: value } : price
        );
        setPrices(updatedPrices);
        setIsModified(true);
    };

    // Function to handle changes in description
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in name
    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in ph
    const handlePHChange = (e) => {
        setPh(e.target.value);
        setIsModified(true); // Mark as modified
    };
    
     // Function to handle changes in sds
    const handleSDSChange = (e) => {
        setSDS(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in weight
    const handleWeightChange = (e) => {
        setWeight(e.target.value);
        setIsModified(true); // Mark as modified
    };

    // Function to handle changes in weight
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setIsModified(true); // Mark as modified
    };
    const [updating, setUpdating] = useState(false);

    // Function to save updated data to Firebase
    const handleSave = async () => {
        setUpdating(true);
        const startTime = Date.now();
        
        try {
            const productDocRef = doc(db, "Products", product.id); // Replace `product.id` with the actual document ID
            await updateDoc(productDocRef, {
                prices,
                description: description, // Add the description field
                name: name,
                ph: parseFloat(ph),
                weight: parseFloat(weight),
                category: category,
                sds: sds
            });
            console.log("Product updated successfully!");

            // Update the productUpdated field in all documents in the Users collection
            const usersCollectionRef = collection(db, "Users");
            const usersSnapshot = await getDocs(usersCollectionRef);

            const updatePromises = usersSnapshot.docs.map((userDoc) => {
                const userDocRef = doc(db, "Users", userDoc.id);
                return updateDoc(userDocRef, { productUpdated: true });
            });

            await Promise.all(updatePromises); // Wait for all updates to complete
            console.log("Updated productUpdated field for all users!");

            alert(`${name} Updated successfully!`);

            setIsModified(false);
        } catch (error) {
            console.error("Error updating product or users:", error);
        }
        
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Product Updated in ${timeElapsed} secs`);
    };


    // Add a new price entry to the prices array
    const handleAddPrice = () => {
        const newPrice = {
            um: '',
            code: '',
            ne: '',
            se: '',
            ag: '',
            dist1: '',
            dist2: ''
        };
        setPrices([...prices, newPrice]);
        setIsModified(true);
    };

    // Remove a price entry from the prices array
    const handleRemovePrice = (index) => {
        const updatedPrices = prices.filter((_, i) => i !== index);
        setPrices(updatedPrices);
        setIsModified(true);
    };

    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700' },
        { category: 'Secondary Nutrients', color: 'bg-snColor' },
        { category: 'Micronutrients', color: 'bg-mnColor' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor' },
        { category: 'Water Treatments', color: 'bg-wtColor' },
        { category: 'Soil Amendments', color: 'bg-saColor' },
        { category: 'Fungicides', color: 'bg-fgColor' },
        { category: 'Equipment', color: 'bg-gray-600' },

    ];

    const bgColor = categoryColors.find(c => c.category === category)?.color || '';

    const [showId, setShowId] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    const handleTripleClick = () => {
        setClickCount(prevCount => prevCount + 1);
        setTimeout(() => setClickCount(0), 500); // Reset click count after 500ms

        if (clickCount === 2) { // On the third click
            setShowId(true); // Set showId to true
            setTimeout(() => setShowId(false), 15000); // Reset showId to false after 30 seconds
        }
    };


    return (
        <div className={`w-full flex flex-col rounded-lg hover:bg-opacity-90 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {updating && (
                <Loading/>
            )}
            
            {/*HEADER*/}
            <div className="w-full flex flex-row mb-3">

                <div className="w-full flex flex-row">

                    {/*Main*/}
                    <div className="w-1/2 mr-auto flex flex-row items-center h-full mt-auto mb-auto">

                        {/*Category*/}
                        <div className={`w-1/3 p-1 h-8 items-center flex pl-3 ${bgColor} rounded-full hover:scale-105`} onClick={handleTripleClick}>
                            <Select
                                id="category"
                                className={`rounded-lg text-center block w-full bg-transparent text-white line-clamp-1`}
                                value={category}
                                onChange={handleCategoryChange}
                                // onChange={(event) => {
                                //     const selectedValue = event.target.value;
                                //     setCategory(selectedValue); // Update priceCode state
                                // }}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                                <option value="Equipment">Equipment</option>
                            </Select>
                            {/*<div className="flex items-center text-white font-bold justify-center h-full text-sm line-clamp-1">{product.category || "--"}</div>*/}
                        </div>

                        {showId && (
                            <div className="text-xxs ml-3">
                                {product.id}
                            </div>
                        )}

                        {/*Name*/}
                        <div className="justify-center w-2/3 flex flex-col h-full ml-6">
                            <Input
                                className={`rounded-md text-lg tracking-wider line-clamp-1 bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                type="string"
                                placeholder="Product Code"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </div>

                    </div>

                    {/*PH*/}
                    <div className="w-1/2 ml-3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-32 mr-auto p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1 font-bold">
                                <Input
                                    className={`rounded-md line-clamp-1 bg-transparent focus:outline-none w-full text-center ${darkMode ? 'text-white' : 'text-black'}`}
                                    type="string"
                                    placeholder="sds"
                                    value={sds}
                                    onChange={handleSDSChange}
                                />
                            </div>
                        </div>
                        
                        {category !== 'Equipment' && (
                            <>
                                <div className={`w-32 mr-auto p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                                    <div className="flex items-center justify-center h-full text-sm line-clamp-1 font-bold">
                                        <Input
                                            className={`rounded-md line-clamp-1 bg-transparent focus:outline-none w-10 ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="string"
                                            placeholder="pH"
                                            value={ph}
                                            onChange={handlePHChange}
                                        />
                                        <div className={`text-xs ml-1 font-medium line-clamp-1`}>pH</div>
                                    </div>
                                </div>

                                <div className={`w-32 ml-3 p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                                    <div className="flex items-center justify-center h-full text-sm line-clamp-1 font-bold">
                                        <Input
                                            className={`rounded-md line-clamp-1 bg-transparent focus:outline-none w-10 ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="string"
                                            placeholder="weight"
                                            value={weight}
                                            onChange={handleWeightChange}
                                        />
                                        <div className={`text-xs ml-1 font-medium line-clamp-1`}>lbs per gal</div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>

                    {/*Update Button*/}
                    <div className="mr-auto flex flex-row items-center h-full mt-auto mb-auto">

                        {isModified && (
                            <div className="ml-6 animate-rubberBand flex items-center">
                                <Button
                                    className="bg-blue-500 hover:bg-opacity-50 hover:scale-105 text-white text-sm font-bold py-1 px-4 rounded-lg"
                                    onClick={handleSave}
                                >

                                    Update
                                </Button>
                            </div>
                        )}

                    </div>

                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center ml-3">
                        <Button
                            className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            )}
                        </Button>
                    </div>
                </div>

            </div>

            {isVisible && (
                <div className={`mb-3`}>

                    {/*<div className={`text-sm border rounded-lg p-1`}>{product.description}</div>*/}

                    <Textarea
                        type="text"
                        name="description"
                        id="description"
                        className={`rounded-lg block w-full h-24 p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                        placeholder="Edit Product Description"
                        value={description}
                        onChange={handleDescriptionChange}
                    />

                </div>
            )}

            {/*Prices Label*/}
            <div className={`flex relative flex-row items-center px-2 border-b py-1 mb-1`}>

                <Button
                    className={`absolute hover:text-green-600 hover:scale-105 stroke-2 hover:animate-pulse`}
                    onClick={handleAddPrice}>
                    <PlusCircleIcon className="w-6"/>
                </Button>

                {/*U/M*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>U/M</div>
                </div>

                {/*Code*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>Code</div>
                </div>


                {/*NE*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>NE Price</div>
                </div>

                {/*SE*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>SE Price</div>
                </div>

                {/*AG*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>AG Price</div>
                </div>

                {/*Dist1*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>DIST1 Price</div>
                </div>

                {/*Dist2*/}
                <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                    <div className={`text-xs font-bold`}>DIST2 Price</div>
                </div>

                <Button
                    className="ml-2 text-transparent"
                    disabled={true}
                >
                    <XCircleIcon className="w-6 h-6"/>
                </Button>


            </div>

            {/*Prices*/}
            <div className="w-full flex flex-col">

                {prices.length === 0 ? (
                    // Display custom dialog if sprays array is empty
                    <div className="flex w-full h-full justify-center items-center py-5">
                        
                        <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                            <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                <SquaresPlusIcon className={`w-6 stroke-1 mr-3`}/>
                                <p className="text-md line-clamp-1 tracking-widest flex">No Pricing Available</p>
                            </div>
                        </div>
                    
                    </div>
                
                ) : (
                    // Display SprayCard components if sprays array is not empty
                    prices.map((price, index) => {
                        return (
                            <div key={index} className={`mb-1 w-full rounded-lg animate-zoomIn`}>
                                <div className="flex flex-row w-full items-center p-1">
                                    
                                    {/* U/M */}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <Select
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            value={price.um}
                                            onChange={(e) => handleInputChange(index, 'um', e.target.value)}
                                        >
                                            <option value="">Select U/M...</option>
                                            {/*<option value="2.5">Case</option>*/}
                                            {/*<option value="30">30 Gal Drum</option>*/}
                                            {/*<option value="55">55 Gal Drum</option>*/}
                                            {/*<option value="275">275 Gal Tote</option>*/}

                                            <option value="ACR">Acre</option>
                                            <option value="B12">BAG 12#</option>
                                            <option value="B25">BAG 25#</option>
                                            <option value="B30">BAG 30#</option>
                                            <option value="B40">BAG 40#</option>
                                            <option value="B5">BAG 5#</option>
                                            <option value="B50">BAG 50#</option>
                                            <option value="B51">BAG 51#</option>
                                            <option value="BAG">BAG (Unspecified Weight)</option>
                                            <option value="2.5">CASE (2x2.5)</option>
                                            <option value="CS4">CASE (4X1)</option>
                                            <option value="30">DRUM 30-GAL</option>
                                            <option value="55">DRUM 55-GAL</option>
                                            <option value="DRU">DRUM (Unspecified Volume)</option>
                                            <option value="EAC">EACH</option>
                                            <option value="GAL">GALLON</option>
                                            <option value="TR">TOTE REFILL</option>
                                            <option value="LB">POUND</option>
                                            <option value="OZ">Ounce - Av</option>
                                            <option value="PAI">PAIL 5-GAL</option>
                                            <option value="SAC1">Super Sack (B1000)</option>
                                            <option value="SAC2">Super Sack (B2000)</option>
                                            <option value="TON">TON (2,000#)</option>
                                            <option value="275">TOTE 275-GAL</option>

                                        </Select>
                                    </div>

                                    {/* Code */}
                                    <div className={`hover:scale-105 rounded-lg block w-full px-2 mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="string"
                                            placeholder="Product Code"
                                            value={price.code}
                                            onChange={(e) => handleInputChange(index, 'code', e.target.value)}
                                        />
                                    </div>

                                    {/* NE */}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <div className="text-borderColor text-xs mr-3">$</div>
                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="number"
                                            placeholder="NE"
                                            value={price.ne}
                                            onChange={(e) => handleInputChange(index, 'ne', e.target.value)}
                                        />
                                        <div className="text-borderColor w-fit text-xxs ml-1">USD</div>
                                    </div>

                                    {/*SE*/}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <div className="text-borderColor text-xs mr-3">$</div>

                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="number"
                                            placeholder="SE"
                                            value={price.se}
                                            onChange={(e) => handleInputChange(index, 'se', e.target.value)}
                                        />

                                        <div className="text-borderColor w-fit text-xxs ml-1">USD
                                        </div>

                                    </div>

                                    {/*AG*/}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <div className="text-borderColor text-xs mr-3">$</div>

                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="number"
                                            placeholder="AG"
                                            value={price.ag}
                                            onChange={(e) => handleInputChange(index, 'ag', e.target.value)}
                                        />

                                        <div className="text-borderColor w-fit text-xxs ml-1">USD
                                        </div>

                                    </div>

                                    {/*Dist1*/}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <div className="text-borderColor text-xs mr-3">$</div>

                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="number"
                                            placeholder="DIST 1"
                                            value={price.dist1}
                                            onChange={(e) => handleInputChange(index, 'dist1', e.target.value)}
                                        />

                                        <div className="text-borderColor w-fit text-xxs ml-1">USD
                                        </div>

                                    </div>

                                    {/*Dist2*/}
                                    <div className={`hover:scale-105 rounded-lg items-center w-full px-2 flex ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                        <div className="text-borderColor text-xs mr-3">$</div>

                                        <Input
                                            className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                            type="number"
                                            placeholder="DIST 2"
                                            value={price.dist2}
                                            onChange={(e) => handleInputChange(index, 'dist2', e.target.value)}
                                        />

                                        <div className="text-borderColor w-fit text-xxs ml-1">USD
                                        </div>

                                    </div>

                                    <Button
                                        onClick={() => handleRemovePrice(index)}
                                        className="ml-2 stroke-2 text-red-500 hover:text-red-700 hover:animate-pulse"
                                    >
                                        <XCircleIcon className="w-6 h-6"/>
                                    </Button>
                                </div>
                            </div>
                        )
                    })

                )}

            </div>

        </div>
    )
}

export default PricingCard;

