import React, {useEffect, useState} from 'react';
import { collection, getDocs } from "firebase/firestore";
import SideBar from "./SideBar";
import {Button, Select} from "@headlessui/react";
import {
    ArrowPathIcon, CheckCircleIcon,
    TrashIcon, XCircleIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {useDarkMode} from "../contexts/darkModeContext";
import TicketCard from "./Profile/TicketCard";
import {AgCharts} from "ag-charts-react";

const Tickets = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("A");

    const { darkMode } = useDarkMode();

    const [allOpen, setallOpen] = useState([]);
    const [filteredAllOpen, setfilteredAllOpen] = useState([]);

    const [allClosed, setallClosed] = useState([]);
    const [filteredAllClosed, setfilteredAllClosed] = useState([]);
    
    const [openGraph, setOpenGraph] = useState(false);
    const [closedGraph, setClosedGraph] = useState(false);


    const [openPageData, setOpenPageData] = useState([]);
    const [openCategoryData, setOpenCategoryData] = useState([]);

    const [closedPageData, setClosedPageData] = useState([]);
    const [closedCategoryData, setClosedCategoryData] = useState([]);

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {

        const fetchAllOpenTickets = async () => {
            try {
                const storedAllData = localStorage.getItem(`allOpen_${currentUser.uid}`);
                let allClientsData = [];


                if (storedAllData) {
                    const pendingData = JSON.parse(storedAllData);
                    allClientsData = pendingData;
                    setallOpen(pendingData);
                    setfilteredAllOpen(pendingData);
                } else {
                    const usersCollectionRef = collection(db, 'Users');
                    const usersSnapshot = await getDocs(usersCollectionRef);

                    // Iterate over each user (using their UID as document ID)
                    const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                        const userId = userDoc.id; // UID of the user

                        // Reference to the open collection for this user
                        const openCollectionRef = collection(db, `Tickets/${userId}/open`);
                        const openSnapshot = await getDocs(openCollectionRef);

                        // Push open data to the allClientsData array
                        openSnapshot.forEach((openDoc) => {
                            allClientsData.push({
                                userID: userId,    // Add user ID
                                ...openDoc.data(),
                                id: openDoc.id, // Add document reference
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // If there's data, store it in state and localStorage
                    if (allClientsData.length > 0) {
                        setallOpen(allClientsData);
                        setfilteredAllOpen(allClientsData);
                        localStorage.setItem(`allOpen_${currentUser.uid}`, JSON.stringify(allClientsData));
                    } else {
                        console.log('No open tickets found');
                    }
                }

                // Calculate count for each unique page and format data for AGCharts
                const pageCountsMap = new Map();

                allClientsData.forEach(ticket => {
                    const page = ticket.page;
                    // Increment the count for this page in the map
                    if (pageCountsMap.has(page)) {
                        pageCountsMap.set(page, pageCountsMap.get(page) + 1);
                    } else {
                        pageCountsMap.set(page, 1);
                    }
                });

                // Convert map to an array format for AGCharts
                const chartData = Array.from(pageCountsMap, ([page, count]) => ({ page, count }));

                // Set the chart data
                setOpenPageData(chartData);


                const categoryCountsMap = new Map();

                allClientsData.forEach(ticket => {
                    const category = ticket.category;
                    // Increment the count for this page in the map
                    if (categoryCountsMap.has(category)) {
                        categoryCountsMap.set(category, categoryCountsMap.get(category) + 1);
                    } else {
                        categoryCountsMap.set(category, 1);
                    }
                });

                // Convert map to an array format for AGCharts
                const chartDataCategory = Array.from(categoryCountsMap, ([category, count]) => ({ category, count }));

                // Set the chart data
                setOpenCategoryData(chartDataCategory);

            } catch (error) {
                console.error("Error fetching open tickets data: ", error);
            }
        };

        const fetchAllClosedTickets = async () => {
            try {
                const storedAllData = localStorage.getItem(`allClosed_${currentUser.uid}`);
                let allClientsData = [];

                if (storedAllData) {
                    const pendingData = JSON.parse(storedAllData);
                    allClientsData = pendingData;
                    setallClosed(pendingData);
                    setfilteredAllClosed(pendingData);
                } else {
                    const usersCollectionRef = collection(db, 'Users');
                    const usersSnapshot = await getDocs(usersCollectionRef);

                    // Iterate over each user (using their UID as document ID)
                    const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                        const userId = userDoc.id; // UID of the user

                        // Reference to the open collection for this user
                        const openCollectionRef = collection(db, `Tickets/${userId}/closed`);
                        const openSnapshot = await getDocs(openCollectionRef);

                        // Push open data to the allClientsData array
                        openSnapshot.forEach((openDoc) => {
                            allClientsData.push({
                                userID: userId,    // Add user ID
                                ...openDoc.data(),
                                id: openDoc.id, // Add document reference
                            });
                        });
                    });

                    // Run all user-level fetches in parallel
                    await Promise.all(userPromises);

                    // If there's data, store it in state and localStorage
                    if (allClientsData.length > 0) {
                        setallClosed(allClientsData);
                        setfilteredAllClosed(allClientsData);
                        localStorage.setItem(`allClosed_${currentUser.uid}`, JSON.stringify(allClientsData));
                    } else {
                        console.log('No closed tickets found');
                    }
                }

                // Calculate count for each unique page for AGCharts
                const closedPageCountsMap = new Map();

                allClientsData.forEach(ticket => {
                    const page = ticket.page;
                    console.log('Processing ticket:', ticket); // Check if `page` exists
                    if (page) {
                        if (closedPageCountsMap.has(page)) {
                            closedPageCountsMap.set(page, closedPageCountsMap.get(page) + 1);
                        } else {
                            closedPageCountsMap.set(page, 1);
                        }
                    }
                });

                const closedChartData = Array.from(closedPageCountsMap, ([page, count]) => ({ page, count }));
                setClosedPageData(closedChartData);

                // Calculate count for each unique category
                const closedCategoryCountsMap = new Map();

                allClientsData.forEach(ticket => {
                    const category = ticket.category;
                    if (category) {
                        if (closedCategoryCountsMap.has(category)) {
                            closedCategoryCountsMap.set(category, closedCategoryCountsMap.get(category) + 1);
                        } else {
                            closedCategoryCountsMap.set(category, 1);
                        }
                    }
                });

                const closedChartDataCategory = Array.from(closedCategoryCountsMap, ([category, count]) => ({ category, count }));
                setClosedCategoryData(closedChartDataCategory);

            } catch (error) {
                console.error("Error fetching open tickets data: ", error);
            }
        };

        fetchAllOpenTickets();
        fetchAllClosedTickets();

    }, [currentUser]);



    const [bgColor, setBgColor] = useState("#efefef");
    const [textColor, setTextColor] = useState("#21b372");
    const [labelColor, setLabelColor] = useState("#ffffff");

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setBgColor("#1e1e1e");   // Dark background color for dark mode
            setTextColor("#21b372"); // Light text color for dark mode
            setLabelColor("#ffffff");
        } else {
            setBgColor("#ffffff");   // Light background color for light mode
            setTextColor("#000000"); // Dark text color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const myTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };

    const [options, setOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Tickets by Page",
        },
        subtitle: {
            text: "By Ticket Count",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "page",
                angleKey: "count",
                sectorLabelKey: "page",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${numberWithCommas(allOpen.length)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, angleKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: ${datum[angleKey]}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    const [optionsCategory, setOptionsCategory] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Tickets by Category",
        },
        subtitle: {
            text: "By Ticket Count",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "category",
                angleKey: "count",
                sectorLabelKey: "category",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${numberWithCommas(allOpen.length)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, angleKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: ${datum[angleKey]}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    const [closedOptions, setClosedOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Tickets by Page",
        },
        subtitle: {
            text: "By Ticket Count",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "page",
                angleKey: "count",
                sectorLabelKey: "page",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${numberWithCommas(allClosed.length)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, angleKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: ${datum[angleKey]}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    const [closedOptionsCategory, setClosedOptionsCategory] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Tickets by Category",
        },
        subtitle: {
            text: "By Ticket Count",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "category",
                angleKey: "count",
                sectorLabelKey: "category",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `${numberWithCommas(allClosed.length)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, angleKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: ${datum[angleKey]}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });



    // Update chart options whenever chartData is updated
    useEffect(() => {
        console.log(openPageData);
        console.log(closedPageData);

        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: openPageData,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: [
                {
                    ...prevOptions.series[0],
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `${numberWithCommas(allOpen.length)}`,
                            fontSize: 18,
                        },
                    ],
                },
            ],
        }));

        setOptionsCategory((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: openCategoryData,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: [
                {
                    ...prevOptions.series[0],
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `${numberWithCommas(allOpen.length)}`,
                            fontSize: 18,
                        },
                    ],
                },
            ],
        }));


        setClosedOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: closedPageData,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: [
                {
                    ...prevOptions.series[0],
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `${numberWithCommas(allClosed.length)}`,
                            fontSize: 18,
                        },
                    ],
                },
            ],
        }));

        setClosedOptionsCategory((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: closedCategoryData,
            background: {
                fill: bgColor, // Update background color dynamically
            },
            series: [
                {
                    ...prevOptions.series[0],
                    innerLabels: [
                        {
                            text: "Total:",
                            fontSize: 16,
                            spacing: 10,
                        },
                        {
                            text: `${numberWithCommas(allClosed.length)}`,
                            fontSize: 18,
                        },
                    ],
                },
            ],
        }));

    }, [openPageData, openCategoryData, closedPageData, closedCategoryData, darkMode, bgColor, myTheme, allOpen.length, allClosed.length]);





    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Open
        try {

            const usersCollectionRef = collection(db, 'Users');
            const usersSnapshot = await getDocs(usersCollectionRef);

            let allClientsData = [];

            // Iterate over each user (using their UID as document ID)
            const userPromisesOpen = usersSnapshot.docs.map(async (userDoc) => {
                const userId = userDoc.id; // UID of the user

                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Tickets/${userId}/open`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Push client data to the allClientsData array
                clientsSnapshot.forEach((clientDoc) => {
                    allClientsData.push({
                        userID: userId,    // Add user ID
                        ...clientDoc.data(),
                        id: clientDoc.id, // Add document reference
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromisesOpen);

            // If there's data, store it in state and localStorage
            if (allClientsData.length > 0) {
                setallOpen(allClientsData);
                setfilteredAllOpen(allClientsData);
                localStorage.setItem(`allOpen_${currentUser.uid}`, JSON.stringify(allClientsData));
            } else {
                console.log('No clients found');
            }

        } catch (error) {
            console.error("Error fetching all pending orders", error);
        }

        // closed
        try {

            const usersCollectionRef = collection(db, 'Users');
            const usersSnapshot = await getDocs(usersCollectionRef);

            let allClientsData = [];

            // Iterate over each user (using their UID as document ID)
            const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                const userId = userDoc.id; // UID of the user

                // Reference to the client's collection for this user
                const clientsCollectionRef = collection(db, `Tickets/${userId}/closed`);
                const clientsSnapshot = await getDocs(clientsCollectionRef);

                // Push client data to the allClientsData array
                clientsSnapshot.forEach((clientDoc) => {
                    allClientsData.push({
                        userId,    // Add user ID
                        ...clientDoc.data(),
                        id: clientDoc.id, // Add document reference
                    });
                });
            });

            // Run all user-level fetches in parallel
            await Promise.all(userPromises);

            // If there's data, store it in state and localStorage
            if (allClientsData.length > 0) {
                setallClosed(allClientsData);
                setfilteredAllClosed(allClientsData);
                localStorage.setItem(`allClosed_${currentUser.uid}`, JSON.stringify(allClientsData));
            } else {
                console.log('No clients found');
            }


        } catch (error) {
            console.error("Error fetching active data for all users: ", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Tickets Updated in ${timeElapsed} secs`)
    };


    // Function to toggle visibility
    const toggleVisibility = () => {
        setOpenGraph(!openGraph);
    };
    const toggleClose = () => {
        setClosedGraph(!closedGraph);
    };


    const filterTicketsByPage = (industry) => {
        const filtered = allOpen.filter(customer => industry === '' || customer.page === industry);
        setfilteredAllOpen(filtered);
    };

    const filterClosedTicketsByPage = (industry) => {
        const filtered = allClosed.filter(customer => industry === '' || customer.page === industry);
        setfilteredAllClosed(filtered);
    };


    const filterTicketsByCategory = (price) => {
        const filtered = allOpen.filter(customer => price === '' || customer.category === price);
        setfilteredAllOpen(filtered);
    };

    const filterClosedTicketsByCategory = (price) => {
        const filtered = allClosed.filter(customer => price === '' || customer.category === price);
        setfilteredAllClosed(filtered);
    };


    const filtering = (e) => {
        if(status === "A"){
            filterTicketsByPage(e.target.value);
        }
        if (status === "P"){
            filterClosedTicketsByPage(e.target.value);
        }
    }

    const filteringCategory = (e) => {
        if(status === "A"){
            filterTicketsByCategory(e.target.value);
        }
        if (status === "P"){
            filterClosedTicketsByCategory(e.target.value);
        }
    }


    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(filteredAllOpen.length);
        }
        if (status === "P"){
            setCount(filteredAllClosed.length);
        }


    }, [status, filteredAllOpen, filteredAllClosed]);

    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-dvh overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>
            
            <div className={`z-20 animate-fadeIn w-full h-full flex flex-row mb-3`}>

                {/*Tickets*/}
                <div className="flex flex-col relative w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                    {/*Customers Header*/}
                    <div className="w-full h-fit flex mb-3">
                        <div className={`text-2xl w-1/4 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row line-clamp-1`}>
                            <div className={`line-clamp-1`}>Support Tickets</div>
                            {/*Buttons Delete*/}
                            <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                                <Button className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                        onClick={onUpdate}
                                >
                                    <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                                </Button>
                            </div>
                        </div>

                        <div className={`text-xl w-3/4 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} flex rounded-md border drop-shadow shadow-gray-900`}>

                            <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                <div className="font-semibold text-sm mr-3 line-clamp-1">Open:</div>
                                <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">{allOpen.length}</div>
                            </div>

                            <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                                <div className="font-semibold text-sm mr-3 line-clamp-1">Closed:</div>
                                <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">{allClosed.length}</div>
                            </div>

                        </div>


                    </div>

                    {/*Search bar row*/}
                    <div className={`w-full h-10 flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                        {/*Status*/}
                        <div className="w-1/3 mr-1.5 h-full">

                            <div className="flex flex-col w-full mr-3 h-full">
                                {/*<div className="text-sm mb-1">Status</div>*/}
                                <Select
                                    className={`text-sm ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <option value="A">Open</option>
                                    <option value="P">Closed</option>
                                </Select>
                            </div>

                        </div>

                        {/*Industry*/}
                        <div className="w-1/3 mr-1.5 ml-1.5 h-full">

                            <div className="flex flex-col w-full mr-3 h-full">
                                {/*<div className="text-sm mb-1">Industry</div>*/}

                                <Select
                                    className={`text-sm ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                    onChange={filtering}
                                >
                                    <option value="">Select Page...</option>
                                    <option value="Home">Home</option>
                                    <option value="Dashboard">Dashboard</option>
                                    <option value="Catalog">Catalog</option>
                                    <option value="Orders">Current Orders</option>
                                    <option value="Archive">Archived Orders</option>
                                    <option value="Customers">Customer Management</option>
                                    <option value="SalesTeam">Sales Team</option>
                                    <option value="Guides">Help & Resources</option>
                                    <option value="ProgramBuilder">Program Builder</option>
                                    <option value="Messaging">Messaging</option>
                                    <option value="PlantOrders">Plant Orders</option>
                                    <option value="PlantCustomers">Plant Customers</option>
                                    <option value="Profile">Profile / Settings</option>
                                    <option value="SignUp">Sign Up</option>
                                    <option value="Login">Login</option>
                                </Select>
                            </div>

                        </div>

                        {/*Pricing Type*/}
                        <div className="w-1/3 ml-1.5 h-full">

                            <div className="flex flex-col w-full mr-3 h-full">
                                {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                                <Select
                                    className={`text-sm ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                    onChange={filteringCategory}
                                >
                                    <option value="">Select Category...</option>
                                    <option value="PERFORMANCE">Performance Issue</option>
                                    <option value="UI_BUG">User Interface Bug</option>
                                    <option value="FEATURE_REQUEST">Feature Request</option>
                                    <option value="NEW_PAGE">New Page Suggestion</option>
                                    <option value="BUG">Other Bug</option>
                                    <option value="OTHER">Other</option>
                                </Select>
                            </div>

                        </div>


                    </div>

                    {/*Orders Body*/}
                    <div className="w-full h-full rounded-md">

                        {/*Orders Maps*/}
                        <div className="w-full flex h-full flex-col">


                            <div className={`w-full h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>

                                {/*HEADER*/}
                                <div className="w-full flex flex-row pl-3 pr-3">

                                    <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                        {/*Buttons Delete*/}
                                        <div className="flex flex-col items-center mr-3">
                                            <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                                <div className="justify-center flex flex-col items-center h-full">
                                                    <div className="text-sm font-semibold">[{count}]</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Contact Name*/}
                                        <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                            <div className="justify-center flex flex-col h-full">
                                                <div className="text-sm font-semibold">Sales Rep</div>
                                            </div>

                                        </div>

                                        {/*Contact Phone*/}
                                        <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                                            <div className="justify-center flex flex-col h-full">
                                                <div className="text-sm font-semibold">Customer</div>
                                            </div>


                                        </div>

                                        <div className="flex w-1/6 items-center justify-center h-full text-sm font-semibold">ERP</div>

                                        {/**/}
                                        <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                            {/*Status*/}
                                            <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                                <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                                </div>

                                            </div>

                                            <div className="w-1/3 p-1 h-8">
                                                {status === "Planned" ? (
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Submit to Plant</div>
                                                ) : (
                                                    <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Date</div>
                                                )}
                                            </div>

                                            <div className="w-1/3 h-8 items-center justify-center flex">
                                                <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Order Total</div>
                                            </div>


                                        </div>


                                        {/*Buttons DropDown*/}
                                        <div className="flex flex-col items-center ml-3">
                                            <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                                <TrashIcon className="h-6 text-transparent m-auto"/>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                {/*Orders MAP*/}
                                <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100vh-14rem)] scroll`}>

                                    {status === "A" && (
                                        <div>

                                            <div>

                                                {filteredAllOpen.length === 0 ? (
                                                    // Display custom dialog if sprays array is empty
                                                    <div className="flex w-full h-full justify-center items-center py-10">

                                                        <div
                                                            className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                            <div
                                                                className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                                <p className="text-xl text-black">No Products in this
                                                                    Order</p>
                                                                <p className="text-lg text-black mt-2">Add a Product</p>
                                                                <p className="text-xs text-black">1] Search Product by name
                                                                    using the search bar above.</p>
                                                                <p className="text-xs text-black">2] Browse the Digital
                                                                    Catalog by clicking on the button above.</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ) : (
                                                    // Display SprayCard components if sprays array is not empty
                                                    filteredAllOpen.map((ticket, index) => {
                                                        return (
                                                            <TicketCard
                                                                key={index}
                                                                index={index}
                                                                ticket={ticket}
                                                            />
                                                        )
                                                    })
                                                )}

                                            </div>

                                        </div>
                                    )}

                                    {status === "P" && (
                                        <div>

                                            <div>

                                                {filteredAllClosed
.length === 0 ? (
                                                    // Display custom dialog if sprays array is empty
                                                    <div className="flex w-full h-full justify-center items-center py-10">

                                                        <div
                                                            className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                            <div
                                                                className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                                <p className="text-xl text-black">No Products in this
                                                                    Order</p>
                                                                <p className="text-lg text-black mt-2">Add a Product</p>
                                                                <p className="text-xs text-black">1] Search Product by name
                                                                    using the search bar above.</p>
                                                                <p className="text-xs text-black">2] Browse the Digital
                                                                    Catalog by clicking on the button above.</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ) : (
                                                    // Display SprayCard components if sprays array is not empty
                                                    filteredAllClosed
.map((ticket, index) => {
                                                        return (
                                                            <TicketCard
                                                                key={index}
                                                                index={index}
                                                                ticket={ticket}
                                                            />
                                                        )
                                                    })
                                                )}

                                            </div>

                                        </div>
                                    )}

                                </div>


                            </div>


                        </div>

                    </div>

                    {/*Customers Footer [Buttons]*/}
                    <div className="w-full h-fit flex mb-3 mt-3">

                    </div>

                </div>

                {/*Graphs*/}
                <div className={`flex flex-col relative w-1/4 mt-3 mb-3 mr-3`}>

                    {/*Open*/}
                    <div className={`w-full h-full overflow-y-auto rounded-lg p-3 mb-3 ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white drop-shadow'}`}>

                        {/*Header*/}
                        <div className={`${darkMode ? '' : ''} w-full flex flex-row mb-1`}>
                            <CheckCircleIcon className={`mr-auto w-6 text-green-600 absolute`}/>
                            <div className={`text-center w-full tracking-widest`}>Open Tickets</div>
                            <Button className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow'} absolute right-2 px-2 text-green-600 rounded-lg text-xs tracking-widest  hover:scale-105 hover:bg-opacity-50`} onClick={toggleVisibility}>Switch</Button>
                        </div>

                        {openGraph ? (
                            <div style={{display: 'grid', width: '100%', height: '90%'}}
                                 className={`transition-transform duration-500 ease-in-out`}>
                                <AgCharts options={options}/>
                            </div>
                        ) : (
                            <div style={{display: 'grid', width: '100%', height: '90%'}}
                                 className={`transition-transform duration-500 ease-in-out`}>
                                <AgCharts options={optionsCategory}/>
                            </div>
                        )}


                    </div>

                    {/*Closed*/}
                    <div className={`w-full h-full overflow-y-auto rounded-lg p-3 ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white drop-shadow'}`}>

                        {/*Header*/}
                        <div className={`${darkMode ? '' : ''} w-full flex flex-row`}>
                            <XCircleIcon className={`mr-auto w-6 text-red-600 absolute`}/>
                            <div className={`text-center w-full tracking-widest`}>Closed Tickets</div>
                            <Button className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow'} absolute right-2 px-2 text-green-600 rounded-lg text-xs tracking-widest  hover:scale-105 hover:bg-opacity-50`} onClick={toggleClose}>Switch</Button>
                        </div>

                        {closedGraph ? (
                            <div style={{display: 'grid', width: '100%', height: '90%'}}
                                 className={`transition-transform duration-500 ease-in-out`}>
                                <AgCharts options={closedOptions}/>
                            </div>
                        ) : (
                            <div style={{display: 'grid', width: '100%', height: '90%'}}
                                 className={`transition-transform duration-500 ease-in-out`}>
                                <AgCharts options={closedOptionsCategory}/>
                            </div>
                        )}

                    </div>

                </div>
            </div>

        </div>
    )
};


export default Tickets;
