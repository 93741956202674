import React from "react";
import {useDarkMode} from "../../contexts/darkModeContext";


const OrdersGuides = () => {

    const { darkMode } = useDarkMode();

    const posts = [
        {
            id: 1,
            title: 'Selecting a customer',
            href: '#',
            description:
                'The way that you, a sales rep, selects a customer is you choose from the drop down labeled Customer. This will preload with all of your active customers and once you have selected them you will see the next three fields to the left “Industry”,  “Pricing Type”, and “Customer Code” preloaded with the data associated with that customer’s account.',
            date: 'Nov 26, 2024',
            category: { title: 'New Order Form', href: '#' },
        },
        // More posts...
    ]


    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'} overflow-y-scroll animate-fadeIn`}>

            <div className="mx-auto px-6">
                <div className="mx-auto">


                    <h2 className="text-pretty text-green-600 text-4xl font-semibold tracking-widest sm:text-5xl">Current Orders</h2>
                    <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>The new order form in the internal sales tool offers an efficient solution for sales reps to manage and submit customer orders seamlessly.</p>


                    <div className={`mt-8 border-t-4 border-dashed ${darkMode ? 'border-green-600' : 'border-green-600'} pt-8`}>
                        <div className="columns-1 sm:columns-2 gap-6 space-y-6">
                            {posts.map((post) => (
                                <article key={post.id} className="break-inside-avoid p-4 rounded-lg shadow-lg hover:scale-105">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className={`${darkMode ? 'text-neutral-500' : 'text-gray-500'}`}>
                                            {post.date}
                                        </time>
                                        <div className={`relative z-10 rounded-full ${darkMode ? 'bg-darkBgColor text-green-600 border border-neutral-700' : 'bg-bgColor border text-green-600'} px-3 py-1.5 font-medium`}>
                                            {post.category.title}
                                        </div>
                                    </div>
                                    <div className="group relative mt-4">
                                        <h3 className="text-lg font-semibold">
                                            <div>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </div>
                                        </h3>
                                        <p className={`mt-3 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            {post.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>


                </div>
            </div>

        </div>

    );
}

export default OrdersGuides;