import {useDarkMode} from "../contexts/darkModeContext";
import course from "../course.jpg"
import React, {useEffect, useState} from "react";
import CyberBackground from "./CyberBackground";
import lyme from "../lymeTrucks.jpg";

const Background = () => {
    const { darkMode } = useDarkMode();

    // Image and component array
    const backgroundElements = [
        { CyberBackground: <CyberBackground /> }, // Custom component
        { Lyman: course },
        { LymeTrucks: lyme },
    ];

    const [currentElement, setCurrentElement] = useState(null);

    // Fetch from local storage on component mount
    useEffect(() => {
        const savedKey = localStorage.getItem("savedKey");

        if (savedKey) {
            const foundElement = backgroundElements.find((item) => item[savedKey]);
            if (foundElement) {
                setCurrentElement(foundElement[savedKey]);
            }
        } else {
            setCurrentElement(Object.values(backgroundElements[0])[0]); // Fallback to first element
        }
        // eslint-disable-next-line
    }, []);


    return (
        <div className="fixed inset-0 flex items-center justify-center">
            
            
            {/* Dynamically Render Image or Component */}
            {typeof currentElement === "string" ? (
                <>
                    {/* Background Image */}
                    <img
                        alt="Background"
                        src={currentElement}
                        className="absolute inset-0 -z-10 size-full object-cover"
                    />
        
                    {/* Decorative Elements */}
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#488A4F] to-[#164A1B] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        />
                    </div>
                </>
            ) : (
                <div className={`fixed inset-0 animate-fadeIn ${darkMode ? 'bg-darkMainColor/50' : ''} flex items-center justify-center`}>
                    {currentElement}
                </div>
            )}
        
        </div>
    );
};

export default Background;